import * as React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { AppConsumer, AppProvider } from '../../../appContext';
import theme from '../../common/styles/theme';
import { MainLayout } from '../components';
import { IUser } from 'modules/auth/types';

interface IProps {
  currentUser: IUser;
  children: React.ReactNode;
}

const LayoutContainer = (props: IProps) => {
  return (
  <MuiThemeProvider theme={theme}>
    <AppProvider currentUser={props.currentUser}>
      <AppConsumer>
        {({ getUser }) => (
          <MainLayout
            getUser={getUser}
            {...props}
          />
        )}
      </AppConsumer>
    </AppProvider>
  </MuiThemeProvider>
)};

export default LayoutContainer;
