import * as React from 'react';
import { ReactComponent as Edit } from 'modules/common/icons/icon-edit.svg';
import { ReactComponent as Trash } from 'modules/common/icons/icon-trash.svg';
import { ReactComponent as Mail } from 'modules/common/icons/icon-mail.svg';
import IconButton from '@material-ui/core/IconButton';
import * as S from './Styled';
import { IWorkspace } from 'modules/types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface IProps {
  workspace: IWorkspace;
  handleUpdateWorkspace: () => void;
  handleDelete: () => void;
  handleSendEmail: () => void;
}

const Workspace = ({
  workspace,
  handleUpdateWorkspace,
  handleDelete,
  handleSendEmail,
}: IProps) => {
  return (
    <Box marginBottom={2}>
      <Grid container justifyContent="space-between" className="whiteCard">
        <Grid item xs={4}>
          <S.ListContent>
            <Typography className="text" noWrap={true}>
              {workspace.name}
            </Typography>
            <S.ResourceTools className="tools">
              <IconButton
                aria-label="edit"
                size="small"
                onClick={handleUpdateWorkspace}
              >
                <Edit />
              </IconButton>
              <IconButton
                aria-label="mail"
                size="small"
                onClick={handleSendEmail}
              >
                <Mail />
              </IconButton>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleDelete}
              >
                <Trash />
              </IconButton>
            </S.ResourceTools>
          </S.ListContent>
        </Grid>
        <Grid item xs={4}>
          <S.ListContent>
            <Typography className="text alignCenter" noWrap={true}>
              {workspace.clientCode ? workspace.clientCode : 'Não cadastrado'}
            </Typography>
          </S.ListContent>
        </Grid>
        <Grid item xs={4}>
          <S.ListContent>
            <Typography className="text alignCenter" noWrap={true}>
              {workspace.code}
            </Typography>
          </S.ListContent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Workspace;
