import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import { paletteColor } from 'modules/common/styles/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

//AdminContainer
export const CustomTabs = styled(Tabs)`
  && {
    padding-left: 24px;
    background: ${paletteColor.primary.white};

    & .MuiTab-wrapper {
      text-transform: none;
      color: #53545C;
    }
  }
`;

export const colorBackground = styled(CustomTabs)`
  background: transparent !important;
  padding-left: 0px !important;
`;

//SelectUsers
export const CustomSelect = styled(Select)`
&& {
    background-color: ${paletteColor.primary.white};
    width: 493px;
    height: 50px;
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    padding: 5px;
    color: ${paletteColor.info.soft};
    font-size: 12px;
    text-indent: 10px;

    &&:hover {
      background-color: ${paletteColor.primary.white};
      border-bottom: 1px solid ${paletteColor.info.border};
    }

    &&:before {
      border-bottom: 1px solid ${paletteColor.info.border};
      border-left: 1px solid ${paletteColor.info.border};
      left: calc(100% - 50px);
      top: 5px;
      bottom: 5px;
      height: auto;
      width: 0;
    }

    & .MuiSelect-select {
      &:focus {
        background-color: transparent;
      }

      &:before {
        content: '';
        position: absolute;
        right: 22px;
        top: 18px;
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: ${paletteColor.info.soft};
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
      }
    }

    & .MuiSelect-icon {
      display: none;
    }
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  && {
    background-color: ${paletteColor.primary.white};
    padding: 8px;
  }
`;

export const CustomTypography = styled(Typography)`
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  color: ${paletteColor.info.soft};
  letter-spacing: 0.05em;
`;

//Workspaces

export const ButtonPermissions = styled.button`
  width: 100%;
  height: 50px;
  padding-left: 25px;
  background: ${paletteColor.primary.white};
  outline: none;
  border: 1px solid ${paletteColor.info.border};
  border-radius: 6px;
  text-align: left;
  font-weight: 600;
  font-size: 0.875rem;
  color: #53545C;
  cursor: pointer;
`;

export const DividerPermissions = styled(Divider)`
  &&{
    height: 3px;
    background: transparent;
  }
`;

export const GrantedAccess = styled.div`
  width: 100%;
  height: 100%;
  background: ${paletteColor.primary.white};
  border: 1.5px solid ${paletteColor.info.border};
  border-radius: 6px;
`;

export const TitleAccessList = styled.div`
  top: 85px;
  font-size: 0.875rem;
  color: #53545C;
  line-height: 1.75;
  font-weight: 500;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & .Titulo{
    margin-left: 10px;
    padding-right: 10px;
  }
`;

export const ComponentsContainer = styled.div`
  height: 50vh;
  width: 48%;
  overflow-y: auto;
`;

//TabsPermission

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FlexContent = styled(FlexContainer)`
  flex-direction: row-reverse;
`;

export const TooltipIcon = styled.img`
  position: relative;
  left: -2px;
  cursor: pointer;
`;

export const TooltipIconModified = styled.img`
  position: absolute;
  margin-left: 10px;
  cursor: pointer;
`;

export const ButtonSaveChanges = styled.button`
  color: ${paletteColor.primary.white};
  font-size: 13px;
  font-weight: bold;
  border-radius: 4px;
  background: ${paletteColor.primary.main};
  padding: 10px 20px;
  outline: none;
  border: 2px solid ${paletteColor.primary.main};
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background: transparent;
    color: ${paletteColor.primary.main};
  }
`;

export const LoadingCircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 45%;
`;

export const ErrorContainer = styled.div`
  position: absolute;
  right: 0;
  top: -30px;
`;

// Modal

export const Loadingwrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
`


export const TitleModalConfirm = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${paletteColor.primary.main};
`;

export const CustomListContainer = styled.div`
  height: 120px;
  overflow: auto;
`;

export const CustomListContainerModal = styled(CustomListContainer)`
  height: 170px;
`;

export const CustomList = styled.li`
  color: #53545C;
  font-size: 14px;
  line-height: 15px;
`;

export const ModalButtonCancel = styled(ButtonSaveChanges)`
  color: ${paletteColor.primary.main};
  background: transparent;
  border-color: transparent;
  margin-right: 10px;

  &:hover {
    border-color: ${paletteColor.primary.main};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// Usuários Cadastrados

export const UserContainer = styled.div`
  && {
    padding: 0px 76px;
  }
  & .InputStyle {
    width: 500px;
    border: 1.5px solid #DFE1EB
    background: #ffffff;
  }
  & .Usuario {
    padding-left: 15px;
  }
`;

export const ResourceTools = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 0;
  min-width: 0;
  overflow: hidden;
  transition: all 400ms;
  margin-left: 25px;

  &::before {
    content: '';
    border-left: 1px solid ${paletteColor.info.border};
    border-radius: 0;
    padding-left: 9px;
  }
`;

export const ListContent = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;

  & .text {
    color: ${paletteColor.info.text};
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
  }

  & .avatar {
    background-color: blueviolet;
    color: white;
  }

  &:hover {
    & .tools {
      width: 45px;
    }
  }
`;

export const CustomGrid = styled(Grid)`
  background: ${paletteColor.primary.white};
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 3px ${paletteColor.info.border};
  border-radius: 4px;
  height: 80px;
`;

// Modal EditUsers

export const CustomTitle = styled(TitleModalConfirm)`
  color: #53545C;
`;

export const SelectModal = styled(CustomSelect)`
  && {
    height: 40px;
    width: 550px;
    margin-right: 15px;

    & .MuiSelect-select {
      margin-top: 0px !important;
      &:before {
        top: 12px;
      }
    }
  }
`;

export const ListModalWrapper = styled.div`
  margin-top: 10px;
  height: 250px;
  overflow: auto;
`;

export const ListModalContent = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  border-radius: 4px;
  background: #F9F9F9;
  height: 40px;
  margin-top: 10px;
  padding-left: 10px;
  color: ${paletteColor.info.soft};
`;

export const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertMessage = styled.div`
  height: 100%;
  color: ${paletteColor.primary.main};
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlignToolTipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  & .MuiIcon-root.sc{
    background: black;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`

export const AlertText = styled.div `
  font-size: 16px;
  color: ${paletteColor.primary.main};
  font-weight: bold;
`;

