import React, { useState, useEffect, useCallback } from 'react';
import {
  ListWorkspace,
  DialogWorkspace,
  DialogWorkspaceDelete,
  DialogWorkspaceSendEmail,
} from '../componentes';
import { getWorkspaces } from '../componentes/DialogConnect';
import { CircularProgress } from '@material-ui/core';
import { IWorkspace } from 'modules/types';
import {
  setDeleteWorkspace,
  setSendExtraHoursApprovedEmail,
} from '../componentes/DialogConnect';

/*
 * @Component WorkspaceContainer
 **/
const WorkspaceContainer = () => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [laodState, setLaodState] = React.useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [workspaceCode, setWorkspaceCode] = useState<string>(null);
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
  const [workspaceDelete, setWorkspaceDelete] = React.useState<string>('');
  const [workspaceEmailSend, setWorkspaceEmailSend] = React.useState<string>(
    ''
  );

  /*
   * @Show or Hidden DialogWorkspace
   */
  const handleWorkspaceDialog = (show: boolean) => {
    setDialogOpen(show);
  };

  /*
   * @Define Workspace to Create or Update
   */
  const handleReloadWorkspace = () => {
    setReload(true);
  };

  /*
   * @Define Workspace to Create or Update
   */
  const handleWorkspaceSingler = (workspaceCode: string) => {
    setWorkspaceCode(workspaceCode);
  };

  /*
   * @Workspaces :: Handle Create Workspace
   * **/
  const handleCreateWorkspace = () => {
    setLaodState(true);
    handleWorkspaceSingler(null);
    handleWorkspaceDialog(true);
    setLaodState(false);
  };

  /*
   * @Workspaces :: Handle Update Workspace
   * **/
  const handleUpdateWorkspace = (workspaceCode: string) => {
    setLaodState(true);
    handleWorkspaceSingler(workspaceCode);
    handleWorkspaceDialog(true);
    setLaodState(false);
  };

  /*
   * @Workspaces :: useCallback
   * **/
  const handleLoadWorkspace = useCallback(async () => {
    getWorkspaces()
      .then(workspaces => {
        setWorkspaces(workspaces);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, []);

  /*
   * @Workspaces :: useEffect
   * **/
  useEffect(() => {
    if (reload) {
      handleLoadWorkspace();
    }
  }, [reload, handleLoadWorkspace]);

  /*
   * @Workspaces :: Return Component
   */
  return (
    <>
      {laodState && (
        <div
          style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 9999 }}
        >
          <CircularProgress size={55} />
        </div>
      )}

      <ListWorkspace
        loading={loading}
        workspaces={workspaces}
        handleUpdateWorkspace={handleUpdateWorkspace}
        handleCreateWorkspace={handleCreateWorkspace}
        setWorkspaceDelete={setWorkspaceDelete}
        setWorkspaceEmailSend={setWorkspaceEmailSend}
      />

      {dialogOpen && (
        <DialogWorkspace
          workspaceCode={workspaceCode}
          handleReloadWorkspace={handleReloadWorkspace}
          handleWorkspaceDialog={handleWorkspaceDialog}
        />
      )}

      {workspaceDelete && (
        <DialogWorkspaceDelete
          workspace={workspaceDelete}
          deleteWorkspace={setDeleteWorkspace}
          handleReloadWorkspace={handleReloadWorkspace}
          handleCloseModal={() => setWorkspaceDelete('')}
        />
      )}

      {workspaceEmailSend && (
        <DialogWorkspaceSendEmail
          workspace={workspaceEmailSend}
          handleCloseModal={() => setWorkspaceEmailSend('')}
          setSendExtraHoursApprovedEmail={setSendExtraHoursApprovedEmail}
        />
      )}
    </>
  );
};

export default WorkspaceContainer;
