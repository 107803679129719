import * as React from 'react';
import { styled } from '@cmp/ui';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { paletteColor } from '../styles/colors';

export const CustomTooltip = styled(({ title, className, ...other }: any) => (
  <Tooltip
    placement="right"
    classes={{ popper: className, tooltip: 'tooltip', arrow: 'arrow' }}
    title={title}
    {...other}
  />
))`
  && {
    & .tooltip {
      background: #17181f;
      width: 250px;
      padding: 14px;
      margin-right: 10px;

      @media (max-width: 375px) {
        width: 190px;
      }
    }
    & .arrow {
      color: #17181f;
      font-size: 13px;
    }
  }
`;

export const IconCustom = styled(Icon)`
  && {
    color: ${paletteColor.info.border};
    transition: color 500ms;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
      color: #f26522;
    }

    &.iconMobile {
      display: none;
    }

    @media (max-width: 768px) {
      &.iconDesktop {
        display: none;
      }

      &.iconMobile {
        display: block;
      }
    }
  }
`;

export const ButtonTooltip = styled.div`
  background: transparent;
  height: 100%;
  width: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #f26522;
  }
`;

export const CustomTooltipContent = styled(Typography)`
  && {
    color: ${paletteColor.primary.white};
    font-size: 14px;

    & .tooltipTitle {
      font-weight: 500;
      margin-bottom: 4px;
    }
  }
`;
