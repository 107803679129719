import styled from 'styled-components';
import { paletteColor } from './../../common/styles/colors'; 

export const LoginInputText = styled.div`
  padding-left: 5px;
  font-weight: 500;
`;
export const LoginInputHelper = styled.div`
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  background: #E00000;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
`;
export const LoginInputContainer = styled.div`
  border: 1.5px solid ${paletteColor.info.border};
  border-radius: 4px;
  position: relative;
  height: 42px;
  & input {
    color: rgb(140,140,140);
    position: absolute;
    font-size: 14px;
    border: none;
    bottom: 3px;
    right: 3px;
    left: 64px;
    top: 3px;
    &:focus {
      outline: none;
    }
  }
`;
export const LoginInputIcon = styled.div`
  background: rgb(235,235,235);
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  height: 34px;
  width: 54px;
  left: 3px;
  top: 3px;  
`;
export const LoginShowText = styled.div`
  position: absolute;
  cursor: pointer;
  use-select: none;
  font-size: 12px;
  color: #E00000;
  right: 10px;
  top: 10px;
`;
