import * as React from 'react';
import { withRouter } from 'react-router';
import { IUser } from '../../auth/types';
import { IRouterProps } from '../../common/types';
import { Navigation } from '../../layout/containers';
import { isAuthenticated } from 'modules/auth';
// import Box from '@material-ui/core/Box';

interface IProps extends IRouterProps {
  children: React.ReactNode;
  getUser: () => IUser;
}

const MainLayout = ({ children, getUser }: IProps) => (
  <>
    {isAuthenticated() ? (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Navigation getUser={getUser}>{children}</Navigation>
      </div>
    ) : (
      children
    )}
  </>
);

export default withRouter<IProps>(MainLayout);
