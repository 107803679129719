type storageType = 'cookies' | 'localStorage';

type options = { [key: string]: string };

type getItemFn = (name: string) => string | null;

type setItemFn = (name: string, value: string, options?: options) => void;

export const setItem = (
  name: string,
  value: string,
  storageType: storageType,
  options = {}
) => {
  const setItemFn = setItemFunctions[storageType];
  setItemFn(name, value, options);
};

export const getItem = (name: string, storageType: storageType) => {
  const getItemFn = getItemFunctions[storageType];
  return getItemFn(name);
};

const setItemFunctions: { [key: string]: setItemFn } = {
  cookies: (name, value, customOptions = {}) => {
    const defaultCookieOptions = { path: '/' };
    const options = { ...defaultCookieOptions, ...customOptions };

    const parsedOptions = Object.keys(options)
      .map(k => `${k}=${options[k]}`)
      .join('; ');

    document.cookie = `${name}=${value}; ${parsedOptions}`;
  },

  localStorage: (name, value) => localStorage.setItem(name, value),
};

const getItemFunctions: { [key: string]: getItemFn } = {
  cookies: name => {
    const cookies = document.cookie.split('; ').map(kv => kv.split('='));
    const cookie = cookies.find(([k, v]) => k === name);

    return cookie ? cookie[1] : null;
  },

  localStorage: name => localStorage.getItem(name),
};
