import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as S from './Styled';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import config from './../../utils/config';

/*
 * @handleAmplyConfig
 **/
const handleAmplyConfig = {
  aws_cognito_region: config.cognitoRegion,
  aws_user_pools_id: config.cognitoPool,
  aws_user_pools_web_client_id: config.clientId,
};

function UnSusbscribeContainer({ location }) {
  const [enviado, setEnviado] = React.useState<Boolean>(false);
  const [message, setmessage] = React.useState<Number>(0);

  function findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    location.search
      .substr(1)
      .split('&')
      .forEach(function(item) {
        tmp = item.split('=');
        if (tmp[0] === parameterName) {
          result = decodeURIComponent(tmp[1]);
        }
      });
    return result;
  }

  return (
    <S.BackgroundContent>
      <S.BoxContent>
        <S.BoxContentHeader>
          <h2>Desinscrição das Notificações</h2>
        </S.BoxContentHeader>
        <S.BoxContentMainText>
          Clique no botão abaixo para se desinscrever do nosso sistema de
          notificações
        </S.BoxContentMainText>

        {enviado === true && (
          <>
            {message === 1 && (
              <S.BoxContentSuccessText>
                Desinscrição concluída
              </S.BoxContentSuccessText>
            )}
            {message === 2 && (
              <S.BoxContentErrorText>
                Erro: não foi possível realizar a desinscrição
              </S.BoxContentErrorText>
            )}
          </>
        )}

        {enviado === true && message === 0 && (
          <S.BoxContentButton>Por favor Aguarde!!!</S.BoxContentButton>
        )}
        {enviado === false && (
          <S.BoxContentButton
            onClick={() => {
              setEnviado(true);
              Amplify.configure(handleAmplyConfig);
              Auth.signIn(`emerson.albuquerque@br.clara.net`, `Clara@123`)
                .then(cognitoUser => {
                  Auth.currentSession().then(
                    (userSession: CognitoUserSession) => {
                      const token = userSession.getAccessToken().getJwtToken();
                      fetch(config.endpoints.GRAPHQL, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                          operationName: 'postUnsubscribeEmail',
                          query:
                            'mutation postUnsubscribeEmail($email: String!) {  postUnsubscribeEmail(email: $email)}',
                          variables: {
                            email: findGetParameter('email'),
                          },
                        }),
                      }).finally(() => {
                        setmessage(1);
                        setEnviado(true);
                      });
                    }
                  );
                })
                .catch(() => {
                  setmessage(2);
                  setEnviado(false);
                });
            }}
          >
            Desinscrever
          </S.BoxContentButton>
        )}

        <img
          src="https://cmp-files-dev.s3.amazonaws.com/Mandic/Notifications/rodape_claranet.png"
          style={{ width: '100%' }}
          alt="Logo da Claranet"
        />
      </S.BoxContent>
    </S.BackgroundContent>
  );
}

const routes = () => (
  <Switch>
    <Route path="/unsubscribe" exact={true} component={UnSusbscribeContainer} />
  </Switch>
);

export default routes;
