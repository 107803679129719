import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import * as S from './Styled';

interface IProps {
  handleCloseDialogSuccessReset: () => void
}

const LoginSuccessReset = ({
  handleCloseDialogSuccessReset
} : IProps) => {
  return (
    <Dialog maxWidth="lg" open={true}>
      <S.DialogInner>
        <S.DialogTitleCenter>
          Redefinição de Senha
          <S.DialogClose onClick={() => handleCloseDialogSuccessReset()}>X</S.DialogClose>
        </S.DialogTitleCenter>
        <S.DialogMain>
          <S.DialogSuccess>
            <S.DialogSuccessMsg>
              Sua senha foi redefinida com sucesso!
            </S.DialogSuccessMsg>
          </S.DialogSuccess>
        </S.DialogMain>
      </S.DialogInner>
      <S.DialogFooterRight>
        <S.DialogBottomAction
          variant="contained"
          color="primary"
          onClick={() => handleCloseDialogSuccessReset()}
        >Fazer Login
        </S.DialogBottomAction>
      </S.DialogFooterRight>
    </Dialog>
  );
};

export default LoginSuccessReset;
