import moment from 'moment';
import 'moment/locale/pt-br';
import * as React from 'react';
import { IUser, IError } from './modules/auth/types';

interface IProps {
  currentUser: IUser;
}

interface IState {
  errors: IError[];
  currentUser: IUser;
}

interface IStore extends IState {
  setError: (errorCode: number, errorMessage: string) => void;
  setUser: (user: IUser) => void;
  getUser: () => IUser;
}

export const AppContext = React.createContext({} as IStore);

export const AppConsumer = AppContext.Consumer;

export class AppProvider extends React.Component<IProps,IState> {
  constructor(props: IProps) {
    super(props);

    const initialErrors: IError[] = [];

    this.state = {
      errors: initialErrors,
      currentUser: props.currentUser,
    };
    moment.locale('pt-br');
  }

  public setError = (errorCode: number, errorMessage: string) => {
    const error: IError | undefined = this.state.errors.find(
      ({ code, message }: IError) => errorCode === code && errorMessage === message
    );

    if (error !== undefined) {
      this.setState((state: IState) => {
        const currentErrors = state.errors;
        currentErrors.push(error);
        return { errors: currentErrors};
      });
    }
  };

  public setUser = (user: IUser) => {
    this.setState({ currentUser: user });
  };

  public getUser = (): IUser => {
    return this.state.currentUser;
  }

  public render() {
    const {
      errors,
      currentUser,
    }: IState = this.state;

    return (
      <AppContext.Provider
        value={{
          errors,
          currentUser,
          setUser: this.setUser,
          getUser: this.getUser,
          setError: this.setError,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
