const createWorkspace = `
  mutation createWorkspace(
    $name: String,
    $poolId: String,
    $clientCode: String,
    $jira: String,
    $pagerduty: String,
    $startedAt: String!
    $planType: String!
  ) {
    createWorkspace(workspace: {
      name: $name,
      poolId: $poolId,
      clientCode: $clientCode,
      jira: $jira,
      pagerduty: $pagerduty,
      startedAt: $startedAt,
      planType: $planType
    })
  }
`;

const updateWorkspace = `
  mutation updateWorkspace(
    $workspaceCode: String!,
    $name: String,
    $poolId: String,
    $clientCode: String,
    $jira: String,
    $pagerduty: String,
    $startedAt: String!
    $planType: String!
  ) {
    updateWorkspace(values: {
      name: $name,
      poolId: $poolId
      clientCode: $clientCode,
      jiraProject: $jira,
      pagerduty: $pagerduty,
      startedAt: $startedAt,
      planType: $planType
    }, workspaceCode: $workspaceCode) {
      name
      code
      clientCode
      jiraProject
      pagerduty
    }
  }
`;

const deleteWorkspace = `
  mutation deleteWorkspace($workspaceCode: String!) {
    deleteWorkspace(workspaceCode: $workspaceCode)
  }
`;

const updateAccounts = `
  mutation updateAccounts($cd: String!, $accounts: [AwsAccountInput!]!){
    updateAccounts(cd: $cd, accounts: $accounts)
  }
`;

const updateProjects = `
  mutation updateProjects($cd: String!, $projectsKeys: [String!]!){
    updateProjects(cd: $cd, projectsKeys: $projectsKeys)
  }
`;

const updateWorklogs = `
  mutation updateWorkLogs($projects: [String!]!){
    updateWorkLogs(projects: $projects)
  }
`;

const sendExtraHoursApprovedEmail = `
  mutation sendExtraHoursApprovedEmail($workspace: String!, $extraHours: Float!) {
    sendExtraHoursApprovedEmail(workspace: $workspace, extraHours: $extraHours)
  }
`;

const updateNotifications = `
  mutation updateNotifications($workspace: String!, $notificationsEmails: [MutationNotificationsInput!]!){
    updateNotifications(workspace: $workspace, notificationsEmails: $notificationsEmails)
  }
`;

export default {
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
  updateAccounts,
  updateProjects,
  updateWorklogs,
  sendExtraHoursApprovedEmail,
  updateNotifications,
};
