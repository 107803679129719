import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import * as S from './Styled';

interface IProps {
  workspace: string;
  handleCloseModal: () => void;
  deleteWorkspace: (workspace: string) => any;
  handleReloadWorkspace: () => void;
}

const DialogWorkspaceDelete = ({
  workspace,
  handleCloseModal,
  deleteWorkspace,
  handleReloadWorkspace,
}: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteWorkspace = async () => {
    if (workspace) {
      try {
        setLoading(true);
        await deleteWorkspace(workspace);
      } finally {
        setLoading(false);
        handleCloseModal();
        handleReloadWorkspace();
      }
    }
  };

  return (
    <Dialog maxWidth="lg" open={Boolean(workspace)} onClose={handleCloseModal}>
      <S.DialogContentCustom>
        <Box padding={2}>
          <Typography>Deseja deletar a workspace?</Typography>
        </Box>
      </S.DialogContentCustom>
      <S.GroupButton>
        <Button
          color="primary"
          onClick={handleCloseModal}
          style={{ marginRight: 20 }}
        >
          Não
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleDeleteWorkspace}
          disabled={loading}
        >
          Sim
        </Button>
      </S.GroupButton>
    </Dialog>
  );
};

export default DialogWorkspaceDelete;
