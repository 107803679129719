import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from 'modules/auth';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated() === true ?
        <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
  )} />
);

export default ProtectedRoute;
