import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/moment';
import { CircularProgress } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Tooltip from 'modules/common/components/Tooltip';
import { Input } from '@cmp/ui';
import * as S from './StyledWorkspace';
import {
  getPools,
  getProjects,
  getAccounts,
  getWorkspace,
  setUpdateWorklogs,
  setUpdateWorkspace,
  setUpdateAccounts,
  setUpdateProjects,
  getNotifications,
  setUupdateNotifications,
  setCreateWorkspace,
} from '../componentes/DialogConnect';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as Trash } from 'modules/common/icons/icon-trash.svg';
import {
  IPool,
  IProject,
  IAccount,
  INotification,
  MutationNotificationsInput,
} from 'modules/types';
import moment from 'moment';

// Create Inteface IProps
interface IProps {
  workspaceCode: string;
  handleReloadWorkspace: () => void;
  handleWorkspaceDialog: (show: boolean) => void;
}

/*
 * @Component DialogWorkspace
 **/
const DialogWorkspace = ({
  workspaceCode,
  handleWorkspaceDialog,
  handleReloadWorkspace,
}: IProps) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingWorklogs, setLoadingWorklogs] = useState<boolean>(false);
  const [loadingUpdateWorkspace, setLoadingUpdateWorkspace] = useState<boolean>(
    false
  );

  /*
   * Informações de Workspace
   */
  const [workspacePools, setWorkspacePools] = useState<IPool[]>([]);
  const [workspaceName, setWorksaceName] = useState<string>('');
  const [workspaceClientCode, setWorksaceClientCode] = useState<string>('');
  const [workspacePlanType, setWorkspacePlanType] = useState<string>('');
  const [workspacePool, setWorkspacePool] = useState<IPool>();
  const [workspacePoolId, setWorkspacePoolId] = useState<string>('');
  const [workspacePoolDate, setWorkspacePoolDate] = useState<any>(moment());
  const [workspaceJiraProject, setWorkspaceJiraProject] = useState<string>('');
  const [workspacePagerduty, setWorkspacePagerduty] = useState<string>('');
  const [workspaceProjects, setWorkspaceProjects] = useState<IProject[]>([
    { key: '', id: 0, category: '', name: '', pool: '' },
  ]);
  const [workspaceAccounts, setWorkspaceAccounts] = useState<IAccount[]>([
    { accountId: '', alias: '' },
  ]);
  const [workspaceContactsClient, setWorkspaceContactsClient] = useState<
    INotification[]
  >([{ email: '', tipo: '' }]);
  const [workspaceContactsTime, setWorkspaceContactsTime] = useState<
    INotification[]
  >([{ email: '', tipo: '' }]);
  const [
    workspaceEmalExecutiveContas,
    setWorkspaceEmalExecutiveContas,
  ] = useState<string>('');

  let workspaceNotifications: INotification[] = [
    {
      email: '',
      tipo: '',
    },
  ];

  /*
   * @Workspaces :: allowOnlyNumbers
   * **/
  const allowOnlyNumbers = event => {
    if (/\D/.test(event.key)) {
      event.preventDefault();
    }
  };

  /*
   * @Workspaces :: removeWords
   * **/
  const removeWords = value => {
    return value.replace(/[^\d.-]|\./g, '');
  };

  /*
   * @Workspaces :: addPrefixClientCode
   * **/
  const addPrefixClientCode = value => {
    return `CD.${removeWords(value)}`;
  };

  /*
   * @Workspaces :: LoadWorkspaceToCreate
   * **/
  const LoadWorkspaceToCreate = () => {
    getPools().then(pools => {
      setLoading(true);
      setWorkspacePools(pools);
    });
  };

  /*
   * @Workspaces :: LoadWorkspaceToUpdate
   * **/
  const LoadWorkspaceToUpdate = () => {
    if (loading === false) {
      getWorkspace(workspaceCode).then(workspace => {
        getPools().then(pools => {
          getProjects(workspace.clientCode).then(projects => {
            getAccounts(workspace.clientCode).then(accounts => {
              getNotifications(workspace.code).then(
                (notifications: INotification[]) => {
                  setLoading(true);
                  workspaceNotifications = notifications;
                  if (
                    workspaceNotifications.filter(n => n.tipo === 'T')
                      .length !== 0
                  ) {
                    setWorkspaceContactsTime(
                      workspaceNotifications.filter(n => n.tipo === 'T')
                    );
                  }
                  if (
                    workspaceNotifications.filter(n => n.tipo === 'C')
                      .length !== 0
                  ) {
                    setWorkspaceContactsClient(
                      workspaceNotifications.filter(n => n.tipo === 'C')
                    );
                  }
                  setWorkspacePools(pools);
                  setWorksaceName(workspace.name);
                  setWorksaceClientCode(removeWords(workspace.clientCode));
                  setWorkspacePlanType(workspace.planType);
                  setWorkspacePool(
                    pools.find(p => p.id === workspace.activePool.poolId)
                  );
                  setWorkspacePoolId(workspace.activePool.poolId);
                  setWorkspacePoolDate(moment(workspace.activePool.started_at));
                  setWorkspaceJiraProject(workspace.jiraProject);
                  setWorkspacePagerduty(workspace.pagerduty);

                  if (projects.length !== 0) {
                    setWorkspaceProjects(projects);
                  }
                  if (accounts.length !== 0) {
                    setWorkspaceAccounts(accounts);
                  }
                  setWorkspaceEmalExecutiveContas(workspace.emailExecContas);
                }
              );
            });
          });
        });
      });
    }
  };

  /*
   * @Workspaces :: useEffect
   * **/
  (() => {
    if (loading === false) {
      if (workspaceCode === null) {
        LoadWorkspaceToCreate();
      } else {
        LoadWorkspaceToUpdate();
      }
    }
  })();

  /*
   * @Workspaces :: Returns DialogWorkspace
   * **/
  return (
    loading && (
      <>
        <Dialog
          open={true}
          maxWidth={'lg'}
          onClose={() => handleWorkspaceDialog(false)}
        >
          <S.DialogTitle>
            {workspaceCode ? 'Editar Workspace' : 'Nova Workspace'}
          </S.DialogTitle>
          <S.DialogTabs
            onClick={event => {
              setTabIndex(
                Array.prototype.indexOf.call(
                  event.target.parentElement.children,
                  event.target
                )
              );
            }}
          >
            <S.DialogTabsItem>
              Cliente
              {tabIndex === 0 && <S.TabsItemIndicator />}
            </S.DialogTabsItem>
            <S.DialogTabsItem>
              Notificações
              {tabIndex === 1 && <S.TabsItemIndicator />}
            </S.DialogTabsItem>
          </S.DialogTabs>
          <S.DialogContent>
            {tabIndex === 0 && (
              <>
                <S.DialogContentInner>
                  <S.SubTitle>Informações de cliente:</S.SubTitle>
                  <Grid container style={{ marginBottom: 6 }}>
                    <Grid item xs={12}>
                      <Input
                        required={true}
                        type="text"
                        text="Nome da Empresa"
                        fullWidth={true}
                        value={workspaceName}
                        onChange={e => setWorksaceName(e.target.value)}
                        error={''}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={2}>
                          <div
                            style={{
                              backgroundColor: 'rgb(240,240,240)',
                              border: '1px solid rgb(225,225,225)',
                              height: '42px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: 'rgb(140,140,140)',
                            }}
                          >
                            CD.
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <Input
                            required={true}
                            type="text"
                            text="Código do Cliente (CD)"
                            fullWidth={true}
                            value={workspaceClientCode}
                            onChange={e =>
                              setWorksaceClientCode(e.target.value)
                            }
                            inputProps={{
                              onKeyPress: e => allowOnlyNumbers(e),
                            }}
                            error={''}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <S.SelectContent>
                        <S.AutocompleteCustom
                          autoComplete
                          key={''}
                          disableClearable
                          options={['SRE', 'DADOS']}
                          value={workspacePlanType}
                          onChange={(_, value) => setWorkspacePlanType(value)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              type="string"
                              label="Plano do cliente"
                              variant="filled"
                              className="selectLabel"
                              fullWidth={true}
                              required={true}
                            />
                          )}
                        />
                      </S.SelectContent>
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
                <S.DialogContentBar />
                <S.DialogContentInner>
                  <S.SubTitle>Pool de horas:</S.SubTitle>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <S.SelectContent>
                        <S.AutocompleteCustom
                          autoComplete
                          key={
                            workspacePool ? workspacePool.id : 'workspacePoolId'
                          }
                          disableClearable
                          options={workspacePools}
                          getOptionLabel={pool => pool.name}
                          value={workspacePool}
                          onChange={(e, value) => {
                            setWorkspacePoolId(value.id);
                            setWorkspacePool(value);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              type="string"
                              label="Pool de horas contratadas"
                              variant="filled"
                              className="selectLabel"
                              fullWidth={true}
                              required={true}
                            />
                          )}
                        />
                      </S.SelectContent>
                    </Grid>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <S.DatePickerCustom
                          autoOk={true}
                          value={workspacePoolDate}
                          onChange={value => {
                            setWorkspacePoolDate(value);
                          }}
                          format="DD/MM/YYYY"
                          variant="inline"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
                <S.DialogContentBar />
                <S.DialogContentInner>
                  <S.SubTitle>Reports:</S.SubTitle>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Input
                        required={true}
                        type="text"
                        text="Key do Jira para gerar Report"
                        value={workspaceJiraProject}
                        onChange={e => setWorkspaceJiraProject(e.target.value)}
                        error={''}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        type="text"
                        text="pagerduty Service"
                        value={workspacePagerduty}
                        onChange={e => setWorkspacePagerduty(e.target.value)}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
                <S.DialogContentBar />
                <S.DialogContentInner>
                  <S.SubTitle>
                    Projetos Ágeis:{' '}
                    <Tooltip
                      description="Key do projeto no Jira para visualização na página de Projetos Àgeis e Cloud Compliance, no MCP. Mais de uma key pode ser cadastrada. clicando em 'Adicionar Key'"
                      icon="info"
                    />
                  </S.SubTitle>
                  {workspaceProjects.map((project: IProject, index: number) => {
                    return (
                      <Grid container spacing={2} key={`grid-projects${index}`}>
                        <Grid item xs={6}>
                          <Input
                            key={`projects-${index}`}
                            type="text"
                            text="Key do Jira para Projetos Ágeis"
                            fullWidth={true}
                            inputProps={{
                              name: 'jirakey',
                            }}
                            value={project.key}
                            onChange={e => {
                              const tmpList = [...workspaceProjects];
                              tmpList[index].key = e.target.value;
                              setWorkspaceProjects(tmpList);
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="mail"
                            size="small"
                            onClick={() => {
                              const tmpList = [...workspaceProjects];
                              tmpList.splice(index, 1);
                              setWorkspaceProjects(tmpList);

                              if (tmpList === null || tmpList.length === 0) {
                                setWorkspaceProjects([{ key: '' } as IProject]);
                              }
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </Grid>
                        {index === 0 && (
                          <>
                            <Grid item xs={4}>
                              <Button
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                disabled={false}
                                onClick={() => {
                                  const tmpList: string[] = [];
                                  workspaceProjects.map((project: IProject) =>
                                    tmpList.push(project.key)
                                  );
                                  setLoadingWorklogs(true);

                                  // @CALL setUpdateWorklogs
                                  setUpdateWorklogs(tmpList).finally(() => {
                                    setLoadingWorklogs(false);
                                  });
                                }}
                              >
                                {loadingWorklogs && (
                                  <CircularProgress
                                    size={16}
                                    style={{ marginRight: 8 }}
                                  />
                                )}
                                {loadingWorklogs
                                  ? `Atualizando`
                                  : `Atualizar Worklogs`}
                              </Button>
                            </Grid>
                            <Grid item xs={1}>
                              <div
                                style={{
                                  height: 42,
                                  width: 42,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Tooltip
                                  description="Permite a atualizalção de issues e de workflogs quando as informações estão inconsistentes entre o Jira e o MCP"
                                  icon="info"
                                />
                              </div>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    );
                  })}
                  <Grid container>
                    <Grid item xs={6}>
                      <S.InsertGroupInput
                        style={{ marginRight: 8 }}
                        onClick={() => {
                          setWorkspaceProjects([
                            ...workspaceProjects,
                            { key: '' } as IProject,
                          ]);
                        }}
                      >
                        Adiconar Key
                      </S.InsertGroupInput>
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
                <S.DialogContentBar />
                <S.DialogContentInner>
                  <S.SubTitle>
                    Contas AWS do cliente:{' '}
                    <Tooltip
                      description="O ID da conta é único e contém 12 dígitos. O apelido, ou alias, é associado ao ID da conta AWS e será mostrado no MCP para ajudar a identificar a conta. Atenção: para cadastrar contas, é necessário que 
                      o workspace tenha um CD válido associado a ele."
                      icon="info"
                    />
                  </S.SubTitle>
                  {workspaceAccounts.map((account: IAccount, index: number) => {
                    return (
                      <Grid container spacing={2} key={`grid-accounts${index}`}>
                        <Grid item xs={6}>
                          <Input
                            key={`accountId-${index}`}
                            type="text"
                            text="ID da Conta AWS"
                            fullWidth={true}
                            value={account.accountId || ''}
                            onChange={e => {
                              const tmpList = [...workspaceAccounts];
                              tmpList[index].accountId = e.target.value;
                              setWorkspaceAccounts(tmpList);
                            }}
                            inputProps={{
                              name: 'accountId',
                              onKeyPress: e => allowOnlyNumbers(e),
                            }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Input
                            key={`accountAlias-${index}`}
                            type="text"
                            text="Apelido da conta AWS"
                            fullWidth={true}
                            mr={8}
                            value={account.alias || ''}
                            onChange={e => {
                              const tmpList = [...workspaceAccounts];
                              tmpList[index].alias = e.target.value;
                              setWorkspaceAccounts(tmpList);
                            }}
                            inputProps={{
                              name: 'alias',
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="mail"
                            size="small"
                            onClick={() => {
                              const tmpList = [...workspaceAccounts];
                              tmpList.splice(index, 1);
                              setWorkspaceAccounts(tmpList);

                              if (tmpList === null || tmpList.length === 0) {
                                setWorkspaceAccounts([
                                  { accountId: '', alias: '' } as IAccount,
                                ]);
                              }
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container>
                    <Grid item xs={11}>
                      <S.InsertGroupInput
                        onClick={() => {
                          setWorkspaceAccounts([
                            ...workspaceAccounts,
                            { accountId: '', alias: '' } as IAccount,
                          ]);
                        }}
                      >
                        Adiconar conta AWS
                      </S.InsertGroupInput>
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
              </>
            )}
            {tabIndex === 1 && (
              <>
                <S.DialogContentInner>
                  <S.notificationtext>
                    <span>
                      Adicione aqui os endereços de e-mail de todos os contatos
                      que devem ser <br /> notificados sobre o status de
                      ultilização do plano de horas contratado.
                    </span>
                  </S.notificationtext>
                  <S.SubTitle>
                    Contato(s) do cliente
                    <Tooltip
                      description="Recebe notificações quando o pool mensal de horas atinge 50% 80% e/ou 100%"
                      icon="info"
                    />
                  </S.SubTitle>
                  {workspaceContactsClient.map(
                    (contactCliente: INotification, index: number) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          key={`grid-contacts-client${index}`}
                        >
                          <Grid item xs={11}>
                            <Input
                              key={`contactCliente-${index}`}
                              required={false}
                              type="text"
                              text="E-mail"
                              fullWidth={true}
                              value={contactCliente.email}
                              onChange={e => {
                                const tmpList = [...workspaceContactsClient];
                                tmpList[index].email = e.target.value;
                                setWorkspaceContactsClient(tmpList);
                              }}
                              error={''}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              aria-label="mail"
                              size="small"
                              onClick={() => {
                                const tmpList = [...workspaceContactsClient];
                                tmpList.splice(index, 1);
                                setWorkspaceContactsClient(tmpList);

                                if (tmpList === null || tmpList.length === 0) {
                                  setWorkspaceContactsClient([
                                    { email: '', tipo: 'C' } as INotification,
                                  ]);
                                }
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                  <Grid container>
                    <Grid item xs={11}>
                      <S.InsertGroupInput
                        onClick={() => {
                          setWorkspaceContactsClient([
                            ...workspaceContactsClient,
                            { email: '', tipo: 'C' } as INotification,
                          ]);
                        }}
                      >
                        Adicionar E-mail
                      </S.InsertGroupInput>
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
                <S.DialogContentBar />
                <S.DialogContentInner>
                  <S.SubTitle>
                    Contato(s) do time
                    <Tooltip
                      description="Recebe notificações quando o pool mensal de horas atinge 50% 80% e/ou 100%"
                      icon="info"
                    />
                  </S.SubTitle>
                  {workspaceContactsTime.map(
                    (contactTime: INotification, index: number) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          key={`grid-contacts-time${index}`}
                        >
                          <Grid item xs={11}>
                            <Input
                              key={`contactTime-${index}`}
                              required={false}
                              type="text"
                              text="E-mail"
                              fullWidth={true}
                              value={contactTime.email}
                              onChange={e => {
                                const tmpList = [...workspaceContactsTime];
                                tmpList[index].email = e.target.value;
                                setWorkspaceContactsTime(tmpList);
                              }}
                              error={''}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              aria-label="mail"
                              size="small"
                              onClick={() => {
                                const tmpList = [...workspaceContactsTime];
                                tmpList.splice(index, 1);
                                setWorkspaceContactsTime(tmpList);

                                if (tmpList === null || tmpList.length === 0) {
                                  setWorkspaceContactsTime([
                                    { email: '', tipo: 'T' } as INotification,
                                  ]);
                                }
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
                  <Grid container>
                    <Grid item xs={11}>
                      <S.InsertGroupInput
                        onClick={() => {
                          setWorkspaceContactsTime([
                            ...workspaceContactsTime,
                            { email: '', tipo: 'T' } as INotification,
                          ]);
                        }}
                      >
                        Adicionar E-mail
                      </S.InsertGroupInput>
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
                <S.DialogContentBar />
                <S.DialogContentInner>
                  <S.SubTitle>
                    Executivo de contas
                    <Tooltip
                      description="Recebe notificações quando o pool mensal de horas atinge 100%"
                      icon="info"
                    />
                  </S.SubTitle>
                  <Grid container style={{ marginBottom: 6 }}>
                    <Grid item xs={12}>
                      <div className="BoxInner">
                        <S.CustomBoxField>
                          <Input
                            required={true}
                            type="text"
                            text="Email"
                            fullWidth={true}
                            value={workspaceEmalExecutiveContas || ''}
                            onChange={() => {}}
                            error={''}
                          />
                        </S.CustomBoxField>
                      </div>
                    </Grid>
                  </Grid>
                </S.DialogContentInner>
              </>
            )}
          </S.DialogContent>
          <S.DialogFooter>
            <Button
              color="primary"
              size={'medium'}
              onClick={() => handleWorkspaceDialog(false)}
              style={{ marginRight: 20 }}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={loadingUpdateWorkspace}
              onClick={() => {
                if (workspaceCode === null) {
                  // Criete Workspace
                  setCreateWorkspace(
                    workspaceName,
                    workspacePoolId,
                    addPrefixClientCode(workspaceClientCode),
                    workspaceJiraProject,
                    workspacePagerduty,
                    workspacePoolDate.format('DD/MM/YYYY'),
                    workspacePlanType
                  ).finally(() => {
                    setUpdateAccounts(
                      addPrefixClientCode(workspaceClientCode),
                      workspaceAccounts
                    ).finally(() => {
                      let workspaceProjects_: string[] = [];
                      workspaceProjects.map((project: IProject) => {
                        return workspaceProjects_.push(project.key);
                      });
                      setUpdateProjects(
                        addPrefixClientCode(workspaceClientCode),
                        workspaceProjects_
                      ).finally(() => {
                        // update notifications emails
                        const notificatonsEmails: MutationNotificationsInput[] = [];

                        [
                          ...workspaceContactsClient,
                          ...workspaceContactsTime,
                        ].map((client: INotification) => {
                          return notificatonsEmails.push({
                            tipo: client.tipo,
                            nome: workspaceName,
                            email: client.email,
                          });
                        });

                        setUupdateNotifications(
                          workspaceCode,
                          notificatonsEmails
                        ).finally(() => {
                          setLoadingUpdateWorkspace(false);
                          handleWorkspaceDialog(false);
                          handleReloadWorkspace();
                        });
                      });
                    });
                  });
                } else {
                  // Update Workspace
                  setLoadingUpdateWorkspace(true);
                  setUpdateWorkspace(workspaceCode, {
                    name: workspaceName,
                    poolId: workspacePoolId,
                    clientCode: addPrefixClientCode(workspaceClientCode),
                    jira: workspaceJiraProject,
                    pagerduty: workspacePagerduty,
                    startedAt: workspacePoolDate.format('DD/MM/YYYY'),
                    planType: workspacePlanType,
                  }).finally(() => {
                    setUpdateAccounts(
                      addPrefixClientCode(workspaceClientCode),
                      workspaceAccounts
                    ).finally(() => {
                      let workspaceProjects_: string[] = [];
                      workspaceProjects.map((project: IProject) => {
                        return workspaceProjects_.push(project.key);
                      });

                      setUpdateProjects(
                        addPrefixClientCode(workspaceClientCode),
                        workspaceProjects_
                      ).finally(() => {
                        // update notifications emails
                        const notificatonsEmails: MutationNotificationsInput[] = [];

                        [
                          ...workspaceContactsClient,
                          ...workspaceContactsTime,
                        ].map((client: INotification) => {
                          return notificatonsEmails.push({
                            tipo: client.tipo,
                            nome: workspaceName,
                            email: client.email,
                          });
                        });

                        setUupdateNotifications(
                          workspaceCode,
                          notificatonsEmails
                        ).finally(() => {
                          setLoadingUpdateWorkspace(false);
                          handleWorkspaceDialog(false);
                          handleReloadWorkspace();
                        });
                      });
                    });
                  });
                }
              }}
            >
              {workspaceCode === null ? 'Criar Workspace' : 'Editar Workspace'}
            </Button>
          </S.DialogFooter>
        </Dialog>
      </>
    )
  );
};

export default DialogWorkspace;
