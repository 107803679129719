import * as React from 'react';
import { IRouterProps } from 'modules/common/types';
import { ListUser } from '../componentes';
import { queries, mutations } from '../graphql';
import client from 'apolloClient';
import gql from 'graphql-tag';
import { IWorkspace } from 'modules/types';
import { IUser } from 'modules/types';

interface IProps extends IRouterProps {
  workspace?: string;
}

interface IUserResponse {
  users: IUser[];
  lastPage: boolean;
}

type State = {
  formState: boolean;
  loading: boolean;
};

export default class UserContainer extends React.Component<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      formState: false,
      loading: true,
    };
  }

  public getUsers(page: number = 0, name?: string): Promise<IUserResponse> {
    const nameSplited = name ? name.split(' ') : [];
    const firstName = nameSplited[0];
    const secondName = nameSplited[1];
    const email = name ? name.replace(' ', '.') : undefined;

    return client
      .query({
        query: gql(queries.users),
        variables: { page, firstName, secondName, email },
        fetchPolicy: 'network-only',
      })
      .then(response => response.data.users);
  }

  public getUser(id: string) {
    return client
      .query({
        query: gql(queries.user),
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .then(response => response.data.users);
  }

  public getWorkspaces(): Promise<IWorkspace[]> {
    return client
      .query({
        query: gql(queries.workspaces),
        fetchPolicy: 'network-only',
      })
      .then(response => response.data.workspaces.workspaces);
  }

  public createUser(
    workspaces: string[],
    username: string,
    email: string,
    password: string,
    resetPassword: boolean
  ) {
    return client.mutate({
      mutation: gql(mutations.createUser),
      variables: {
        workspaces,
        username,
        email,
        password,
        resetPassword,
      },
    });
  }

  public editUser(
    workspaces: string[],
    email: string,
    newEmail: string,
    password: string,
    resetPassword: boolean
  ) {
    return client.mutate({
      mutation: gql(mutations.editUser),
      variables: {
        workspaces,
        email,
        newEmail,
        password: password || undefined,
        resetPassword,
      },
    });
  }

  public deleteUser(email: string) {
    return client.mutate({
      mutation: gql(mutations.deleteUser),
      variables: {
        email,
      },
    });
  }

  render() {
    return (
      <ListUser
        loading={this.state.loading}
        createUser={this.createUser}
        formState={this.state.formState}
        getWorkspaces={this.getWorkspaces}
        getUsers={this.getUsers}
        editUser={this.editUser}
        deleteUser={this.deleteUser}
        setLoading={value => this.setState({ loading: value })}
      />
    );
  }
}
