import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import * as S from './Styled';
import LoginInput from './LoginInput';
// import { ReactComponent as IconMail } from 'modules/common/icons/mail.svg';
// import DialogTitle from '@material-ui/core/DialogTitle';

interface IProps {
  handleCloseDialogForget: () => void;
  handleOpenDialogReset: (emailReset:string) => void;
}

const LoginForget = ({
  handleCloseDialogForget,
  handleOpenDialogReset
}: IProps) => {
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<boolean>(false);

  return (
    <Dialog maxWidth="lg" open={true}>
      <S.DialogInner>
        <S.DialogTitle>
          Esqueci minha senha
          <S.DialogClose onClick={() => handleCloseDialogForget()}>X</S.DialogClose>
        </S.DialogTitle>
        <S.DialogMain>
          <LoginInput
            value={email}
            error={emailError}
            type="text"
            text="Informe seu email"
            helper="Obrigatório informar email"
            plasholder="Email"
            onBlur={(event) => setEmailError(event.target.value === "")}
            onChange={(event) => setEmail(event.target.value)}
          />
        </S.DialogMain>
      </S.DialogInner>
      <S.DialogFooterRight>
        <S.DialogBottomCancel
          onClick={() => handleCloseDialogForget()}
        >Fechar</S.DialogBottomCancel>
        <S.DialogBottomAction
          variant="contained"
          color="primary"
          onClick={() => {
            setEmailError(email === "");

            if(email !== "")
              handleOpenDialogReset(email);
          }}
        >
          Solicitar senha
        </S.DialogBottomAction>
      </S.DialogFooterRight>
    </Dialog>
  );
};

export default LoginForget;
