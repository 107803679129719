const workspaces = `
  query workspaces {
    workspaces(orderBy: "name") {
      workspaces {
        name
        code
        clientCode
        users {
          username
          email
        }
      }
    }
  }
`;

const workspace = `
  query workspace($code: String) {
    workspaces(filter: { code: $code }) {
      workspaces {
        name
        code
        clientCode
        jiraProject
        pagerduty
        emailExecContas
        activePool {
          poolId
          started_at
        }
        planType
      }
    }
  }
`;

const pools = `
  query pools($orderBy: String) {
    pools(orderBy: $orderBy){
      id,
      name,
      pool_size
    }
  }
`;

const accounts = `
  query accounts($cd: String!) {
    accounts(filter: { cd: $cd }) {
        accountId
        alias
    }
  }
`;

const projects = `
  query projects($cd: String!) {
    projects(filter: { cd: $cd }) {
      id
      key
      name
      category
      pool
    }
  }
`;

const notifications = `
  query notifications($workspace: String!) {
    notifications(filter: { workspace: $workspace }) {
      tipo
      email
    }
  }  
`;

export default {
  workspaces,
  workspace,
  pools,
  accounts,
  projects,
  notifications,
};
