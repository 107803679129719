import * as React from 'react';
import { Input } from '@cmp/ui';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';
import * as S from './Styled';
import DialogUserCreate from './DialogUserCreate';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import User from './User';
import { IWorkspace } from 'modules/types';
import { IUser } from 'modules/types';
import DeleteUser from './DialogUserDelete';
import UserEmpty from './UserEmpty';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import DialogUserEdit from './DialogUserEdit';
import InfiniteScroller from './InfiniteScroller';
import { takeUntil } from 'utils/operators';

interface IUserResponse {
  users: IUser[];
  lastPage: boolean;
}

interface IProps {
  loading: boolean;
  setLoading: (value: boolean) => void;
  formState: boolean;
  getWorkspaces: () => Promise<IWorkspace[]>;
  createUser: (
    workspaces: string[],
    username: string,
    email: string,
    password: string,
    resetPassword: boolean
  ) => any;
  getUsers: (page: number, name?: string) => Promise<IUserResponse>;
  editUser: (
    workspaces: string[],
    email: string,
    newEmail: string,
    password: string,
    resetPassword: boolean
  ) => Promise<any>;
  deleteUser: (email: string) => Promise<any>;
}

const ListUser = ({
  loading,
  setLoading,
  getWorkspaces,
  createUser,
  getUsers,
  editUser,
  deleteUser,
}: IProps) => {
  const [openDialogUserCreate, setOpenDialogUserCreate] = React.useState<
    boolean
  >(false);
  const [openDialogUserEdit, setOpenDialogUserEdit] = React.useState<boolean>(
    false
  );
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [user, setUser] = React.useState<IUser | undefined>(undefined);
  const [email, setEmail] = React.useState<string>('');
  const [reload, setReload] = React.useState<boolean>(true);
  const [searchField, setSearchField] = React.useState<string>('');
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const [workspaces, setWorkspaces] = React.useState<IWorkspace[]>([]);
  const [buttonToggle, setButtonToggle] = React.useState<boolean>(false);

  const retrieveUsers = React.useCallback(
    async (pageNumber: number, search?: string) => {
      setLoading(true);
      setReload(false);

      try {
        const response: IUserResponse = await getUsers(0, search);
        setUsers(response.users);
        const hasMoreToggle = !(
          response.lastPage || response.users.length === 0
        );
        setHasMore(hasMoreToggle);
      } catch (error) {
        console.error('erro ao listar usuários', error);
      } finally {
        setLoading(false);
      }
    },
    [getUsers, setLoading]
  );

  React.useEffect(() => {
    if (reload) {
      retrieveUsers(0);
    }
  }, [setUsers, retrieveUsers, reload, users]);

  const waitTime = 1000;
  const memoizedTakeUntil = React.useMemo(() => takeUntil(waitTime), [
    waitTime,
  ]);
  const onChangeSearchField = (currentValue: string) => {
    setSearchField(currentValue);
    memoizedTakeUntil(retrieveUsers, 0, currentValue);
    setButtonToggle(Boolean(currentValue));
  };

  const loadWorkspaces = React.useCallback(async () => {
    try {
      const response = await getWorkspaces();
      setWorkspaces(response);
    } catch (e) {
      console.error('Não foi possível carregar os Workspaces');
    }
  }, [getWorkspaces, setWorkspaces]);

  React.useEffect(() => {
    loadWorkspaces();
  }, [loadWorkspaces]);

  const openEditUser = (userSelected: IUser) => {
    setUser(userSelected);
    setOpenDialogUserEdit(true);
  };

  const OpenNewUser = () => {
    setOpenDialogUserCreate(true);
  };

  const renderUsers = () => {
    if (loading) {
      return (
        <>
          {new Array(7).fill(true).map((_, index) => (
            <Skeleton key={`load_${index}`} height={80} />
          ))}
        </>
      );
    }

    if (!users.length) {
      return (
        <Box textAlign="center" m={1}>
          Nenhum recurso encontrado
        </Box>
      );
    }

    return (
      <InfiniteScroller loadMore={handleLoadMore} hasMore={hasMore}>
        {users.map((user, index) => (
          <User
            key={`user_${index}`}
            user={user}
            editUser={openEditUser}
            handleDeleteUser={() => setEmail(user.email)}
          />
        ))}
      </InfiniteScroller>
    );
  };

  const handleLoadMore = async page => {
    try {
      const response: IUserResponse = await getUsers(page, searchField);
      setUsers([...users, ...response.users]);
      const hasMoreToggle = !(response.lastPage || response.users.length === 0);
      setHasMore(hasMoreToggle);
    } catch (error) {
      console.error('erro ao listar usuários');
    }
  };

  const onClickSearchFieldClear = () => {
    setSearchField('');
    memoizedTakeUntil(setReload, true);
    setButtonToggle(false);
  };

  return (
    <S.Root>
      <DialogUserCreate
        formState={openDialogUserCreate}
        handleCloseModal={() => setOpenDialogUserCreate(false)}
        getWorkspaces={getWorkspaces}
        createUser={createUser}
        onSaveUser={() => setReload(true)}
      />
      {user && (
        <DialogUserEdit
          formState={openDialogUserEdit}
          workspaces={workspaces}
          user={user}
          editUser={editUser}
          handleCloseModal={() => setOpenDialogUserEdit(false)}
          reloadList={setReload}
        />
      )}
      <DeleteUser
        email={email}
        deleteUser={deleteUser}
        setReload={setReload}
        handleCloseModal={() => setEmail('')}
      />
      <S.Container>
        {users.length || loading || searchField ? (
          <>
            <Grid container justify="space-between">
              <Grid item={true} xs={6}>
                <Box display="flex" alignItems="center">
                  <Input
                    type="text"
                    text="Digite aqui para buscar um usuário ou workspace"
                    Icon={IconSearch}
                    fullWidth={true}
                    size={400}
                    value={searchField}
                    onChange={event => onChangeSearchField(event.target.value)}
                  />
                  {buttonToggle && (
                    <S.ButtonSearch onClick={onClickSearchFieldClear}>
                      Limpar
                    </S.ButtonSearch>
                  )}
                </Box>
              </Grid>
              <Button
                color="primary"
                variant="contained"
                style={{ padding: '0px 30px' }}
                onClick={OpenNewUser}
              >
                Novo Usuário
              </Button>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.CustomTypography>Nome do usuário</S.CustomTypography>
              <S.CustomTypography>Email</S.CustomTypography>
              <S.CustomTypography>Workspaces</S.CustomTypography>
            </Grid>
            {renderUsers()}
          </>
        ) : (
          <UserEmpty
            createUser={createUser}
            getWorkspaces={getWorkspaces}
            onSaveUser={() => {} /* this.props.history.push('/users' */}
          />
        )}
      </S.Container>
    </S.Root>
  );
};
export default ListUser;
