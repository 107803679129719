import * as React from 'react';
import * as S from './Styled';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { paletteColor } from 'modules/common/styles/colors';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Edit } from 'modules/common/icons/icon-edit.svg';
import ModalCustomersList from './ModalCustomersList';
import ModalEditPermission from './ModalEditPermissions';
import successIcon from '../../assets/success.svg';
import { CognitoApi } from './CognitoApi';
import Alert from '@material-ui/lab/Alert';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//Style props
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: `${paletteColor.primary.white}`,
  border: '0',
  borderRadius: '4px',
  boxShadow: 24,
  outline: 'none',
  p: '15px 15px',
  width: 650,
  minHeight: 200
};

//Modal De confirmação
function ChildModal(props) {  
  const [open, setOpen] = React.useState(false);
  const [confirmPermissions, setConfirmPermissions] = React.useState([]);
  const [confirmCustomers, setConfirmCustomers] = React.useState([]);
  const [confirmSelectedCustomers, setConfirmSelectedCustomers] = React.useState([]);
  const [confirmSelectedPermissions, setConfirmSelectedPermissions] = React.useState([]);
  const [userIdCustomers, setUserIdCustomers] = React.useState([]);
  const [userCode, setUserCode] = React.useState('');
  const [step, setStep] = React.useState(1);
  const [alertIfArraysIsEmpty, setAlertIfArraysIsEmpty] = React.useState<boolean>(false);
  
  const sendToCognito = () => {
    if(confirmSelectedPermissions.length > 0){
      CognitoApi.setAddUserToGroup(userCode, confirmSelectedPermissions)
      .then(() => setStep(2));
    }
    if(confirmPermissions.length > 0) {
      CognitoApi.setDropUserToGroup(userCode, confirmPermissions)
      .then(() => setStep(2));
    }
    if(userIdCustomers.length > 0) {
      CognitoApi.setAddUserToGroup(userCode, userIdCustomers)
      .then(() => setStep(2));
    }
    if(confirmCustomers.length > 0) {
      CognitoApi.setDropUserToGroup(userCode, confirmCustomers.map(id => id.idCustomer))
      .then(() => setStep(2));
    }
    setTimeout(() => {
      window.location.reload();
    }, 2000)
  }
  React.useEffect(() => {
    setConfirmPermissions(props.excludedPermissions);
    setConfirmCustomers(props.excludedCustomers);
    setConfirmSelectedCustomers(props.selectedUsers);
    setConfirmSelectedPermissions(props.selectedPermissions);
    setUserCode(props.userCode);
    setUserIdCustomers(props.personKey);
  }, [props.excludedPermissions, props.excludedCustomers, props.selectedUsers, props.selectedPermissions, props.userCode, props.idCustomers, props.personKey]);
  
  const handleOpen = () => {
    let confirmsemptyBool = confirmCustomers.length === 0 && confirmPermissions.length === 0; 
    let selectemptyBool = confirmSelectedPermissions.length === 0 && confirmSelectedCustomers.length === 0;
    
    if(confirmsemptyBool === true && selectemptyBool === true ) {
      setAlertIfArraysIsEmpty(true);
      setTimeout(() => {
        setAlertIfArraysIsEmpty(false);
      }, 3000);
    }else {
      setOpen(true);
      setAlertIfArraysIsEmpty(false)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <React.Fragment>
      <S.ButtonSaveChanges onClick={handleOpen}>Salvar alterações</S.ButtonSaveChanges>
      {alertIfArraysIsEmpty &&
        <div style={{position: 'absolute', top: 0, right: 0}}>
          <Alert variant="filled" severity="error">
            É obrigatória a seleção de ao menos uma permissão ou cliente.
          </Alert>
        </div> 
      }
      <Modal
        open={open}
        onClose={handleClose}
      >
        {step === 1 ?
          <Box sx={{ ...style}}>
            <S.TitleModalConfirm>
              Confirmar alterações
            </S.TitleModalConfirm>
            <Divider style={{marginTop: '10px', background: `${paletteColor.info.border}`}}/>
            <S.CustomTypography>
              Dados inseridos:
            </S.CustomTypography>
              <S.CustomListContainerModal>
                {confirmSelectedCustomers.map((customers, index) => {
                    return (
                      <ul key={index}>
                        <li>{customers}</li>
                      </ul>
                    )
                  })}
                  {confirmSelectedPermissions.map((permissions, index) => {
                    return (
                      <ul key={index}>
                        <li>{permissions}</li>
                      </ul>
                    )
                  })}
              </S.CustomListContainerModal>
            <S.CustomTypography>
              Dados excluídos:
            </S.CustomTypography>
            <S.CustomListContainerModal>
              {confirmPermissions.map((permission, index) => {
                return (
                  <ul key={index}>
                    <li>{permission}</li>
                  </ul>
                )
              })}
              {confirmCustomers.map((customers, index) => {
                return (
                  <ul key={index}>
                    <li>{customers.description}</li>
                  </ul>
                )
              })}
            </S.CustomListContainerModal>
            <S.ButtonsWrapper>
              <S.ModalButtonCancel onClick={handleClose}>Cancelar</S.ModalButtonCancel>
              <S.ButtonSaveChanges onClick={sendToCognito}>Confirmar</S.ButtonSaveChanges>
            </S.ButtonsWrapper>
          </Box>
          :
          <Box sx={style}>
          <S.FlexModal>
            <img src={successIcon} alt="success_icon" />
            <h3>As alterações foram salvas</h3>
          </S.FlexModal>
          </Box>
        }
      </Modal>
    </React.Fragment>
  );
}
// Modal
export default function ModalEditUserPermission(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [value, setValue] = React.useState(0);
  const [excludedCustomers, setExcludedCustomers] = React.useState<String[]>([]);
  const [selectedUsers, setSelectedUsers] = React.useState<String[]>([]);
  const [excludedPermissions, setExcludedPermissions] = React.useState<String[]>([]);
  const [selectedPermissions, setSelectedPermissions] = React.useState<String[]>([]);
  const [updateWorkspace, setUpdateWorkspace] = React.useState<String[]>([]);
  const [personKey, setPersonKey] = React.useState<String[]>([]);
  const handleClose = () => {
    localStorage.removeItem('customersList');
    localStorage.removeItem('workspacesForUser');
    localStorage.removeItem('excludedWorkspaces');

    localStorage.removeItem('PermissionsList');
    localStorage.removeItem('PermissionsForUser');
    localStorage.removeItem('excludedPermissions');
    setOpen(false);
  };

  const handleChange = (event, newValue: number) => {
    setValue(newValue);
  };

  const pull_dataModalCostumersList = (removedCustomers, customersSelected, idCustomersSelected, personKey) => {
    setExcludedCustomers(removedCustomers);
    setSelectedUsers(customersSelected);
    setUpdateWorkspace(idCustomersSelected);
    setPersonKey(personKey);
  }

  const pull_dataModalEditPermissions = (removedPermissions, permissionsSelected) => {
    setExcludedPermissions(removedPermissions);
    setSelectedPermissions(permissionsSelected);
  }

  React.useEffect(() => {
    if(open === false) {
      localStorage.removeItem('customersList');
      localStorage.removeItem('workspacesForUser');
      localStorage.removeItem('excludedWorkspaces');
  
      localStorage.removeItem('PermissionsList');
      localStorage.removeItem('PermissionsForUser');
      localStorage.removeItem('excludedPermissions');
    }
  }, [open]);

  return (
    <div>
        <IconButton
            aria-label="edit"
            size="small"
            onClick={handleOpen}
        >
          <Edit />
        </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <S.CustomTitle>
              {props.email}
            </S.CustomTitle>
            <Divider style={{marginTop: '10px', background: `${paletteColor.info.border}`}}/>
            <Box>
              <S.CustomTabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
                <Tab label="Clientes" {...a11yProps(0)} style={{minWidth:"10%", marginRight: '75px'}} />
                <Tab label="Permissões" {...a11yProps(1)} style={{minWidth:"10%"}} />
              </S.CustomTabs>
              <Divider style={{background: `${paletteColor.info.border}`}}/>
            </Box>
            <TabPanel value={value} index={0}>
              <ModalCustomersList email={props.email} func={pull_dataModalCostumersList}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ModalEditPermission email={props.email}  func={pull_dataModalEditPermissions}/>
            </TabPanel>
            <S.ButtonsWrapper>
              <S.ModalButtonCancel onClick={handleClose}>Cancelar</S.ModalButtonCancel>
              <ChildModal 
                excludedCustomers={excludedCustomers} 
                excludedPermissions={excludedPermissions} 
                selectedUsers={selectedUsers}
                selectedPermissions={selectedPermissions}
                userEmail={props.email}
                userCode={props.code}
                idCustomers={updateWorkspace}
                personKey={personKey}
              />
          </S.ButtonsWrapper>
          </Box>
        </>
      </Modal>
    </div>
  );
}
