import styled from 'styled-components';
import { DatePicker } from '@cmp/ui';
import { Autocomplete } from '@material-ui/lab';
import { paletteColor } from 'modules/common/styles/colors';

export const DialogTitle = styled.div`
  border-bottom: 1px solid rgb(200, 200, 200);
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  min-width: 600px;
  font-size: 16px;
  display: flex;
  padding: 15px;
`;

export const DialogTabs = styled.div`
  border-bottom: 1px solid rgb(200, 200, 200);
  justify-content: flex-start;
  align-items: center;
  display: flex;
  height: 48px;
`;

export const DialogTabsItem = styled.div`
  justify-content: center;
  padding-right: 30px;
  padding-left: 30px;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  height: 48px;
`;

export const CenterBox = styled.span``;

export const TabsItemIndicator = styled.div`
  border-bottom: 3px solid #e00000;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

export const DialogContent = styled.div`
  overflow-y: auto;
  max-height: 85%;
`;

export const DialogContentInner = styled.div`
  padding: 20px;
`;

export const DialogContentNotification = styled.div`
  padding: 20px;
`;

export const SubTitle = styled.div`
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  display: flex;

  & span {
    margin-left: 5px;
  }
`;

export const DialogContentBar = styled.div`
  border-top: 1px solid rgb(200, 200, 200);
`;

export const DialogFooter = styled.div`
  border-top: 1px solid rgb(200, 200, 200);
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 15px;
`;

export const SelectContent = styled.div`
  position: relative;

  & .MuiAutocomplete-root {
    width: auto !important;
  }

  & .error-content {
    position: absolute;
    background: ${paletteColor.error.main};
    border-radius: 0 0 4px 4px;
    text-align: center;
    top: 95%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  & .error-text {
    font-size: 12px;
    color: ${paletteColor.primary.white};
    margin: 0;
    padding: 0;
  }
`;

export const InsertGroupInput = styled.div`
  text-align: right;
  font-weight: 600;
  color: #e00000;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
`;

export const AutocompleteCustom = styled(Autocomplete)`
  width: 100%;
  min-width: 200px;

  && .MuiFilledInput-root {
    background: ${paletteColor.primary.white};
    color: ${paletteColor.info.text};
    border: 1px solid ${paletteColor.info.border};
    position: relative;
    border-radius: 3px;
    font-size: 12px;
    height: 40px;
    padding: 9px 7px;

    &:before {
      content: '';
      position: absolute;
      border-right: 1px solid ${paletteColor.info.border};
      right: 36px;
      top: 6px;
      height: 24px;
      border-bottom: none;
    }
  }

  && .MuiFilledInput-root:hover {
    text-decoration: none;
  }

  & input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus-within {
    border: 1px solid #f26522;
    border-radius: 3px;
  }

  & label {
    font-size: 12px;
    color: ${paletteColor.info.soft};
    position: absolute;
    top: calc(50% - 27px);
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
    content: ' ';
    position: initial;
  }

  & .MuiAutocomplete-popupIndicator:before {
    content: ' ';
    position: absolute;
    box-sizing: border-box;
    height: 8px;
    width: 8px;
    border-style: solid;
    border-color: ${paletteColor.info.soft};
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }

  & .MuiIconButton-root:hover {
    background: none;
  }

  & .MuiAutocomplete-popupIndicator {
    padding: 12px;
    margin: 0;
    color: transparent;
  }

  & .MuiIconButton-label {
    display: none;
  }

  & .MuiInputLabel-asterisk {
    color: red;
  }
`;
export const Intro = styled.div`
  margin-top: 0px;
  padding-bottom: 10px;
`;

export const Icons = styled.div`
  padding: 2px;
  display: grid;
  justify-self: center;
  margin-left: -18px;
  margin-top: -5px;
`;

export const CustomBoxField = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;

  & .BoxInner {
    width: 80%;
  }

  & span {
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
  }

  & .BoxButtonCreate {
    color: #e00000;
  }
`;

export const notificationtext = styled.div`
  margin-bottom: 10px;
`;

export const DatePickerCustom = styled(DatePicker)`
  && .MuiFormControl-root {
    width: 100% !important;
  }
`;
