import styled from 'styled-components';
import { colors , Input } from '@cmp/ui';
import loginBackground from '../../common/images/bcg_login.png';
import Button from '@material-ui/core/Button';
import { paletteColor } from 'modules/common/styles/colors';
import Grid from '@material-ui/core/Grid';


export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 125px;
  height: 125px;
  border-radius: 100px;
  background: #ffffff;
  justify-content: center;
  box-shadow: 0px 4.784688949584961px 10.76555061340332px 0px #00000026;
  & .ImgBrand {
    width: 60px;
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${colors.dark};
  padding-top: 25px;
`;

export const BcgLogin = styled.div`
  background-image: url(${loginBackground});
  background-size: contain;
  background-position-x: right;
  height: 100vh;
  background-repeat: no-repeat;
  background-color: #c6dbe9;
  @media (max-width: 1000px) {
    display: none
  }
`;

export const FormContent = styled.div`
  display: block;
`;

export const Checkbox = styled.div`
  & .MuiCheckbox-root-108 {
    color: ${colors.orange};
  }

  & .MuiCheckbox-checked-109 {
    color: ${colors.orange};
  }
`;

export const SpanLink = styled.span`
  cursor: pointer;
  font-size: 15px;
  line-height: 24.61px;
  font-weight: 400;
  color: #E00000;
  letter: 5%;
`;

export const InputLogin = styled(Input)`
  padding: 8px 8px;
  width: 500px;
  @media (max-width: 400px){
    heigh: 35px;
    width: 500px;
  }
`;

export const BtnCancel = styled(Button)`
  padding: 8px 16px !important;
  margin-right: 10px !important;
  cursor: pointer;
  & span {
    font-size: 16px;
  }
`;

export const BtnSubmit = styled(Button)`
  padding: 8px 16px !important;
  cursor: pointer;
  & span {
    font-size: 16px;
  }
`;

export const BtnGoogle = styled(Button)`
  && {
    background: #4285f4;
    color: ${paletteColor.primary.white};
    font-weight: 500;
    border-radius: 3px;
    position: relative;
    width: 418px;
    padding: 8px !important;
    &:hover {
      background-color: #4285f4;
    }
    & .txt-btn {
      align-items: center;
      font-size: 16px;
      margin: 0 auto;
      text-transform: none;
    }
    & .social-logo {
      background: ${paletteColor.primary.white};
      border-radius: 3px;
      position: absolute;
      left: 2px;
      top: 2px;
      padding: 9px;
      text-align: center;
      vertical-align: middle;
      width: 40px;
      height: 40px;
    }
  }
`;



/*
 * @Style DialogInner
 * Implementar CSS Interno do Dialog
 */
export const ErrorLogin = styled.div`
  background-color: #E00000;
  border-radius: 6px 6px 0px 0px;
  padding: 3px 10px;
  font-size: 12px;
  color: #FFF;
`;
export const DialogInner = styled.div`
  padding: 25px 25px 0px 25px;
`;
export const DialogTitle = styled.div`
  border-bottom: 1px solid ${paletteColor.info.border};
  padding: 5px 0px 20px 0px;
  position: relative;
  font-weight: 500;
  text-align: left;
  font-size: 20px;
  width: 400px;
`;
export const DialogTitleCenter = styled.div`
  padding: 5px 0px 5px 0px;
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 20px;
  width: 400px;
`;
export const SubTitle = styled.div`
  color: rgb(180,180,180);
  font-weight: normal;
  margin-top: 15px;
  font-size: 13px;
  line-height: 16px;
`;
export const DialogClose = styled.div`
  color: rgb(140,140,140);
  justify-content: center;
  align-items: center;
  font-weight: normal;
  position: absolute;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  height: 14px;
  right: 0px;
  width: 14px;
  top: 8px;
`;
export const DialogMain = styled.div`
  padding: 20px 0px 0px 0px;
`;
export const DialogSuccess = styled.div`
  background-color: rgb(235,235,235);
  justify-content: center;
  align-items: center;
  display: flex;
`
export const DialogSuccessMsg = styled.div`
  color: rgb(0,200,0);
  font-size: 16px;
  padding: 15px;
`
export const DialogFooterRight = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 25px;
  & button {
    margin-left: 10px;
  }
`;
export const DialogFooterCenter = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 25px;
`;
export const DialogBottomAction = styled(Button)`
  && {
    padding: 10px 30px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }
`;
export const DialogBottomCancel = styled(Button)`
  && {
    border: 2px solid #E00000;
    padding: 8px 30px;
    font-weight: 500;
    font-size: 16px;
    color: #E00000;
  }
`;
export const DialogBoxHelper = styled.div`
  left: calc(100vw / 2);
  position: relative;
  margin-left: 200px;
  margin-top: 140px;
  position: fixed;
  height: 210px;
  width: 460px;
`;
export const HelperText = styled.div`
  position: absolute;
  line-height: 22px;
  bottom: 30px;
  right: 40px;
  left: 100px;
  top: 45px;
`;





export const DialogContext = styled.div`
  && {
    border-top: 1px solid ${paletteColor.info.border};
    padding: 25px 25px 25px 25px;
    position: relative;
    min-width: 418px;
    display: block;
    overflow: hidden;
  }
`;

export const InputLabel = styled.div`
  margin-left: 10px;
`;

export const DialogFooter = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 0px 16px 16px 16px;
`;

export const PasswordValidation = styled.div`
  left: calc(100vw / 2);
  margin-left: 210px;
  margin-top: 60px;
  position: fixed;
  height: 210px;
  width: 460px;
`;

export const PasswordValidationBox = styled.div`
  border-radius: 5px;
  position: absolute;
  height: 140px;
  width: 300px;
  bottom: 0px;
  left: 90px;
  top: 40px;
`;

export const PasswordValidationText = styled.div`
  color: #72CB46;
`;

export const CustomGrid = styled(Grid)`
  &&  {

    @media (max-width: 1000px){
    & .MuiGrid-container {
      display: flex;
      flex-direction: collum;
      align-itens: center;
      justify-content: center;
    }
    & .MuiGrid-grid-xs-7{
      flex-grow: none;
      max-width: 0;
      flex-basics: 0;
    }
   }
    @media (max-width: 420px){
    &
    }
  }
  }
`;
