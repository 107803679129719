import styled from 'styled-components';

export const BackgroundContent = styled.div`
  background-color: #f7f8fc;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;

  justify-content: center;
  align-items: flex-start;
  display: flex;

  font-size: 20px;
  line-height: 30px;
  text-align: center;
  padding: 30px;
`;

export const BoxContent = styled.div`
  width: 80%;
  min-width: 360px;
  max-width: 780px;
  margin: 0 auto;
  background-color: #ffffff;
`;

export const BoxContentHeader = styled.div`
  justify-content: center;
  text-align: center;

  color: #252733;
  padding: 35px 35px 20px 35px;
  border-bottom: 8px solid #e00000;
`;

export const BoxContentMainText = styled.p`
  margin: 20px auto;
  width: 80%;
`;

export const BoxContentSuccessText = styled.p`
  color: green;
`;

export const BoxContentErrorText = styled.p`
  color: red;
`;

export const BoxContentButton = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 18px;
  background: #34454e;
  color: #ffffff;
  border-radius: 3px;
  width: 30%;
  margin: 30px auto;
`;
