import AWS from 'aws-sdk';
import config from '../../../utils/config';

// Configuração de Acesso ao Cognito
const cognito = new AWS.CognitoIdentityServiceProvider({
  credentials: {
    accessKeyId: 'AKIAI4CSFXSQNFWPJEPQ',
    secretAccessKey: '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',
  },
  region: 'us-east-1',
});

// Users, Permissions, Clients
var UserPoolId = config.cognitoPool;

// getClientsFromUser
const getClientsFromUser = async (Username: string) => {
  return await cognito
    .adminListGroupsForUser({
      UserPoolId: UserPoolId,
      Username: Username,
    })
    .promise()
    .then(Data => Data.Groups.filter(group => /^wks:/gi.test(group.GroupName)));
};

// getPermissionsFromUser
const getPermissionsFromUser = async Username => {
  return await cognito
    .adminListGroupsForUser({
      UserPoolId: UserPoolId,
      Username: Username,
    })
    .promise()
    .then(Data =>
      Data.Groups.filter(group => /^[^wks:]/gi.test(group.GroupName))
    );
};

// GetListUsers
const getListUsers = async () => {
  return await cognito
    .listUsers({
      UserPoolId: UserPoolId,
    })
    .promise()
    .then(Data =>
      Data.Users.map(user => {
        return {
          Username: user.Username,
          UserCreateDate: user.UserCreateDate,
          UserLastModifiedDate: user.UserLastModifiedDate,
          UserStatus: user.UserStatus,
          UserSub: user.Attributes.find(args => args.Name === 'sub').Value,
          UserEmail: user.Attributes.find(args => args.Name === 'email').Value,
          UserEmailVerified: user.Attributes.some(
            args => args.Name === 'email_verified'
          ),
          Enabled: user.Enabled,
          UserPermission: [],
          UserClients: [],
        };
      })
    );
};

const getListUsersInGroup = async (GroupName: string) => {
  return await cognito
    .listUsersInGroup({
      GroupName: GroupName,
      UserPoolId: UserPoolId,
    })
    .promise()
    .then(Data =>
      Data.Users.map(user => {
        return {
          Username: user.Username,
          UserCreateDate: user.UserCreateDate,
          UserLastModifiedDate: user.UserLastModifiedDate,
          UserStatus: user.UserStatus,
          UserSub: user.Attributes.find(args => args.Name === 'sub').Value,
          UserEmail: user.Attributes.find(args => args.Name === 'email').Value,
          UserEmailVerified: user.Attributes.some(
            args => args.Name === 'email_verified'
          ),
          Enabled: user.Enabled,
          UserPermission: [],
          UserClients: [],
        };
      })
    );
};

// getAllPermission
const getAllPermission = async () => {
  return await cognito
    .listGroups({
      UserPoolId: UserPoolId,
    })
    .promise()
    .then(Data =>
      Data.Groups.filter(group => /^[^wks:]/gi.test(group.GroupName))
    );
};

// getAllClients
const getAllClientsFromToken = async (NextToken: string = null) => {
  return await cognito
    .listGroups({
      UserPoolId: UserPoolId,
      NextToken: NextToken,
      Limit: 60,
    })
    .promise();
};

// getAllClients
const getAllClients = async () => {
  var hasNextToken = false;
  var strNextToken = null;
  var limitClients = 60;
  var allClientsTmp;
  var allClients = [];

  // Get AllClients
  allClientsTmp = await getAllClientsFromToken();

  // Check is NextToken
  hasNextToken = allClientsTmp.Groups.length === limitClients;
  strNextToken = allClientsTmp.NextToken;

  // Pouplar allClients
  allClientsTmp.Groups.map(client => allClients.push(client));

  // Start Loop in getAllClientsFromToken
  while (hasNextToken) {
    var allClientsNext = await getAllClientsFromToken(strNextToken);

    // Check is NextToken
    hasNextToken = allClientsNext.Groups.length === limitClients;
    strNextToken = allClientsNext.NextToken;

    // Pouplar allClients
    allClientsNext.Groups.map(client => allClients.push(client));
  }

  // remover permissions
  return allClients.filter(client => /^wks:/gi.test(client.GroupName));
};

// setAddUserToGroup
const setAddUserToGroup = async (Username: string, GroupNameList: string[]) => {
  GroupNameList.map(async (GroupName: string) => {
    return await cognito
      .adminAddUserToGroup({
        GroupName: GroupName,
        Username: Username,
        UserPoolId: UserPoolId,
      })
      .promise();
  });
};

// setDropUserToGroup
const setDropUserToGroup = async (
  Username: string,
  GroupNameList: string[]
) => {
  GroupNameList.map(async (GroupName: string) => {
    return await cognito
      .adminRemoveUserFromGroup({
        GroupName: GroupName,
        Username: Username,
        UserPoolId: UserPoolId,
      })
      .promise();
  });
};

// CognitoApi
export const CognitoApi = {
  // Gets
  getUsers: async () => await getListUsers(),
  getUsersInGroup: async (GroupName: string) =>
    await getListUsersInGroup(GroupName),
  getWorkspaces: async () => await getAllClients(),
  getPermissions: async () => await getAllPermission(),
  getClientsFromUser: async (userEmal: string) =>
    await getClientsFromUser(userEmal),
  getPermissionsFromUser: async (userEmal: string) =>
    await getPermissionsFromUser(userEmal),

  // Sets
  setAddUserToGroup: async (userName: string, groupNameList: string[]) =>
    await setAddUserToGroup(userName, groupNameList),
  setDropUserToGroup: async (userName: string, groupNameList: string[]) =>
    await setDropUserToGroup(userName, groupNameList),
};
