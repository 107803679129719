import * as React from 'react';
import * as S from '../components/Styled';
import PermissionsRegistration from '../components/PermissionsRegistration';
import RegisteredUsers from '../components/TabsRegisteredUsers'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import { CognitoApi } from '../components/CognitoApi';
import { CircularProgress } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [userPermission, setUserPermission] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(true);
  (async () => {
    const getUsers = await CognitoApi.getUsers()
      .then(response => response.filter(element => element.UserEmail.includes(localStorage.getItem("cmp_user_email"))))
    await CognitoApi.getPermissionsFromUser(getUsers[0].Username)
      .then(response => {
          const permissions = response.map(permissions => permissions.GroupName)
          if(permissions.includes("admin:permission_admin")) {
            setUserPermission(true);
          }
          setReload(false);
        }
      )
  })()

  const handleChange = (event, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      {reload ?
        <S.LoadingContainer>
          <CircularProgress
          size={50}
          style={{ marginRight: 8 }}
          />
        </S.LoadingContainer>
        :
        <>
          {userPermission === false ?
            <S.LoadingContainer>
              <S.AlertText>O usuário não tem acesso a este módulo. Verifique com o administrador.</S.AlertText>
            </S.LoadingContainer>
            :
            <Box sx={{ width: '100%' }}>
              <Box>
                <S.CustomTabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
                    <Tab label="Cadastro de permissões" {...a11yProps(0)} style={{minWidth:"10%", marginRight: '75px'}} />
                    <Tab label="Usuários cadastrados" {...a11yProps(1)} style={{minWidth:"10%"}} />
                </S.CustomTabs>
              </Box>
              <TabPanel value={value} index={0}>
                <PermissionsRegistration />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <RegisteredUsers />
              </TabPanel>
            </Box>
          }
        </>
      }
    </>
  );
}
