import React from 'react';
import * as S from './Styled';
import { Input } from '@cmp/ui';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';
import { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ModalEditUserPermission from './ModalEditUserPermissions';
import { CognitoApi } from './CognitoApi';

const TabsRegisteredUsers = () => {
  const [cogUsers, setCogUsers] = React.useState<any[]>([]);
  const [searchField, setSearchField] = React.useState('');
  const cogUsersFiltered = cogUsers.filter((usuario) => usuario.email.toLowerCase().includes(searchField.toLowerCase()));

  useEffect(() => {
    CognitoApi.getUsers()
      .then(response => {
        const cogUsersList: any[] = [];
        response.map(users => cogUsersList.push({email: users.UserEmail, code: users.Username}));
        setCogUsers(cogUsersList);
      })
  }, []);

  function compareString(a, b) {
    if(a.email < b.email){
      return -1;
    }
    if(a.email > b.email){
      return 1;
    }
    return 0;
  }

  return (
    <S.UserContainer>
      <div className='InputStyle'>
        <Input
          type="text"
          text="Digite aqui para buscar um usuário"
          fullWidth={true}
          Icon={IconSearch}
          rootProps={{ style: { border: 0 } }}
          value={searchField}
          onChange={e => setSearchField(e.target.value)}
        >
        </Input>
      </div>
      <Box>
        <Grid container>
          <Grid className="Usuario" item xs={6}>
            <S.CustomTypography>Usuário</S.CustomTypography>
          </Grid>
          <Grid item xs={6}>
            <S.CustomTypography>E-mail</S.CustomTypography>
          </Grid>
        </Grid>
      </Box>
      {cogUsersFiltered.sort(compareString).map((users: any, index: number) => (
        <Box marginTop={1}  key={index}>
          <S.CustomGrid container>
            <Grid item xs={6}>
              <S.ListContent>
                <Typography>
                  {
                    users.email.replace(".", " ").split("@br.clara.net").toString().replace(',',' ').replace(/(?:^|\s)\S/g, function(a) {
                      return a.toUpperCase();
                    })
                  }
                </Typography>
                <S.ResourceTools className="tools">
                  <ModalEditUserPermission username=
                    {
                      users.email.replace(".", " ").split("@br.clara.net").toString().replace(',',' ').replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                      })
                    }
                    email={users.email}
                    code={users.code}
                  />
                </S.ResourceTools>
              </S.ListContent>
            </Grid>
            <Grid item xs={6}>{users.email}</Grid>
          </S.CustomGrid>
        </Box>
      ))}
    </S.UserContainer>
  )
}

export default TabsRegisteredUsers;
