import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as S from './Styled';
import ImgBrand from 'modules/common/brand/logo-brand.png';
import { ReactComponent as IconGoogle } from 'modules/common/images/btn_google.svg';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { login } from 'modules/auth';
import { Input } from '@cmp/ui';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import config from '../../../utils/config';
import { IUser, IError } from 'modules/auth/types';
import { ReactComponent as IconMail } from 'modules/common/icons/mail.svg';
import { ReactComponent as IconLock } from 'modules/common/icons/lock.svg';
import LoginForget from './LoginForget';
import LoginReset from './LoginReset';
import LoginNewPassword from './LoginNewPassword';
import LoginSuccessReset from './LoginSuccessReset';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

interface IProps extends RouteComponentProps {
  setUser: (user: IUser) => void;
  setError: (code: number, message: string) => void;
  errors: IError[];
}

const handleGoogleAuth = () => {
  window.location.href = config.endpoints.AUTH_URI.replace(
    /%REDIRECT_URI/g,
    `${config.baseURI}login`
  )
    .replace(/%CLIENT_ID/g, config.clientId)
    .replace(/%URL/g, config.cognitoUrl);
};

const Login = ({ location, history, setUser }: IProps) => {
  const [redirectToReferrer, setRedirectToReferrer] = React.useState<boolean>(
    false
  );
  const [email, setEmail] = React.useState<string>('');
  const [senha, setSenha] = React.useState<string>('');
  const [lembrar, setLembrar] = React.useState<boolean>(false);
  const [errorLogin, setErrorLogin] = React.useState<string>('');
  const [openDialogForget, setOpenDialogForget] = React.useState<boolean>(
    false
  );
  const [openDialogReset, setOpenDialogReset] = React.useState<boolean>(false);
  const [openSuccessReset, setOpenSuccessReset] = React.useState<boolean>(
    false
  );
  const [openDialogNewPassword, setOpenDialogNewPassword] = React.useState<
    boolean
  >(false);

  const [cognitoUser, setCognitoUser] = React.useState(null);

  const hasGoogleSession = false;

  /*
   * @handleAmplyConfig
   **/
  const handleAmplyConfig = {
    aws_cognito_region: config.cognitoRegion,
    aws_user_pools_id: config.cognitoPool,
    aws_user_pools_web_client_id: config.clientId,
  };

  const {
    access_token = '',
    id_token = '',
    expires_in = 3600,
  }: {
    access_token?: string;
    id_token?: string;
    expires_in?: number;
  } = queryString.parse(location.hash);

  login(
    access_token,
    id_token,
    expires_in,
    (err: boolean, email: string): void => {
      if (!err) {
        setRedirectToReferrer(true);
        setUser({ email });
      }
    }
  );

  const handleCloseDialogForget = () => {
    setOpenDialogForget(false);
  };

  const handleOpenDialogReset = () => {
    setOpenDialogForget(false);
    setOpenDialogReset(true);
  };

  const handleCloseDialogReset = () => {
    setOpenDialogReset(false);
  };

  const handleCloseDialogNewPassword = () => {
    setOpenDialogNewPassword(false);
  };

  const handlePostDialogReset = (codigo: string, senha: string) => {
    Amplify.configure(handleAmplyConfig);
    Auth.forgotPasswordSubmit(email, codigo, senha).then(forgotPassword => {
      setOpenSuccessReset(true);
      setOpenDialogReset(false);
    });
  };

  /*
   * @cognitoNewPassword
   *
   * @params: <string> @senha
   **/
  const handlePostDialogNewPassword = (senhaNew: string) => {
    Amplify.configure(handleAmplyConfig);

    Auth.completeNewPassword(cognitoUser, senhaNew, {}).then(
      completeNewPassword => {
        if (
          completeNewPassword.challengeParam.userAttributes.email_verified ===
          'true'
        ) {
          if (
            completeNewPassword.challengeParam.userAttributes.email === email
          ) {
            setOpenDialogNewPassword(false);
            setOpenSuccessReset(true);
          }
        }
      }
    );
  };

  /*
   * @cognitoSignIn
   *
   * @params: <string> @email
   * @params: <string> @senha
   **/
  const handleCognitoSignIn = (email: string, senha: string) => {
    Amplify.configure(handleAmplyConfig);
    Auth.signIn(email, senha)
      .then(cognitoUser => {
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoUser(cognitoUser);
          setOpenDialogNewPassword(true);
        } else {
          Auth.currentSession().then((userSession: CognitoUserSession) => {
            login(
              userSession.getAccessToken().getJwtToken(),
              userSession.getIdToken().getJwtToken(),
              userSession.getAccessToken().getExpiration(),
              (err: boolean, email: string): void => {
                if (!err) {
                  setRedirectToReferrer(true);
                  setUser({ email });
                }
              }
            );
          });
        }
      })
      .catch(() => {
        setErrorLogin('E-mail ou senha incorretos. Tente novamente.');
      });
  };

  /*
   * @cognitoSignIn
   *
   * @params: <string> @email
   * @params: <string> @senha
   **/
  const handleCognitoForgotPassword = (email: string) => {
    Amplify.configure(handleAmplyConfig);
    Auth.forgotPassword(email)
      .then(fotgotPassword => {
        if (fotgotPassword.CodeDeliveryDetails.AttributeName === 'email') {
          handleOpenDialogReset();
        }
      })
      .catch(fotgotPasswordError => {});
  };

  const handleCloseDialogSuccessReset = () => {
    setOpenSuccessReset(false);
  };

  if (redirectToReferrer) {
    history.push('/reports');
  }

  return (
    <>
      <S.CustomGrid>
      <Grid container>
        <Grid item xs={5}>
          <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <S.LogoContainer>
              <img src={ImgBrand} alt="logo" />
            </S.LogoContainer>
            <Box marginBottom={3}>
              <S.Title>Bem-vindo!</S.Title>
            </Box>
            {errorLogin !== '' && (
              <Box marginBottom={0}>
                <S.ErrorLogin>{errorLogin}</S.ErrorLogin>
              </Box>
            )}
            <Box marginBottom={1}>
              <S.InputLogin
                type="email"
                text="Email"
                Icon={IconMail}
                value={email}
                size={350}
                onChange={(event: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Box marginBottom={0}>
              <Input
                type="password"
                text="Senha"
                Icon={IconLock}
                value={senha}
                size={350}
                onChange={(event: {
                  target: { value: React.SetStateAction<string> };
                }) => {
                  setSenha(event.target.value);
                }}
              />
            </Box>
            <Box
              marginBottom={2}
              paddingRight={0.25}
              paddingLeft={0.25}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width={350}
            >
              <span>
                <FormControlLabel
                  control={
                    <Checkbox
                      color={'primary'}
                      checked={lembrar}
                      onChange={event => {
                        setLembrar(!!event.target.checked);
                      }}
                    />
                  }
                  label="Lembrar-me"
                />
              </span>
              <S.SpanLink onClick={() => setOpenDialogForget(true)}>
                Esqueci minha senha
              </S.SpanLink>
            </Box>
            <Box marginBottom={2}>
              <S.BtnSubmit
                variant="contained"
                color="primary"
                style={{ width: 350 }}
                onClick={() => {
                  handleCognitoSignIn(email, senha);
                }}
              >
                Entrar
              </S.BtnSubmit>
            </Box>
            {hasGoogleSession && (
              <S.BtnGoogle
                className="btn google-button socialButton-customizable"
                name="googleSignUpButton"
                onClick={handleGoogleAuth}
              >
                <IconGoogle className="social-logo" />
                <span className="txt-btn">Logar com o Google</span>
              </S.BtnGoogle>
            )}
          </Box>
        </Grid>
        <Grid item xs={7}>
          <S.BcgLogin />
        </Grid>
      </Grid>
      </S.CustomGrid>
      {openDialogNewPassword && (
        <LoginNewPassword
          handleCloseDialogNewPassword={() => handleCloseDialogNewPassword()}
          handlePostDialogNewPassword={(senha: string) => {
            handlePostDialogNewPassword(senha);
          }}
          email={email}
        />
      )}
      {openDialogForget && (
        <LoginForget
          handleCloseDialogForget={() => handleCloseDialogForget()}
          handleOpenDialogReset={(emailReset: string) => {
            setEmail(emailReset);
            handleCognitoForgotPassword(emailReset);
            handleOpenDialogReset();
          }}
        />
      )}
      {openDialogReset && (
        <LoginReset
          handleCloseDialogReset={() => handleCloseDialogReset()}
          handlePostDialogReset={(codigo: string, senha: string) => {
            handlePostDialogReset(codigo, senha);
          }}
          email={email}
        />
      )}
      {openSuccessReset && (
        <LoginSuccessReset
          handleCloseDialogSuccessReset={handleCloseDialogSuccessReset}
        />
      )}
    </>
  );
};

export default withRouter(Login);
