import * as React from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import LoginInput from './LoginInput';
import  { ReactComponent as IconRetangulo } from './../../../modules/common/icons/icon-retangulo.svg';
import * as S from './Styled';

interface IProps {
  handleCloseDialogNewPassword: () => void;
  handlePostDialogNewPassword: (
    senha:string
  ) => void;
  email: string;
}

const LoginNewPassword = ({
  handleCloseDialogNewPassword,
  handlePostDialogNewPassword,
  email
}: IProps) => {
  const [senha, setSenha] = React.useState<string>("");
  const [senhaNew, setSenhaNew] = React.useState<string>("");
  const [caracterCount, setCaracterCount] = React.useState<boolean|null>(null);
  const [caracterUpper, setCaracterUpper] = React.useState<boolean|null>(null);
  const [caracterLower, setCaracterLower] = React.useState<boolean|null>(null);
  const [caracterNumber, setCaracterNumber] = React.useState<boolean|null>(null);
  const [caracterEspecial, setCaracterEspecial] = React.useState<boolean|null>(null);
  const [senhaMatch, setSenhaMatch] = React.useState<boolean|null>(null);
  const [senhaError, setSenhaError] = React.useState<boolean>(false);
  const [senhaNewError, setSenhaNewError] = React.useState<boolean>(false);

  /*
   * Applicar handleColorValidation
   **/
  const handleColorValidation = ( validation:Boolean|null ) => {
    if( validation === null ){
      return `#E00000`;
    } else
    if( validation === false ){
      return `#000000`;
    } else
    if( validation === true ){
      return `#72CB46`;
    }
  }

  /*
   * Applicar handleValidarSenha
   **/
  const handleValidarSenha = ( value ) => {
    if(value !== ""){
      setCaracterCount(value.length >= 8);
      setCaracterUpper(/[A-Z]/.test(value));
      setCaracterLower(/[a-z]/.test(value));
      setCaracterNumber(/[0-9]/.test(value));
      setCaracterEspecial(/[@|$|%|!|*]/.test(value));

      setSenhaError(false);
      setSenhaNewError(false);
    } else {
      setCaracterCount(null);
      setCaracterUpper(null);
      setCaracterLower(null);
      setCaracterNumber(null);
      setCaracterEspecial(null);
      setSenhaMatch(null);
    }
  }

  /*
   * Applicar handleValidarSenha
   **/
  const handleEmailCriptor = ( email:string ) => {
    const emailPart1 = email.split("@")[0];
    const emailPart2 = email.split("@")[1];
    const emailPart3 = email.split("@")[1].split(".");
    return `${emailPart1[0]}***@${emailPart2[0]}***${emailPart3[emailPart3.length-1]}`;
  }

  return (
    <Dialog maxWidth="lg" open={true}>
      <S.DialogInner>
        <S.DialogTitleCenter>
          Redefinir Senha de Acesso<br/>
          {handleEmailCriptor(email)}
          <S.SubTitle>
            Por favor é necessário alterar sua senha temporária.
          </S.SubTitle>
          <S.DialogClose onClick={() => handleCloseDialogNewPassword()}>X</S.DialogClose>
        </S.DialogTitleCenter>
        <S.DialogMain>
          <Box marginBottom={2}>
            <LoginInput
              value={senha}
              error={senhaError}
              helper="Obrigatório informar a senha"
              type="password"
              text="Digite sua nova senha"
              onBlur={(event) => setSenhaError(event.target.value === "")}
              onChange={(event) => {
                handleValidarSenha(event.target.value);
                setSenha(event.target.value);
              }}
            />
          </Box>
          <Box marginBottom={0}>
            <LoginInput
              value={senhaNew}
              error={senhaNewError}
              helper="Obrigatório informar a nova senha"
              type="password"
              text="Confirme sua nova senha"
              onBlur={(event) => setSenhaNewError(event.target.value === "")}
              onChange={(event) => {
                setSenhaMatch(senha === event.target.value);
                setSenhaNew(event.target.value);
              }}
            />
          </Box>
        </S.DialogMain>
      </S.DialogInner>
      <S.DialogFooterCenter>
        <S.DialogBottomAction
          variant="contained"
          color="primary"
          onClick={() => handlePostDialogNewPassword(
            senha
          )}
        >
          Redefinir senha
        </S.DialogBottomAction>
      </S.DialogFooterCenter>
      <S.DialogBoxHelper>
        <IconRetangulo />
        <S.HelperText>
          <span style={{color: handleColorValidation(caracterCount)}}>✓ Deve ter ao menos 8 caracteres.</span><br/>
          <span style={{color: handleColorValidation(caracterUpper)}}>✓ Deve conter ao menos uma letra maiuscula.</span><br/>
          <span style={{color: handleColorValidation(caracterLower)}}>✓ Deve conter ao menos uma letra minuscula.</span><br/>
          <span style={{color: handleColorValidation(caracterNumber)}}>✓ Deve conter ao menos um número.</span><br/>
          <span style={{color: handleColorValidation(caracterEspecial)}}>✓ Deve conter ao menos um caractere especial.</span><br/>
          <span style={{color: handleColorValidation(senhaMatch)}}>✓ A senha não confere.</span>
        </S.HelperText>
      </S.DialogBoxHelper>
    </Dialog>
  );
};

export default LoginNewPassword;
