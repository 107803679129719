import * as React from 'react';

const withUser = (Component: any) => {
  const email: string = localStorage.getItem('cmp_user_email') || '';
  const Container = (props: any) => {
    const updatedProps = {
      ...props,
      currentUser: {
        email,
      }
    }

    return <Component {...updatedProps} />
  }
  return Container;
}

export default withUser;
