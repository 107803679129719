import * as React from 'react';
import { AdminContainer } from 'modules/admin/containers';
import { Route, Switch } from 'react-router-dom';
import { StatusContainer } from 'modules/status/containers';
import { ProtectedRoute } from 'modules/auth/hoc';

const routes = () => (
  <Switch>
  <ProtectedRoute
    path="/admin"
    exact={true}
    component={AdminContainer}
  />
  <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;