import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginContainer } from 'modules/login/containers';
import { StatusContainer } from 'modules/status/containers';

const routes = () => (
  <Switch>
    <Route path="/login" exact={true} component={LoginContainer} />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
