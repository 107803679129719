import config from 'utils/config';

interface IValidationError {
  field: string;
  message: string;
}

type SetState = (
  (error: IValidationError) => void
);

export const isEmailValid = (
  currentEmail: string,
  setError: SetState
): boolean => {
  const { validations } = config;

  if (!currentEmail) {
    setError({ field: 'email', message: 'Digite um e-mail' });
    return false;
  } else if (!validations.emailRegex.test(currentEmail)) {
    setError({ field: 'email', message: 'Digite um e-mail válido' });
    return false;
  }
  return true;
};

export const isUsernameValid = (
  username: string,
  setError: SetState
): boolean => {
  if (!username) {
    setError({ field: 'username', message: 'Digite um nome de usuário' });
    return false;
  }

  return true;
};

export const isPasswordValid = (
  password: string,
  setError: SetState
): boolean => {
  if (!password) {
    setError({ field: 'password', message: 'Digite uma senha' });
    return false;
  }

  return true;
};

export const isWorkspaceValid = (
  workspaces: string[],
  setError: SetState
): boolean => {
  if (!workspaces || workspaces.length < 1) {
    setError({ field: 'workspaces', message: 'Selecione ao menos uma workspace' });
    return false;
  }

  return true;
};

export default {
  isEmailValid,
  isUsernameValid,
  isPasswordValid,
  isWorkspaceValid
}
