import styled from 'styled-components';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { paletteColor } from 'modules/common/styles/colors';

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;
`;

export const Root = styled.div`
  padding: 30px;

  & .whiteCard {
    background: ${paletteColor.primary.white};
    box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 65px;
    margin-bottom: 15px;
  }
`;

export const DialogContentCustom = styled(DialogContent)`
  && {
    border-top: 1px solid ${paletteColor.info.border};
    border-bottom: 1px solid ${paletteColor.info.border};
    overflow-y: hidden;
    padding: 0;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  && {
    & .avatar.count {
      margin-right: -12px;
      background: ${paletteColor.info.border};
      color: ${paletteColor.info.soft};
    }
    & .avatar {
      border: 2px solid ${paletteColor.primary.white};
      text-transform: uppercase;
    }
    & .avatar:not(.count) {
      margin-right: -12px;
      background: #f2994a;
      transition: transform 100ms ease;
      cursor: pointer;
    }
    & .avatar:not(.count):hover {
      transform: scale(1.2);
      z-index: 10;
    }
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;

  &:nth-child(2) {
    border-top: 1px solid ${paletteColor.info.border};
    border-bottom: 1px solid ${paletteColor.info.border};
    padding: 25px;
  }

  & input {
    background: transparent;
  }

  & .formControl {
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    width: 100%;
    max-height: 75px;
    overflow: hidden;

    &:focus-within {
      border: 1px solid #f26522;
    }
  }

  & .input-label {
    font-size: 12px;
    height: 14px;
    color: ${paletteColor.info.soft};
    line-height: 0;
  }

  && .Mui-focused {
    color: ${paletteColor.info.soft};
  }

  & .custom-select {
    width: 618px;
    background: ${paletteColor.primary.white};
    margin-right: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 3px;

    &:before {
      content: '';
      position: absolute;
      left: 95%;
      top: 16px;
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: ${paletteColor.info.soft};
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
    }

    & .MuiSelect-icon {
      display: none;
    }

    &:hover,
    :focus {
      background: ${paletteColor.primary.white};
    }
  }

  && .MuiSelect-selectMenu {
    overflow: visible;
    width: 618px;
    white-space: inherit;

    &:focus, &:hover {
      background: ${paletteColor.primary.white};
    }

    & .MuiChip-root {
      margin: 2px;
    }
  }

  & .MuiFilledInput-input {
    padding: 18px 12px 10px;
  }
  & .MuiFilledInput-root {
    background: transparent;
  }
  && .MuiFilledInput-underline:after {
    border-bottom: none;
  }
  & .MuiFilledInput-underline:hover:before {
    border-bottom: 2px solid ${paletteColor.info.soft};
  }
`;

export const ButtonSearch = styled(Button)`
  && {
    background: ${paletteColor.info.background};
    color: ${paletteColor.primary.main};
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding: 6px 8px;
    border-radius: 3px;
    width: 60px;
    height: 32px;
    margin-left: -70px;
    bottom: 2px;

    &:hover {
      background: ${paletteColor.info.fill};
    }
  }
`;

export const GroupButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 24px;
`;

export const ListContent = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;

  & .text {
    color: ${paletteColor.info.text};
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
  }

  & .avatar {
    background-color: blueviolet;
    color: white;
  }

  &:hover {
    & .tools {
      width: 65px;
      min-width: 65px;
    }
  }
`;

export const ResourceTools = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 0;
  min-width: 0;
  overflow: hidden;
  transition: all 400ms;
  margin-left: 18px;

  &::before {
    content: '';
    border-left: 1px solid ${paletteColor.info.border};
    border-radius: 0;
    padding-left: 4px;
  }
`;

export const CustomTypography = styled(Typography)`
  && {
    color: ${paletteColor.info.soft};
    font-weight: 500;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;

export const DialogActionsCustom = styled(DialogActions)`
  && {
    padding: 16px;
    border-top: 1px solid ${paletteColor.info.border};
    margin: 0;
  }
`;
