export const takeUntil = (milliseconds: number) => {
  let timeout;
  return (fn: (...params: any) => void, ...params: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...params);
    }, milliseconds);
  }
};

export const waitFn = takeUntil(1000);
