import * as React from 'react';
import { UserContainer } from 'modules/user/containers';
import { Route, Switch } from 'react-router-dom';
import { StatusContainer } from 'modules/status/containers';
import { ProtectedRoute } from 'modules/auth/hoc';

const routes = () => (
  <Switch>
  <ProtectedRoute
    path= "/users"
    exact={true}
    component={UserContainer}
  />
  <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
