import * as React from 'react';
import * as S from './Styled';
import { ReactComponent as Edit } from 'modules/common/icons/icon-edit.svg';
import { ReactComponent as Trash } from 'modules/common/icons/icon-trash.svg';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { IUser, IWorkspace } from 'modules/types';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';

interface IProps {
  user: IUser;
  editUser: (user: IUser) => void;
  handleDeleteUser: () => void;
}

const User = ({ user, editUser, handleDeleteUser }: IProps) => {
  const handleEdit = () => {
    editUser(user);
  };

  return (
    <Grid container justifyContent="space-between" className="whiteCard">
      <Grid item xs={5}>
        <S.ListContent>
          <Typography className="text" noWrap={true}>
            {user.username}
          </Typography>
          <S.ResourceTools className="tools">
            <IconButton aria-label="edit" size="small" onClick={handleEdit}>
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={handleDeleteUser}
            >
              <Trash />
            </IconButton>
          </S.ResourceTools>
        </S.ListContent>
      </Grid>
      <Grid item xs={5}>
        <S.ListContent>
          <Typography className="text alignCenter" noWrap={true}>
            {user.email}
          </Typography>
        </S.ListContent>
      </Grid>
      <Grid item xs={2}>
        <S.AvatarContainer>
          {user.workspaces.slice(0, 4).map((workspace: IWorkspace) => (
            <Tooltip
              key={`workspace_${workspace.code}`}
              title={workspace.name}
              placement="top"
            >
              <Avatar color="primary" className="avatar">
                {workspace.name.slice(0, 1)}
              </Avatar>
            </Tooltip>
          ))}
          {user.workspaces.length > 4 && (
            <Avatar className="avatar count">
              +{user.workspaces.length - 4}
            </Avatar>
          )}
        </S.AvatarContainer>
      </Grid>
    </Grid>
  );
};
export default User;
