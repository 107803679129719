import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import * as S from './Styled';
import DialogContent from '@material-ui/core/DialogContent';


interface IProps {
  email: string;
  setReload: (state: boolean) => void;
  handleCloseModal: () => void;
  deleteUser: (email: string) => Promise<any>;
}

const DialogUserDelete = ({ email, setReload, handleCloseModal, deleteUser }: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteUser = async () => {
    if (email) {
      try {
        setLoading(true);
        await deleteUser(email);
      } finally {
        setReload(true);
        setLoading(false);
        handleCloseModal();
      }
    }
  }

  return (
    <Dialog open={Boolean(email)} onClose={handleCloseModal}>
      <DialogContent>
        <Typography>Deseja deletar o usuário?</Typography>
      </DialogContent>
      <S.DialogActionsCustom>
        <Button color="primary" size="medium" onClick={handleCloseModal}>
          Não
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleDeleteUser}
          disabled={loading}
        >
          Sim
        </Button>
      </S.DialogActionsCustom>
    </Dialog>
  );
};

export default DialogUserDelete;
