import React, { useCallback, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import * as S from './Styled';
import cancelIcon from '../../assets/cancel.svg';
import { CircularProgress } from '@material-ui/core';
import Tooltip from 'modules/common/components/Tooltip';
import{ CognitoApi } from './CognitoApi';
import { makeStyles } from '@material-ui/core';

const MenuProps = {
  PaperProps: {
    style: {
      position: 'absolute',
      marginTop: '16%',
      marginLeft: -4,
      maxHeight: 200
    },
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    position: 'relative'
  }
}))

const SelectPermission = (props) => {
  const classes = useStyles();
  const [cogPermissions, setCogPermissions] = React.useState<String[]>([]);
  const [permissions, setPermissions] = React.useState<String[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [listAllGroups, setListAllGroups] = React.useState<String[]>([]);
  const [excludedPermissions, setExcludedPermissions] = React.useState<String[]>([]);

  const getAllPermissionsForUser = useCallback(async() => {
    CognitoApi.getPermissions()
    .then(response => {
      const arrayPermissionsSelect: String[]=[];
      response.map(groupName => arrayPermissionsSelect.push(groupName.GroupName));
      setListAllGroups(arrayPermissionsSelect);
    })
    if(localStorage.getItem('PermissionsForUser') === null) {
      await CognitoApi.getPermissionsFromUser(`${props.email}`)
        .then(response => {
          const arrayPermissions: String[] = [];
          response.map(users => arrayPermissions.push(users.GroupName));
          setCogPermissions(arrayPermissions);
          setLoading(false);
        })
    }else {
      setLoading(false);
    }
  }, [props]);

  useEffect(() => {
    if(loading) {
      getAllPermissionsForUser();
    }
  }, [loading, getAllPermissionsForUser]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPermissions(
      typeof value === 'string' ? value.split(',') : value,
      );
    };

    function separatorNames(selected) {
      return selected.join(', ');
    }

    const removeCustomer = (permissions) => {
      setCogPermissions(cogPermissions.filter(removedPermission => !removedPermission.includes(permissions)));
      setExcludedPermissions((removedPermission) => [...removedPermission, permissions]);
    };

    React.useEffect(() => {
      props.func(excludedPermissions, permissions);
    }, [props, excludedPermissions, permissions]);

    React.useEffect(() => {
      const dataPermissions = window.localStorage.getItem('PermissionsList');
      const dataViewPermissions = window.localStorage.getItem('PermissionsForUser');
      const dataExcludedPermissions = window.localStorage.getItem('excludedPermissions');
      if(dataPermissions) {
        setPermissions(JSON.parse(dataPermissions));
      }
      if(dataViewPermissions) {
        setCogPermissions(JSON.parse(dataViewPermissions));
      }
      if(dataExcludedPermissions) {
        setExcludedPermissions(JSON.parse(dataExcludedPermissions));
      }
    }, []);

    React.useEffect(() => {
      window.localStorage.setItem('PermissionsList', JSON.stringify(permissions));
      window.localStorage.setItem('PermissionsForUser', JSON.stringify(cogPermissions));
      window.localStorage.setItem('excludedPermissions', JSON.stringify(excludedPermissions));
    }, [permissions, cogPermissions, excludedPermissions]);

   return(
    <>
     <S.AlignToolTipContainer>
      <FormControl className={classes.formControl}>
        <S.SelectModal
          multiple
          value={permissions}
          onChange={handleChange}
          renderValue=
            {
                selected => separatorNames(selected)
            }
            MenuProps={MenuProps}
        >
          {listAllGroups.filter( x => JSON.stringify(cogPermissions).indexOf(JSON.stringify(x)) < 0).map((name: String)=> {
            return(
            <MenuItem key={`${name}`} value={`${name}`}>
            <Checkbox color="default" checked={permissions.indexOf(name) > -1} />
            <ListItemText secondary={name} />
            </MenuItem>
            );
          })}
        </S.SelectModal>
      </FormControl>
        <Tooltip
              description="Selecione quais permissões este usuário poderá acessar."
              icon="info"
            />
      </S.AlignToolTipContainer>
      <S.ListModalWrapper>
        {loading &&
          <S.Loadingwrapper>
            <CircularProgress
            size={50}
            style={{ marginRight: 8 }}
            />
          </S.Loadingwrapper>
        }
        {loading === false && cogPermissions.length === 0 ?
            <S.AlertMessage>Este usuário ainda não tem permissões</S.AlertMessage>
            :
            cogPermissions.map((permissions, index) => {
                return(
                  <S.ListWrapper key={index}>
                    <S.ListModalContent key={index}>{permissions}</S.ListModalContent>
                    <img src={cancelIcon} alt="Cancelar" onClick={() => removeCustomer(permissions)}/>
                  </S.ListWrapper>
                )
            })
        }
      </S.ListModalWrapper>
    </>
  )
}

export default SelectPermission;
