const config = {
  env: process.env.NODE_ENV,
  baseURI: process.env.REACT_APP_API_BASE_URI,
  endpoints: {
    GRAPHQL:
      process.env.REACT_APP_MIDDLEWARE ||
      'https://api-backoffice.devops.mandic.com.br/graphql',
    AUTH_URI:
      process.env.REACT_APP_AUTH_URI ||
      `https://%URL/oauth2/authorize?identity_provider=Google&redirect_uri=%REDIRECT_URI&response_type=TOKEN&client_id=%CLIENT_ID&scope=openid`,
    IDP_URI:
      process.env.REACT_APP_IDP_URI ||
      `https://cognito-idp.us-east-1.amazonaws.com/%COGNITO_POOL/.well-known/jwks.json`,
  },
  cognitoUrl:
    process.env.REACT_APP_AWS_COGNITO_URL ||
    'cmp-dev-backoffice.auth.us-east-1.amazoncognito.com',
  cognitoRegion: process.env.AWS_COGNITO_REGION || 'us-east-1',
  cognitoPool: process.env.REACT_APP_AWS_COGNITO_POOL || 'us-east-1_uPJLtuBtA',
  clientId: process.env.REACT_APP_AWS_CLIENT_ID || '3bvd77p67um02fin76gi61ct2h',
  validations: {
    emailRegex: /^(([^áàãâéêíóõôúç\\^~´`!$%¨&*ªº"'|<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
};

export default config;
