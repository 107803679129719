import jwkToPem from 'jwk-to-pem';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import config from 'modules/../utils/config';

export const getPublicKey = async (isIdToken?: boolean): Promise<any | undefined> => {
  const index = isIdToken ? 0 : 1;
  const { data } = await axios.get(config.endpoints.IDP_URI.replace(/%COGNITO_POOL/g, config.cognitoPool));
  const { keys } = data;
  if (!keys.length) {
    return;
  }
  const pem = jwkToPem(keys[index]);
  return { pem, ...keys[index] };
};

export const validateToken = async (
  token: string
): Promise<any | string | undefined> => {
  const stringPublicKey: string | null = localStorage.getItem('cmp_cHVibGljIGtleQo=');
  let publicKey: any;

  if (stringPublicKey) {
    publicKey = JSON.parse(stringPublicKey);
  } else {
    publicKey = await getPublicKey();
    localStorage.setItem('cmp_cHVibGljIGtleQo=', JSON.stringify(publicKey))
  }

  return jwt.verify(token, publicKey!.pem, {
    algorithms: [publicKey!.alg],
  });
};

export const login = (
  accessToken: string,
  idToken: string,
  expiresIn: number,
  callback: (err: boolean, email: string) => void
): void => {
  if (!accessToken) {
    callback(true, 'Credenciais inválidas!');
    return;
  }

  validateToken(accessToken).then(
    () => {
      const { email } = jwt.decode(idToken);
      localStorage.setItem('cmp_access_token', accessToken);
      localStorage.setItem('cmp_user_email', email);
      setTimeout(logout, (60 * 60) * 1000);
      callback(false, email);
    },
    err => {
      logout();
      callback(true, 'Credenciais inválidas!');
    }
  );
}

export const logout = (): void => {
  localStorage.removeItem('cmp_access_token');
  localStorage.removeItem('cmp_user_email');
  localStorage.removeItem('cmp_cHVibGljIGtleQo=');
}

export const isAuthenticated = (): boolean => {
  try {
    const token: string | null = localStorage.getItem('cmp_access_token');
    const stringPublicKey: string | null = localStorage.getItem('cmp_cHVibGljIGtleQo=');
    let publicKey: any;

    if (!token || !stringPublicKey) {
      return false;
    }

    publicKey = JSON.parse(stringPublicKey);

    return !!jwt.verify(token, publicKey!.pem, {
      algorithms: [publicKey!.alg],
    });
  } catch (err){
    console.error(err);
    return false;
  }
}
