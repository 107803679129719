import * as React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { MainLayout } from 'modules/layout/containers';
import ReportsRoutes from 'modules/reports/routes';
import Login from 'modules/login/routes';
import UnSusbscribe from 'modules/unsubscribe/routes';
import User from 'modules/user/routes';
import Workspace from 'modules/workspace/routes';
import Admin from 'modules/admin/routes';
import { withUser } from 'modules/auth/hoc';
import { IUser } from 'modules/auth/types';
import { StatusContainer } from 'modules/status/containers';

interface IProps {
  currentUser: IUser;
}

const RedirectReports = () => <Redirect to="/reports" />;

const renderRoutes = ({ currentUser }: IProps) => {
  return (
    <Router>
      <MainLayout currentUser={currentUser}>
        <Switch>
          <Route exact={true} path="/" render={RedirectReports} />
          <Route path="/login" component={Login} />
          <Route path="/unsubscribe" component={UnSusbscribe} />
          <Route path="/reports" component={ReportsRoutes} />
          <Route path="/users" component={User} />
          <Route path="/workspaces" component={Workspace} />
          <Route path="/admin" component={Admin} />
          <Route path="*" component={StatusContainer} />
        </Switch>
      </MainLayout>
    </Router>
  );
};

export default withUser(renderRoutes);
