const user = `
  query user($id: ID!){
    users(filters: {id: $id}) {
      username
      email
      workspaces {
        code
        name
      }
    }
  }
`;

const users = `
  query users($page: Int!, $email: String){
    users(page: $page, size: 10, orderBy: "email", filter: {email: $email}) {
      users {
        username
        email
        workspaces {
          code
          name
        }
      }
      lastPage
    }
  }
`;

const workspaces = `
  query {
    workspaces {
      workspaces {
        code
        name
      }
    }
  }
`;

export default {
  user, users, workspaces
}
