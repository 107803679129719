import * as React from 'react';
import * as S from './styled';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { IRouterProps } from 'modules/common/types';
import { IMenu, IMenuList } from '../types';
import { Input } from '@cmp/ui';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';

export const modules = [
  {
    key: 'backoffice',
    name: 'Backoffice',
    icon: 'assignment',
    list: [
      { name: 'Reports', modules: ['/reports'] },
      { name: 'Workspaces', modules: ['/workspaces'] },
      { name: 'Administrativo', modules: ['/admin'] },
    ],
  },
];

interface IProps extends IRouterProps {
  isMenuOpened: boolean;
  children: React.ReactNode;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const Menu = ({
  location,
  isMenuOpened,
  children,
  search,
  setSearch,
}: IProps) => {
  const flatMap = (listProduct: IMenu[]) => {
    return listProduct
      .map((item: IMenu) => item.list)
      .reduce(
        (destinationArray: IMenuList[], array: IMenuList[]) =>
          destinationArray.concat(array),
        []
      );
  };

  const buildNavItem = (item: IMenuList, itemIndex: number) => (
    <>
      {
        <S.NavItem
          key={`item_${itemIndex}`}
          exact={true}
          to={item.modules[0]}
          activeClassName="nav-item-active"
          isActive={() =>
            item.modules.some(m => new RegExp(m).test(location.pathname))
          }
          onClick={() => setSearch('')}
        >
          <S.ListItemMenu>{item.name}</S.ListItemMenu>
        </S.NavItem>
      }
    </>
  );

  return (
    <>
      {children}
      <S.DrawerCustom
        id="drawer-left"
        className="drawer"
        variant="persistent"
        anchor="left"
        open={isMenuOpened}
      >
        <div className="drawHeader">
          <Input
            type="text"
            text="Localizar serviços"
            fullWidth={true}
            Icon={IconSearch}
            rootProps={{ style: { border: 0 } }}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <Divider />
        <S.ListMenu>
          {search
            ? flatMap(modules).map((item, itemIndex) => (
                <div key={`item_${itemIndex}`}>
                  {new RegExp(search, 'i').test(item.name) && (
                    <>{buildNavItem(item, itemIndex)}</>
                  )}
                </div>
              ))
            : modules.map((category, categoryIndex) => (
                <div key={`category_${categoryIndex}`}>
                  <S.TitleMenu>{category.name}</S.TitleMenu>
                  <S.ListMenuItem>
                    {category.list.map((item, itemIndex) => (
                      <div key={`item_${itemIndex}`}>
                        {buildNavItem(item, itemIndex)}
                      </div>
                    ))}
                  </S.ListMenuItem>
                </div>
              ))}
        </S.ListMenu>
      </S.DrawerCustom>
    </>
  );
};

export default withRouter(Menu);
