import { colors, styled } from '@cmp/ui';
import Button from '@material-ui/core/Button';

export const Root = styled.div`
  margin: 32px;
  & span {
    color: ${colors.orange};
  }
`;

export const ButtonHome = styled(Button)`
  & {
    font-size: 12px;
    width: 300px;
    top: 40px;
    & span {
      color: ${colors.white};
    }
  }
`;
