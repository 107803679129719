import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import * as S from './Styled';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Input } from '@cmp/ui';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import { ReactComponent as IconUser } from 'modules/common/icons/icon-user.svg';
import { ReactComponent as IconMail } from 'modules/common/icons/mail.svg';
import { ReactComponent as IconLock } from 'modules/common/icons/lock.svg';
import { IWorkspace } from 'modules/types';
import {
  isEmailValid,
  isWorkspaceValid,
  isUsernameValid,
  isPasswordValid
} from 'modules/common/validations/formValidations';

interface IError {
  field: string;
  message: string;
}

interface IProps {
  formState: boolean;
  handleCloseModal: () => void;
  onSaveUser: () => void;
  getWorkspaces: () => Promise<IWorkspace[]>;
  createUser: (
    workspaces: string[],
    username: string,
    email: string,
    password: string,
    resetPassword: boolean
  ) => Promise<any>;
}

const DialogUserCreate = ({
  formState,
  handleCloseModal,
  getWorkspaces,
  createUser,
  onSaveUser,
}: IProps) => {
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetPassword, setResetPassword] = React.useState(false);
  const [workspacesSelected, setWorkspacesSelected] = React.useState<string[]>(
    []
  );
  const [workspaces, setWorkspaces] = React.useState<IWorkspace[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IError[]>([]);

  const cleanForm = () => {
    setUsername('');
    setEmail('');
    setPassword('');
    setWorkspacesSelected([]);
    setErrors([]);
  };

  const setError = (newError: IError) => {
    const localErrors = [...errors];
    const foundIndex = localErrors.findIndex(error => error.field === newError.field);
    if (foundIndex !== -1) {
      localErrors[foundIndex] = newError;
      setErrors(localErrors);
    } else {
      setErrors([...localErrors, newError]);
    }
  }

  const getErrorMessageByField = (fieldName: string): string => {
    const foundError = errors.find(error => error.field === fieldName);
    if (foundError !== undefined) {
      return foundError.message;
    }
    return '';
  }

  const removeValidationError = (field: string) => {
    const localErrors = [...errors];
    const foundIndex = localErrors.findIndex(error => error.field === field);
    if (foundIndex !== -1) {
      localErrors.splice(foundIndex, 1);
      setErrors(localErrors);
    }
  }

  const loadWorkspaces = React.useCallback(async () => {
    try {
      const response = await getWorkspaces();
      setWorkspaces(response);
    } catch (err) {
      console.error('Não foi possível carregar os Workspaces', err);
    }
  }, [getWorkspaces, setWorkspaces]);

  const onChangeWorkspace = (event: React.ChangeEvent<{ value: unknown }>) => {
    setWorkspacesSelected(event.target.value as string[]);
    if (isWorkspaceValid(event.target.value as string[], setError)) {
      removeValidationError('workspaces');
    }
  };

  const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
    if(isUsernameValid(event.currentTarget.value, setError)) {
      removeValidationError('username');
    }
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    if (isEmailValid(event.currentTarget.value, setError)) {
      removeValidationError('email');
    }
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
    if (isPasswordValid(event.currentTarget.value, setError)) {
      removeValidationError('password');
    }
  };

  const handleCreateUser = React.useCallback(() => {
    loadWorkspaces();
  }, [loadWorkspaces]);

  const handleSaveUser = (): void => {
    const isFieldsValid = isWorkspaceValid(workspacesSelected, setError) &&
      isUsernameValid(username, setError) &&
      isEmailValid(email, setError) &&
      isPasswordValid(password, setError);
    if (!isFieldsValid) {
      return;
    }
    setLoading(true);
    createUser(
      workspacesSelected,
      username,
      email,
      password,
      resetPassword
    ).then(
      () => {
        onSaveUser();
        handleCloseModal();
        setLoading(false);
        cleanForm();
      },
      () => {
        setLoading(false);
      }
    );
  };

  React.useEffect(() => {
    cleanForm();
    handleCreateUser();
  }, [formState, handleCreateUser]);

  return (
    <Dialog maxWidth="lg" open={formState}>
      <DialogTitle>Novo Usuário</DialogTitle>
      <S.DialogContentCustom>
        <S.FormContent>
          <FormControl error={getErrorMessageByField('workspaces') !== ''} variant="filled" className="formControl">
            <InputLabel className="input-label" id="multiple-workspace-label">
              Selecione ao menos uma Workspace
            </InputLabel>
            <Select
              labelId="multiple-workspace-label"
              id="multiple-workspace"
              className="custom-select"
              required
              multiple
              value={workspacesSelected}
              onChange={onChangeWorkspace}
              renderValue={workspacesSelected => (
                <div className="chips">
                  {(workspacesSelected as string[]).map(value => (
                    <Chip key={value} label={value} className="chip" />
                  ))}
                </div>
              )}
            >
              {workspaces.map(({ code, name }: IWorkspace) => (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </S.FormContent>
        <S.FormContent>
          <Input
            type="text"
            text="Nome de Usuário"
            required
            Icon={IconUser}
            fullWidth={true}
            size={300}
            mr={20}
            value={username}
            onChange={onChangeUsername}
            error={getErrorMessageByField('username')}
          />
          <Input
            type="email"
            text="E-mail"
            required
            Icon={IconMail}
            fullWidth={true}
            size={300}
            value={email}
            onChange={onChangeEmail}
            error={getErrorMessageByField('email')}
          />
        </S.FormContent>
        <S.FormContent>
          <Input
            type="password"
            text="Senha"
            required
            Icon={IconLock}
            fullWidth={true}
            size={300}
            value={password}
            onChange={onChangePassword}
            error={getErrorMessageByField('password')}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                className="ColorBar"
                value={resetPassword}
                onChange={event => setResetPassword(event.target.checked)}
              />
            }
            label="Redefinir senha no primeiro acesso"
          />
        </S.FormContent>
      </S.DialogContentCustom>
      <S.GroupButton>
        <Button
          color="primary"
          style={{ marginRight: '15px' }}
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveUser}
          disabled={loading}
        >
          Criar Usuário
        </Button>
      </S.GroupButton>
    </Dialog>
  );
};

export default DialogUserCreate;
