import { CircularProgress, Grid, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { paletteColor } from 'modules/common/styles/colors';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import styled from 'styled-components';

export interface IStyledProps {
  flexDirection?: string;
  alignItems?: string;
  width?: string | number;
  color?: string;
  marginTop?: string;
  active?: boolean;
  openChart?: boolean;
  textAlign?: string;
  height?: string;
  status?: boolean;
  lastElement?: boolean;
  switchStatus?: 'all' | 'none' | 'some';
  reportsType?: boolean;
  selected?: boolean;
}

export const DatePicker = styled(MuiDatePicker as any)`
  && {
    background-color: ${paletteColor.primary.white};
    width: 240px;
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    padding: 5px 10px;
    letter-spacing: 0.03em;
    text-indent: 10px;
    margin-top: 4px;

    &&:after {
      content: '';
      position: absolute;
      right: 22px;
      top: 12px;
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: ${paletteColor.info.soft};
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
    }

    &&:before {
      content: '';
      position: absolute;
      border-left: 1px solid ${paletteColor.info.border};
      left: 190px;
      top: 5px;
      height: 24px;
    }

    &&:before {
      border-bottom: 1px solid ${paletteColor.info.border};
      border-left: 1px solid ${paletteColor.info.border};
      left: calc(100% - 45px);
      top: 5px;
      bottom: 5px;
      height: auto;
      width: 0;
    }

    & .MuiInput-input {
      color: ${paletteColor.info.soft};
      font-size: 12px;
      cursor: pointer;
    }

    & .MuiInput-underline:before,
    & .MuiInput-underline:after,
    & .MuiInput-underline:hover:before {
      border: 0;
      &:hover {
        border: 0;
      }
    }

    & .MuiInput-underline:after {
      border: 0;
    }

    & .MuiSelect-icon {
      display: none;
    }
  }
`;

export const Root = styled.div`
  padding: 30px;
`;

export const FormContent = styled.div`
  padding: 24px 16px;

  &.customPadding {
    padding-bottom: 10px;
  }
`;

export const FormContentSelect = styled.div`
  padding: 10px 16px;
`;

export const FormContentMulti = styled.div`
  padding: 0px 16px 34px 16px;

  & .chip-container {
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
  }

  && .custom-select {
    width: 100%;
  }

  & .MuiChip-label {
    padding-left: 0;
  }

  & .MuiChip-label:first-letter {
    text-transform: capitalize;
  }

  & .MuiChip-root {
    font-size: 12px;
    margin: 4px 0 4px 4px;
  }

  && .multiteste {
    top: 246.25px;
    left: 17px;
  }
`;

export const CustomSelect = styled(Select)`
  && {
    background-color: ${paletteColor.primary.white};
    width: 240px;
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    padding: 5px;
    color: ${paletteColor.info.soft};
    font-size: 12px;
    text-indent: 10px;

    &&:hover {
      background-color: ${paletteColor.primary.white};
      border-bottom: 1px solid ${paletteColor.info.border};
    }

    &&:before {
      border-bottom: 1px solid ${paletteColor.info.border};
      border-left: 1px solid ${paletteColor.info.border};
      left: calc(100% - 50px);
      top: 5px;
      bottom: 5px;
      height: auto;
      width: 0;
    }

    & .MuiSelect-select {
      &:focus {
        background-color: transparent;
      }
      &:before {
        content: '';
        position: absolute;
        right: 22px;
        top: 12px;
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: ${paletteColor.info.soft};
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
      }

      && .MuiMenu-paper {
        top: 140px !important;
      }
    }

    & .MuiSelect-icon {
      display: none;
    }
  }

  &&:after {
    background-color: ${paletteColor.primary.white};
    border-bottom: none;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  && {
    background-color: ${paletteColor.primary.white};
    padding: 8px;
  }
`;

export const PDFContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 50px;
  height: calc(100vh - 50px);
  background-color: ${paletteColor.info.fill};
`;

export const PDFViewer = styled.div`
  z-index: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
`;

export const MarkdownEditor = styled(ReactMde)`
  && .mde-header {
    background-color: ${paletteColor.primary.white};
    border-bottom: 0px;
  }

  && .mde-textarea-wrapper textarea.mde-text{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  && .mde-header-item:hover {
    background-color: #efefef;
  }
`;

export const LoadingContainer = styled(Grid)`
  position: absolute;
  padding: 0 16vw;
  background-color: ${paletteColor.info.fill};
`;

export const ButtonProgress = styled(CircularProgress)`
  margin-left: 15px;
  margin-right: 14px;
  margin-bottom: 1px;
`;

export const textCapitalize = styled.div`
  text-transform: capitalize;
 `;

export const TypographyInfo = styled(Typography)`
  color: ${paletteColor.info.soft};
`;
