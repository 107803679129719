import * as React from 'react';
import client from '../../../apolloClient';
import Report from '../components/Report';
import { queries } from '../graphql';
import { IReport } from '../types';

const ReportsContainer = () => {
  const getWorkspaces = () => {
    return client
      .query({
        query: queries.workspaces,
      })
      .then(response => response.data.workspaces);
  };

  const getReports = async (
    workspaceCode: string,
    offset: number = 0,
    awsRegion: string,
    awsAccounts: string[]
  ): Promise<IReport> => {
    const getQueriesResult = async (queries: any[], variables: object) => {
      const result = await Promise.all(
        queries.map(query =>
          client
            .query({
              query,
              variables,
            })
            .then(response => response.data)
            .catch(console.error)
        )
      );

      const aggregatedResult = result.reduce(
        (aggregatedReport, currentReport) => ({
          ...aggregatedReport,
          ...currentReport,
        }),
        {}
      );

      return aggregatedResult;
    };

    const commonResults = await getQueriesResult(queries.reports.common, {
      workspaceCode,
      offset,
    });

    const awsResults = (
      await Promise.all(
        awsAccounts.map(async awsAccount => ({
          [awsAccount]: await getQueriesResult(queries.reports.aws, {
            workspaceCode,
            offset,
            awsRegion,
            awsAccount,
          }),
        }))
      )
    ).reduce((prev, curr) => ({ ...prev, ...curr }), {});

    return { common: commonResults, aws: awsResults };
  };

  const getAccounts = (cd: String) => {
    return client
      .query({
        query: queries.accounts,
        variables: {
          cd,
        },
      })
      .then(response => response.data.accounts);
  };

  return (
    <Report
      getReports={getReports}
      getWorkspaces={getWorkspaces}
      getAccounts={getAccounts}
    />
  );
};
export default ReportsContainer;
