import { EmptyState } from '@cmp/ui';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as IconAlert } from 'modules/common/icons/icon-alert.svg';
import { slugify } from 'modules/common/parses/strings';
import moment, { Moment } from 'moment';
import * as Showdown from 'showdown';
import * as React from 'react';
import { IAccounts, IReport, IReportWithDate, IWorkspace } from '../types';
import ReportForm from './ReportForm';
import * as S from './Styled';
import client from '../../../apolloClient';
import { queries } from '../graphql';
import PDFObject from 'pdfobject';

const mdConverter = new Showdown.Converter();
mdConverter.setFlavor('github');

const containerId = 'pdfViewerContainerId';

interface IProps {
  getReports: (
    workspaceCode: string,
    offset: number,
    awsRegion: string,
    awsAccounts: string[]
  ) => Promise<IReport>;
  getWorkspaces: () => any;
  getAccounts: (cd: string) => Promise<any>;
}

const Report = ({ getReports, getWorkspaces, getAccounts }: IProps) => {
  const [selectedDate, setSelectedDate] = React.useState<Moment>(moment());
  const [workspaces, setWorkspaces] = React.useState<IWorkspace[]>([]);
  const [awsRegion, setAwsRegion] = React.useState<string>('us-east-1');
  const [loading, setLoading] = React.useState<number>(0);
  const [editorLoading, setEditorLoading] = React.useState<boolean>(false);
  const [selectorLoading, setSelectorLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [reports, setReports] = React.useState<
    [IReportWithDate, IReportWithDate]
  >(null);
  const [awsAccounts, setAwsAccounts] = React.useState<IAccounts[]>([]);
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([]);
  const [costRecommendation, setCostRecommendation] = React.useState<string>(
    ''
  );
  const [
    performanceRecommendation,
    setPerformanceRecommendation,
  ] = React.useState<string>('');
  const [additionalInformation, setAdditionalInformation] = React.useState<
    string
  >('');
  const [workspace, setWorkspace] = React.useState<IWorkspace | undefined>(
    undefined
  );
  const [costEditor, setCostEditor] = React.useState<string>('');
  const [perfEditor, setPerfEditor] = React.useState<string>('');
  const [infoEditor, setInfoEditor] = React.useState<string>('');
  const [pdfBlobUrl, setPdfBlobUrl] = React.useState<string | null>(null);

  const handleCreatePDF = async (
    reportWorkspace: IWorkspace | undefined,
    reportDate: string | Moment,
    reportTitle: string
  ) => {
    const workspaceName = reportWorkspace
      ? reportWorkspace.name
      : 'unknow-workspace';
    const parsedDate = moment(reportDate).format('YYYY-MM');
    const fileName = `${parsedDate}-${slugify(workspaceName)}-${reportTitle}`;

    const pdfTab = window.open('about:blank', '_blank');
    if (!pdfTab) {
      console.error('Could not open a new tab for pdf');
      return;
    }

    pdfTab.document.open();
    pdfTab.document.write(
      `<iframe width='100%' height='100%' src='${pdfBlobUrl}' frameBorder="0"></iframe>`
    );
    pdfTab.document.title = fileName;
    pdfTab.document.close();
    pdfTab.focus();
  };

  const handleDownloadPdf = React.useCallback(async () => {
    if (!reports) {
      return;
    }

    setLoading(loading => loading + 1);
    const pdfBase64: string | null = await client
      .query({
        query: queries.reportsPdf,
        variables: {
          rawContent: JSON.stringify([
            {
              ...reports[0],
              htmlTexts: {
                performanceRecommendation: mdConverter.makeHtml(
                  performanceRecommendation
                ),
                costRecommendation: mdConverter.makeHtml(costRecommendation),
                additionalInformation: mdConverter.makeHtml(
                  additionalInformation
                ),
              },
            },
            reports[1],
          ]),
          workspaceName: workspace.name,
          workspaceCode: workspace.code,
          authorEmail: localStorage.getItem('cmp_user_email') || '',
        },
      })
      .then(response => response.data.sreReportPdf.pdfBase64)
      .catch(error => {
        console.error(error);
        return null;
      });
    setLoading(loading => loading - 1);

    if (!pdfBase64) {
      return;
    }

    // Conversion from base64 to blob data
    const byteCharacters = atob(
      pdfBase64.replace('data:application/pdf;base64,', '')
    );

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
    setPdfBlobUrl(URL.createObjectURL(pdfBlob));
  }, [
    additionalInformation,
    costRecommendation,
    performanceRecommendation,
    reports,
    workspace,
  ]);

  const loadWorkspaces = React.useCallback(async () => {
    try {
      const response = await getWorkspaces();
      setWorkspaces(response.workspaces);
    } catch (error) {
      console.error(error);
    }
  }, [getWorkspaces, setWorkspaces]);

   const loadAccounts = React.useCallback(async () => {
    if (workspace) {
      try {
        const response = await getAccounts(workspace.clientCode);
        setAwsAccounts(response);
      } catch (error) {
        console.error(error);
      }
    }
  }, [workspace, getAccounts, setAwsAccounts]);



  const loadReports = React.useCallback(async () => {
    if (workspace && selectedAccounts) {
      try {
        setLoading(loading => loading + 1);
        setError(false);
        const offset = -Math.abs(moment().diff(selectedDate, 'months'));

        const currentMonthReport: IReportWithDate = {
          ...(await getReports(
            workspace.code,
            offset,
            awsRegion,
            selectedAccounts
          )),
          month: selectedDate.format('MMMM'),
          year: selectedDate.format('YYYY'),
        };

        const afterMonthReport: IReportWithDate = {
          ...(await getReports(
            workspace.code,
            offset - 1,
            awsRegion,
            selectedAccounts
          )),
          month: moment(selectedDate)
            .add(-1, 'month')
            .format('MMMM'),
          year: moment(selectedDate)
            .add(-1, 'month')
            .format('YYYY'),
        };

        setReports([currentMonthReport, afterMonthReport]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(loading => loading - 1);
      }
    }
  }, [
    workspace,
    selectedAccounts,
    selectedDate,
    setLoading,
    setError,
    setReports,
    getReports,
    awsRegion,
  ]);

  React.useEffect(() => {
    loadWorkspaces();
  }, [loadWorkspaces]);

  React.useEffect(() => {
    loadReports();
  }, [workspace, selectedDate, selectedAccounts, loadReports]);

  React.useEffect(() => {
    loadAccounts();
  }, [workspace, loadAccounts]);

  React.useEffect(() => {
    handleDownloadPdf();
  }, [handleDownloadPdf]);

  React.useEffect(() => {
    if (!pdfBlobUrl) {
      return;
    }
    PDFObject.embed(pdfBlobUrl, `#${containerId}`);
  }, [pdfBlobUrl]);

  const render = () => {
    const result = [];

    if (loading > 0 || editorLoading || selectorLoading) {
      result.push(
        <S.LoadingContainer item={true} sm={12} md={6} key="loading">
          <CircularProgress size={55} />
        </S.LoadingContainer>
      );
    }

    if (error) {
      return (
        <EmptyState>
          <IconAlert />
          <Typography component="h2">Erro ao carregar informações</Typography>
        </EmptyState>
      );
    }

    if (workspace && reports && selectedAccounts) {
      result.push(<S.PDFViewer key="pdf_viewer" id={containerId} />);
    } else if (loading <= 0) {
      result.push(
        <Typography key="no_workspace">Selecione um workspace</Typography>
      );
    }

    return result;
  };

  return (
    <Grid container={true}>
      <Grid item={true} sm={12} md={6}>
        <Box borderRight="1px solid #DFE1EB">
          <ReportForm
            handleCreatePDF={() =>
              handleCreatePDF(
                workspace,
                selectedDate,
                'relatorio-disponibilidade-custos-melhorias'
              )
            }
            loading={loading > 0}
            setEditorLoading={setEditorLoading}
            setSelectorLoading={setSelectorLoading}
            workspace={workspace}
            workspaces={workspaces}
            setWorkspace={setWorkspace}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            costRecommendation={costRecommendation}
            setCostRecommendation={setCostRecommendation}
            costEditor={costEditor}
            setCostEditor={setCostEditor}
            performanceRecommendation={performanceRecommendation}
            setPerformanceRecommendation={setPerformanceRecommendation}
            infoEditor={infoEditor}
            setInfoEditor={setInfoEditor}
            additionalInformation={additionalInformation}
            setAdditionalInformation={setAdditionalInformation}
            perfEditor={perfEditor}
            setPerfEditor={setPerfEditor}
            awsRegion={awsRegion}
            setAwsRegion={setAwsRegion}
            awsAccounts={awsAccounts}
            setAwsAccounts={setAwsAccounts}
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
          />
        </Box>
      </Grid>
      <Grid item={true} sm={12} md={6}>
        <S.PDFContainer>{render()}</S.PDFContainer>
      </Grid>
    </Grid>
  );
};

export default Report;
