import gql from 'graphql-tag';

const incidents = gql`
  query incidentsReport($workspaceCode: String!, $offset: Int = 0) {
    incidents(workspaceCode: $workspaceCode, offset: $offset) {
      total
      mtta
      mttr
      top5 {
        name
        hostname
        downtime
        uptime
        severity
        count
      }
      sla {
        sla_ack
        sla_res
        sla_count
        sla_percentage
      }
    }
  }
`;

const outages = gql`
  query outagesReport($workspaceCode: String!, $offset: Int = 0) {
    outages(workspaceCode: $workspaceCode, offset: $offset) {
      name
      hostname
      outages
      uptime {
        percentage
      }
      downtime {
        percentage
        total
      }
    }
  }
`;

const costs = gql`
  query costsReport(
    $workspaceCode: String!
    $awsAccount: String!
    $offset: Int = 0
  ) {
    costs(
      workspaceCode: $workspaceCode
      offset: $offset
      awsAccount: $awsAccount
    ) {
      costs {
        month
        total
      }
      reservation {
        service
        recommendations {
          paymentOption
          summary {
            estimatedMonthlySavings
            recommendationCount
            savingsPercentage
          }
        }
      }
      rightsizing {
        recommendations {
          rightsizingType
          currentInstance {
            instanceName
            instanceId
          }
          recommendationDetails {
            estimatedMonthlySavings
          }
        }
      }
    }
  }
`;

const config = gql`
  query configReport(
    $workspaceCode: String!
    $awsRegion: String!
    $awsAccount: String!
    $offset: Int = 0
  ) {
    config(
      workspaceCode: $workspaceCode
      region: $awsRegion
      offset: $offset
      awsAccount: $awsAccount
    ) {
      rules {
        name
        description
        uncompliant
      }
    }
  }
`;

const worklogs = gql`
  query worklogsReport($workspaceCode: String!, $offset: Int = 0) {
    worklogs(workspaceCode: $workspaceCode, offset: $offset) {
      issues {
        key
        summary
        timeSpentSeconds
        type
      }
      types {
        type
        timeSpentSeconds
      }
      quarter {
        pool_size
        consumed
        remaining
        month
      }
      total {
        pool_size
        consumed
        remaining
        month
      }
      month {
        pool_size
        month
      }
    }
  }
`;

const cloudtrail = gql`
  query cloudtrailReport(
    $workspaceCode: String!
    $awsRegion: String!
    $awsAccount: String!
  ) {
    cloudtrail(
      workspaceCode: $workspaceCode
      region: $awsRegion
      awsAccount: $awsAccount
    ) {
      analysis {
        errors
        warnings
      }
    }
  }
`;

const iam = gql`
  query iamReport(
    $workspaceCode: String!
    $awsRegion: String!
    $awsAccount: String!
  ) {
    iam(
      workspaceCode: $workspaceCode
      region: $awsRegion
      awsAccount: $awsAccount
    ) {
      analysis {
        errors
        warnings
      }
    }
  }
`;

const reportsPdf = gql`
  query reportPdf(
    $rawContent: String!
    $workspaceCode: String!
    $workspaceName: String!
    $authorEmail: String!
  ) {
    sreReportPdf(
      rawContent: $rawContent
      workspaceCode: $workspaceCode
      workspaceName: $workspaceName
      authorEmail: $authorEmail
    ) {
      pdfBase64
    }
  }
`;

const workspaces = gql`
  query {
    workspaces(orderBy: "name") {
      workspaces {
        code
        name
        clientCode
      }
    }
  }
`;

const accounts = gql`
  query accounts($cd: String!) {
    accounts(filter: {cd: $cd}){
      accountId
      alias
    }
}
`;

export default {
  reports: {
    common: [worklogs, incidents, outages],
    aws: [iam, cloudtrail, config, costs],
  },
  reportsPdf,
  workspaces,
  accounts
};
