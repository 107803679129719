import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import config from './utils/config';

const cache = new InMemoryCache({
  dataIdFromObject: object => object.id || null,
  addTypename: false,
});

const httpLink = createHttpLink({
  credentials: 'same-origin',
  uri: config.endpoints.GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('cmp_access_token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    console.error('Disconnect ...');
  }
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const client = new ApolloClient({
  cache,
  link,
});

export default client;
