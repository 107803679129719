import * as React from 'react';
import { Login } from '../components';
import { AppConsumer } from '../../../appContext';

const LoginContainer = () => {
  return(
    <AppConsumer>
    {({ setError, setUser, errors }) => (
      <Login
        setUser={setUser}
        setError={setError}
        errors={errors}
      />
    )}
  </AppConsumer>
  )
}
export default LoginContainer;

