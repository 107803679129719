import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Skeleton from '@material-ui/lab/Skeleton';

interface IProps {
  hasMore: boolean;
  loadMore: (page: number) => void;
  children?: any;
}

const LoaderScroll = () => {
  return (
    <>
      {new Array(7).fill(true).map((_, index) => (
       <Skeleton key={`load_${index}`} height={80} />
      ))}
    </>
  );
};

const UserInfiniteScroller = ({ hasMore, loadMore, children }: IProps) => {

  return (
    <InfiniteScroll
      pageStart={0}
      hasMore={hasMore}
      loadMore={loadMore}
      loader={<LoaderScroll key={"infinity_loader"} />}
      style={{ width: '100%' }}
    >
      {children}
    </InfiniteScroll>
  );
};

export default UserInfiniteScroller;
