import * as React from 'react';
import * as S from './Styled';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { CircularProgress } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import cancelIcon from '../../assets/cancel.svg';
import Tooltip from 'modules/common/components/Tooltip';
import { CognitoApi } from './CognitoApi';
import { makeStyles } from '@material-ui/core';

const MenuProps = {
  PaperProps: {
    style: {
      position: 'absolute',
      marginTop: '16%',
      marginLeft: -4,
      maxHeight: 200
    },
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    position: 'relative'
  }
}))

const ModalCustomersList = (props) => {
  const classes = useStyles();
  const [personName, setPersonName] = useState<String[]>([]);
  const [personKey, setPersonKey] = useState<String[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const [workspaces, setWorkspaces] = useState([]);
  const [viewWorkspaces, setViewWorkspaces] = useState([]);
  const [excludedCustomers, setExcludedCustomers] = useState<String[]>([]);
  const [updateWorkspace, setUpdateWorkspace] = useState<String[]>([]);
  const [verifyPermission, setVerifyPermission] = useState<boolean>(false);

  const getAllCustomers = useCallback(() => {
    if(localStorage.getItem('workspacesForUser') !== null) {
      CognitoApi.getWorkspaces().then(workspaces => {
      setWorkspaces(workspaces);
      let filteredArray = workspaces.filter( works => !viewWorkspaces.filter( a2 => works.GroupName === a2.idCustomer).length);
      setWorkspaces(filteredArray)
      }).finally(() => {
        setReload(false);
      })
    }
  }, [viewWorkspaces]);

  const getCustomersForUser = useCallback(() => {
    if(localStorage.getItem('workspacesForUser') === null) {
      CognitoApi.getClientsFromUser(`${props.email}`)
        .then(response => {
          const arrayOfWorkspaces = [];
          response.map(workspaces => arrayOfWorkspaces.push({description: workspaces.Description, idCustomer: workspaces.GroupName}));
          setViewWorkspaces(arrayOfWorkspaces);
          setLoading(false);
        })
    }else {
      setTimeout(() => {
        setLoading(false);
      }, 2000)
    }
  }, [props])

  const verifyPermissionViewAllWorspaces = useCallback(() => {
    CognitoApi.getUsersInGroup("view_all_workspaces")
      .then(response => {
        const permissionsArray = [];
        response.map(permissions => permissionsArray.push(permissions.UserEmail));
        permissionsArray.includes(props.email) === true ? setVerifyPermission(true) : setVerifyPermission(false);
      })
  }, [props]);

  useEffect(() => {
    if (reload) {
      getAllCustomers();
      getCustomersForUser()
      verifyPermissionViewAllWorspaces();
    }
  }, [reload, getAllCustomers, getCustomersForUser, verifyPermissionViewAllWorspaces]);

  const handleChange = (event, props) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
    // Por algum motivo, a key estava sendo adicionada com um 2 na frente da key de cada cliente.
    // Regexp remove um numeral antes de cada key
    const key = props.key.replace(/^\.\$wks=[0-9]/ig, "wks:");
    let keys = personKey;
    if(keys.includes(key)) {
      keys = keys.filter(gn => gn !== key);
    }else {
      keys.push(key);
    }
    setPersonKey(keys);
  };

  function separatorNames(selected) {
    return selected.join(', ');
  };

  const handleAddPopulateGroupName = (workspaces) => {
    const wsp = updateWorkspace;
    wsp.push(workspaces.GroupName);
    setUpdateWorkspace(wsp);
  }

  const handleDropPopulateGroupName = (workspaces) => {
    const wsp = updateWorkspace.filter(gn => gn !== workspaces.GroupName);
    setUpdateWorkspace(wsp);
  }

  const removeCustomer = (workspaces) => {
    setViewWorkspaces(viewWorkspaces.filter(removedWorkspace => removedWorkspace !== workspaces));
    setExcludedCustomers((removedWorkspace) => [...removedWorkspace, workspaces]);
  };

  useEffect(() => {
    props.func(excludedCustomers, personName, updateWorkspace, personKey);
  }, [props, excludedCustomers, personName, updateWorkspace, personKey]);

  useEffect(() => {
    const dataAllCustomers = window.localStorage.getItem('customersList');
    const dataWorkspaces = window.localStorage.getItem('workspacesForUser');
    const dataExcludedWorkspaces = window.localStorage.getItem('excludedWorkspaces');
    if(dataAllCustomers) {
      setPersonName(JSON.parse(dataAllCustomers));
    }
    if(dataWorkspaces) {
      setViewWorkspaces(JSON.parse(dataWorkspaces));
    }
    if(dataExcludedWorkspaces) {
      setExcludedCustomers(JSON.parse(dataExcludedWorkspaces));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('customersList', JSON.stringify(personName));
    window.localStorage.setItem('workspacesForUser', JSON.stringify(viewWorkspaces));
    window.localStorage.setItem('excludedWorkspaces', JSON.stringify(excludedCustomers));
  }, [viewWorkspaces, excludedCustomers, personName]);

  function compareString(a, b) {
    if(a.Description < b.Description){
      return -1;
    }
    if(a.Description > b.Description){
      return 1;
    }
    return 0;
  }


  return (
    <div>
      <S.AlignToolTipContainer>
      <FormControl className={classes.formControl}>
        <S.SelectModal
          multiple
          value={personName}
          onChange={handleChange}
          renderValue=
            {
              selected => separatorNames(selected)
            }
            MenuProps={MenuProps}
        >
          {workspaces.sort(compareString).map((name: any, index: number) => {
            return (
                <S.CustomMenuItem key={`${name.GroupName}`} value={`${name.Description}`}>
                    <Checkbox color="default" checked={personName.indexOf(name.Description) > -1} onClick={(event) => {
                      (event.target as HTMLInputElement).checked
                        ? handleAddPopulateGroupName(workspaces[index])
                        : handleDropPopulateGroupName(workspaces[index])
                    }}/>
                    <ListItemText secondary={name.Description} />
                </S.CustomMenuItem>
            );
          })}
        </S.SelectModal>
      </FormControl>
        <Tooltip
            description="Selecione quais clientes este usuário poderá acessar."
            icon="info"
          />
      </S.AlignToolTipContainer>
      <S.ListModalWrapper>
        {loading &&
          <S.Loadingwrapper>
            <CircularProgress
            size={50}
            style={{ marginRight: 8 }}
            />
          </S.Loadingwrapper>
        }
        {!loading && !verifyPermission && viewWorkspaces.length === 0 &&
          <S.AlertMessage>Este usuário não possui acesso a nenhum dos clientes</S.AlertMessage>
        }
        {!loading && verifyPermission ?
          <S.AlertMessage>Este usuário possui acesso a TODOS os clientes</S.AlertMessage>
          :
          viewWorkspaces.map((workspaces, index) => {
            return(
              <S.ListWrapper key={index}>
                <S.ListModalContent>{workspaces.description}</S.ListModalContent>
                <img src={cancelIcon} alt="Cancelar" onClick={() => removeCustomer(workspaces)}/>
              </S.ListWrapper>
            )
          })
        }
      </S.ListModalWrapper>
    </div>
  );
};

export default ModalCustomersList;
