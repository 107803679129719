import * as React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import './modules/common/styles/App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './utils/config';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (config.env === 'production') {
  serviceWorker.register(false);
} else {
  serviceWorker.unregister();
}
