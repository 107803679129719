import * as React from 'react';
import { Navigation } from '../components/index';
import { IUser } from 'modules/auth/types';

interface IProps {
  getUser: () => IUser;
  children: React.ReactNode;
}

const NavigationContainer = ({ getUser, children }: IProps) => (
  <Navigation getUser={getUser} children={children} />
);

export default NavigationContainer;
