import React from 'react';
import * as S from './LoginInputStyled';
import { paletteColor } from './../../common/styles/colors'; 

interface IProps {
  onChange:(event) => void;
  onBlur?:(event) => void;
  onFocus?:(event) => void;
  plasholder?:string;
  value:string;
  type:string;
  text:string;
  helper?:string;
  error?:boolean;
  icon?:React.FunctionComponent<React.SVGProps<SVGSVGElement>> 
}

/*
 * @Component LoginInput
 * 
 * */
function LoginInput({
  onChange,
  onBlur,
  onFocus,
  plasholder,
  value,
  type,
  text,
  helper,
  error,
  icon
}: IProps): JSX.Element {
  const [showPassword, setShowPassword] = React.useState(false);
  const [focus, setFocus] = React.useState(false);

  return (
    <>
      <S.LoginInputText>{text}</S.LoginInputText>
      <S.LoginInputContainer style={{
        border: focus ? `1px solid #E00000` : `1.5px solid ${paletteColor.info.border}`
      }}>
        {icon && 
        <S.LoginInputIcon>
        </S.LoginInputIcon> }
        <input
          type={showPassword ? "text" : type}
          onChange={onChange}
          placeholder={plasholder}
          onFocus={() => setFocus(true)}
          onBlur={(event) => {
            onBlur(event);
            setFocus(false);
          }}
          value={value}
          autoComplete="off"
          style={{
            left: icon ? 64 : 9
          }}
        />
      {type === "password" &&
        <S.LoginShowText onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? "Esconder" : "Mostrar"}
        </S.LoginShowText>}
      </S.LoginInputContainer>
      {error && <S.LoginInputHelper>{helper}</S.LoginInputHelper>}
    </>
  );
}

export default LoginInput;