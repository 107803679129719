import { createTheme } from '@material-ui/core/styles';
import { paletteColor } from './colors';

const theme = createTheme({
  palette: {
    primary: { main: paletteColor.primary.main },
    secondary: { main: paletteColor.primary.white },
  },
  typography: {
    fontSize: 14,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '12px 16px',
      },
      label: {
        textTransform: 'capitalize',
      },
    },
    MuiPaper: {
      root: {
        transition: 'none !important',
        transformOrigin: '0 0 !important',
        top: '0px !important'
      }
    }
  },
});

export default theme;
