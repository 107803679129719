import React, { useState, useCallback, useEffect } from 'react';
import * as S from './Styled';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Tooltip from 'modules/common/components/Tooltip';
import { CognitoApi } from './CognitoApi';


const Workspaces = (props) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [reload, setReload] = useState<boolean>(true);
  const [grantedAccessList, setGrantedAccessList] = React.useState([]);
  const [loadError, setLoadError] = React.useState(false);

  const getAllCustomers = useCallback(() => {
    if(localStorage.getItem('Workspaces') === null) {
      CognitoApi.getWorkspaces().then(workspaces => {
        setWorkspaces(workspaces);
      }).finally(() => {
        setReload(false);
      })
    }else {
      const dataWorkspaces = window.localStorage.getItem('Workspaces');
      setWorkspaces(JSON.parse(dataWorkspaces));
      setReload(false);
    }
  }, []);

  const addWorkspace = (works) => {
    const verifyIfGroupExists = grantedAccessList.map(workspace => workspace.GroupName);
    if(!verifyIfGroupExists.includes(works.GroupName)) {
      setGrantedAccessList((prevWorkspace) => [...prevWorkspace, works]);
    }else {
      setLoadError(true);
      setTimeout(() => {
        setLoadError(false);
      }, 3000);
    }
  };

  const removeWorkspace = (workspaces) => {
    setGrantedAccessList(grantedAccessList.filter(element => !element.GroupName.includes(workspaces.GroupName)));
  };

  useEffect(() => {
    if (reload) {
      getAllCustomers();
    }
  }, [reload, getAllCustomers]);

  useEffect(() => {
    const datagrantedAcessList = window.localStorage.getItem('Acesso-concedido-Workspaces');

    if(datagrantedAcessList) {
      setGrantedAccessList(JSON.parse(datagrantedAcessList));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('Acesso-concedido-Workspaces', JSON.stringify(grantedAccessList));
    window.localStorage.setItem('Workspaces', JSON.stringify(workspaces));
    props.func(grantedAccessList);
  }, [props, grantedAccessList, workspaces]);

  function compare (a, b){
    if (a.Description < b.Description){
      return -1;
    }
    if (a.Description > b.Description){
      return 1;
    }
    return 0;
  }

  return(
    <S.FlexContainer>
      {reload === true &&
        <S.LoadingCircleContainer>
          <CircularProgress
          size={50}
          style={{ marginRight: 8 }}
          />
        </S.LoadingCircleContainer>
      }
      {reload === false &&
        <S.ComponentsContainer>
          {workspaces.sort(compare).map((works, index) => {
            return (
              <div key={index}>
                <S.ButtonPermissions onClick={() => addWorkspace(works)}>{works.Description}</S.ButtonPermissions>
                <S.DividerPermissions/>
              </div>
            )}
          )}
        </S.ComponentsContainer>
      }
      <S.ComponentsContainer>
        <S.TitleAccessList>
         <p className='Titulo'>Acesso concedido</p>
        <Tooltip
            description="Aqui constarão os clientes e permissões que este(s) usuário(s) têm permissão de acesso"
            icon="info"
          />
        </S.TitleAccessList>
        <S.GrantedAccess>
          {grantedAccessList.map((access, index) => {
            return(
              <div key={index}>
                <S.ButtonPermissions onClick={() => removeWorkspace(access)}>{access.Description}</S.ButtonPermissions>
              </div>
            )
          })}
        </S.GrantedAccess>
      </S.ComponentsContainer>
      <S.ErrorContainer>
        {loadError &&
          <Alert variant="filled" severity="error">
            Este cliente já foi selecionado.
          </Alert>
        }
      </S.ErrorContainer>
    </S.FlexContainer>
  );
};

export default Workspaces;
