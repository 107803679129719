import * as React from 'react';
import * as S from './Styled';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import { paletteColor } from 'modules/common/styles/colors';
import successIcon from '../../assets/success.svg';
import { CognitoApi } from './CognitoApi';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: `${paletteColor.primary.white}`,
  border: '0',
  borderRadius: '4px',
  boxShadow: 24, 
  outline: 'none',
  p: '15px 15px',
  width: 650
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [confirmWorkspaces, setConfirmWorkspaces] = React.useState([]);
  const [confirmPermissions, setConfirmPermissions] = React.useState([]);
  const [confirmUsers, setConfirmUsers] = React.useState([]);
  const [dispareAlert, setDispareAlert] = React.useState(false);
  const [personKey, setPersonKey] = React.useState<string[]>([])
  const [step, setStep] = React.useState(1);
  
  React.useEffect(() => {
    setConfirmWorkspaces(props.confirmWorkspaces);
    setConfirmPermissions(props.confirmPermissions);
    setConfirmUsers(props.confirmUsers);
    setPersonKey(props.personKey);
  }, [props.confirmWorkspaces, props.confirmPermissions, props.confirmUsers, props.personKey]);

  const handleOpen = () => {
    if(confirmUsers.length === 0 || confirmPermissions.length === 0 || confirmWorkspaces.length === 0) {
      setDispareAlert(true);
      setTimeout(() => {
      setDispareAlert(false);
      }, 5000);
    }else {
      setOpen(true);
    }
  }

  const handleSend = () => {
    personKey.map(async (usersId) => (
      await CognitoApi.setAddUserToGroup(
        usersId, 
        confirmWorkspaces.map(works => works.GroupName).concat(confirmPermissions)
      )
    ))
    setStep(2);
    setTimeout(() => {
      window.location.reload();
    }, 2000)
  }
  return (
    <div>
      <S.ErrorContainer>
        {dispareAlert === true &&
          <Alert variant="filled" severity="error">
            Selecione ao menos 1 usuário, 1 cliente e 1 permissão
          </Alert>
        }
      </S.ErrorContainer>
      <S.ButtonSaveChanges onClick={handleOpen}>Salvar alterações</S.ButtonSaveChanges>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {step === 1 ?
          <Box sx={style}>
            <S.TitleModalConfirm>
              Confirmar alterações
            </S.TitleModalConfirm>
            <Divider style={{marginTop: '10px', background: `${paletteColor.info.border}`}}/>
            <S.CustomTypography>
              O(s) seguinte(s) usuário(s):
            </S.CustomTypography>
            <S.CustomListContainer>
              {confirmUsers.map((names, index) => {
                return (
                  <ul key={index}>
                    <S.CustomList>{names}</S.CustomList>
                  </ul>
                )
              })
              }
            </S.CustomListContainer>
            <S.CustomTypography>
              Terá(ão) acesso às seguintes permissões:
            </S.CustomTypography>
            <S.CustomListContainer>
              {confirmWorkspaces.map((users, index) => {
                return (
                  <ul key={index}>
                    <li>{users.Description}</li>
                  </ul>
                )
              })
              }
              {confirmPermissions.map((works, index) => {
                return (
                  <ul key={index}>
                    <S.CustomList>{works}</S.CustomList>
                  </ul>
                )
              })
              }
            </S.CustomListContainer>
            <S.ButtonsWrapper>
              <S.ModalButtonCancel onClick={handleClose}>Cancelar</S.ModalButtonCancel>
              <S.ButtonSaveChanges onClick={handleSend}>Confirmar</S.ButtonSaveChanges>
            </S.ButtonsWrapper>           
          </Box>
          :
          <Box sx={style}>
            <S.FlexModal>
              <img src={successIcon} alt="success_icon" />
              <h3>Cadastro concluído com sucesso!</h3>
            </S.FlexModal>
          </Box>
        }
      </Modal>
    </div>
  );
}