import * as React from 'react';
import * as S from './Styled';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Input } from '@cmp/ui';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { ReactComponent as IconUser } from 'modules/common/icons/icon-user.svg';
import { ReactComponent as IconMail } from 'modules/common/icons/mail.svg';
import { ReactComponent as IconLock } from 'modules/common/icons/lock.svg';
import { IUser, IWorkspace } from 'modules/types';
import {isWorkspaceValid, isEmailValid } from 'modules/common/validations/formValidations';

interface IError {
  field: string;
  message: string;
}

interface IProps {
  formState: boolean;
  user: IUser;
  handleCloseModal: () => void;
  workspaces: IWorkspace[];
  reloadList: (value: boolean) => void;
  editUser: (
    workspaces: string[],
    newEmail: string,
    email: string,
    password: string,
    resetPassword: boolean
  ) => Promise<any>;
}

const DialogUserEdit = ({
  formState,
  handleCloseModal,
  workspaces,
  editUser,
  user,
  reloadList,
}: IProps) => {
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetPassword, setResetPassword] = React.useState(false);
  const [workspacesSelected, setWorkspacesSelected] = React.useState<string[]>(
    []
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<IError[]>([]);

  const cleanForm = () => {
    setUsername('');
    setEmail('');
    setPassword('');
    setWorkspacesSelected([]);
    setErrors([]);
  };

  const setError = (newError: IError) => {
    const localErrors = [...errors];
    const foundIndex = localErrors.findIndex(error => error.field === newError.field);
    if (foundIndex !== -1) {
      localErrors[foundIndex] = newError;
      setErrors(localErrors);
    } else {
      setErrors([...localErrors, newError]);
    }
  }

  const getErrorMessageByField = (fieldName: string): string => {
    const foundError = errors.find(error => error.field === fieldName);
    if (foundError !== undefined) {
      return foundError.message;
    }
    return '';
  }

  const removeValidationError = (field: string) => {
    const localErrors = [...errors];
    const foundIndex = localErrors.findIndex(error => error.field === field);
    if (foundIndex !== -1) {
      localErrors.splice(foundIndex, 1);
      setErrors(localErrors);
    }
  }

  const onChangeWorkspace = (event: React.ChangeEvent<{ value: unknown }>) => {
    setWorkspacesSelected(event.target.value as string[]);
    if (isWorkspaceValid(event.target.value as string[], setError)) {
      removeValidationError('workspaces');
    }
  };

  const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    if (isEmailValid(event.currentTarget.value, setError)) {
      removeValidationError('email');
    }
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleEditUser = React.useCallback(() => {
    setWorkspacesSelected(user.workspaces.map(workspace => workspace.code));
    setUsername(user.username);
    setEmail(user.email);
  }, [setWorkspacesSelected, setUsername, setEmail, user]);

  const handleSaveUser = (): void => {
    const isFieldsValid = isWorkspaceValid(workspacesSelected, setError) &&
    isEmailValid(email, setError);

    if(!isFieldsValid) {
      return;
    }
    setLoading(true);
    editUser(
      workspacesSelected,
      user.email,
      email,
      password,
      resetPassword
    ).then(
      () => {
        reloadList(true);
        handleCloseModal();
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  React.useEffect(() => {
    cleanForm();
    handleEditUser();
  }, [formState, handleEditUser]);

  return (
    <Dialog maxWidth="lg" open={formState}>
      <DialogTitle>Editar Usuário</DialogTitle>
      <S.DialogContentCustom>
        <S.FormContent>
          <FormControl error={getErrorMessageByField('workspaces') !== ''} variant="filled" className="formControl">
            <InputLabel className="input-label" id="multiple-workspace-label">
              Selecione ao menos uma Workspace
            </InputLabel>
            <Select
              labelId="multiple-workspace-label"
              id="multiple-workspace"
              className="custom-select"
              required
              multiple
              value={workspacesSelected}
              onChange={onChangeWorkspace}
              renderValue={workspacesSelected => (
                <div className="chips">
                  {(workspacesSelected as string[]).map(value => {
                    const workspaceName = workspaces.find(
                      w => w.code === value
                    );
                    return (
                      <Chip
                        key={value}
                        label={
                          workspaceName ? workspaceName.name : 'carregando'
                        }
                        className="chip"
                      />
                    );
                  })}
                </div>
              )}
            >
              {workspaces.map(({ code, name }: IWorkspace) => (
                <MenuItem key={code} value={code}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </S.FormContent>
        <S.FormContent>
          <Input
            type="text"
            text="Nome de Usuário"
            required
            Icon={IconUser}
            fullWidth={true}
            size={300}
            mr={20}
            value={username}
            inputProps={{ disabled: true }}
            onChange={onChangeUsername}
            error={getErrorMessageByField('username')}
          />
          <Input
            type="text"
            text="Email"
            required
            Icon={IconMail}
            fullWidth={true}
            size={300}
            value={email}
            onChange={onChangeEmail}
            error={getErrorMessageByField('email')}
          />
        </S.FormContent>
        <S.FormContent>
          <Input
            type="password"
            text="Senha"
            Icon={IconLock}
            fullWidth={true}
            size={300}
            value={password}
            onChange={onChangePassword}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                className="ColorBar"
                value={resetPassword}
                onChange={event => setResetPassword(event.target.checked)}
              />
            }
            label="Redefinir senha no primeiro acesso"
          />
        </S.FormContent>
      </S.DialogContentCustom>
      <S.GroupButton>
        <Button
          color="primary"
          style={{ marginRight: '15px' }}
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveUser}
          disabled={loading}
        >
          Salvar alterações
        </Button>
      </S.GroupButton>
    </Dialog>
  );
};

export default DialogUserEdit;
