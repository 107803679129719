import * as React from 'react';
import { Input } from '@cmp/ui';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';
import * as S from './Styled';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import Workspace from './Workspace';
import { IWorkspace } from 'modules/types';
// import { WorkspaceEmpty } from '../componentes';

interface IProps {
  loading: boolean;
  workspaces: IWorkspace[];
  handleCreateWorkspace: () => void;
  handleUpdateWorkspace: (workspaceCode: string) => void;
  setWorkspaceDelete: (value: string) => void;
  setWorkspaceEmailSend: (value: string) => void;
}

const ListWorkspace = ({
  loading,
  workspaces,
  handleCreateWorkspace,
  handleUpdateWorkspace,
  setWorkspaceDelete,
  setWorkspaceEmailSend,
}: IProps) => {
  const [searchField, setSearchField] = React.useState('');
  const [workspaceFiltered, setWorkspaceFiltered] = React.useState<
    IWorkspace[]
  >([]);

  React.useEffect(() => {
    setWorkspaceFiltered(workspaces);
  }, [workspaces]);

  const filterResource = React.useCallback(async () => {
    const regex = new RegExp(searchField, 'gi');
    setWorkspaceFiltered(
      workspaces.filter(workspace => workspace.name.match(regex))
    );
  }, [searchField, workspaces]);

  React.useEffect(() => {
    filterResource();
  }, [filterResource, searchField, workspaces]);

  const renderWorkspaces = () => {
    if (loading) {
      return (
        <>
          {new Array(7).fill(true).map((_, index) => (
            <Skeleton key={`load_${index}`} height={80} />
          ))}
        </>
      );
    }

    if (!workspaceFiltered.length) {
      return (
        <Box textAlign="center" m={1}>
          Nenhum recurso encontrado
        </Box>
      );
    }

    return (
      <>
        {workspaceFiltered.map((workspace: IWorkspace) => (
          <Workspace
            key={workspace.code}
            workspace={workspace}
            handleUpdateWorkspace={() => handleUpdateWorkspace(workspace.code)}
            handleDelete={() => setWorkspaceDelete(workspace.code)}
            handleSendEmail={() => setWorkspaceEmailSend(workspace.code)}
          />
        ))}
      </>
    );
  };

  return (
    <S.Root>
      <S.Container>
        {workspaces.length || loading ? (
          <>
            <Grid container justifyContent="space-between">
              <Input
                type="text"
                text="Digite aqui para buscar uma workspace"
                Icon={IconSearch}
                size={400}
                value={searchField}
                onChange={e => setSearchField(e.target.value)}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleCreateWorkspace()}
              >
                Create Workspace
              </Button>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <S.CustomTypography>Nome da Empresa</S.CustomTypography>
              </Grid>
              <Grid item xs={4}>
                <S.CustomTypography>Código do Cliente (CD)</S.CustomTypography>
              </Grid>
              <Grid item xs={4}>
                <S.CustomTypography>Code</S.CustomTypography>
              </Grid>
            </Grid>
            {renderWorkspaces()}
          </>
        ) : (
          <div></div>
        )}
      </S.Container>
    </S.Root>
  );
};
export default ListWorkspace;
