import React from 'react';
import { Dialog, Button, Typography } from '@material-ui/core';
import { Input } from '@cmp/ui';
import * as S from './Styled';

interface IProps {
  workspace: string;
  handleCloseModal: () => void;
  setSendExtraHoursApprovedEmail: (
    workspace: string,
    extraHours: number
  ) => Promise<any>;
}

interface IPropsMessage {
  message: string;
}

const Message: React.FC<IPropsMessage> = ({ message }) => (
  <S.TextContainer>
    <Typography>{message}</Typography>
  </S.TextContainer>
);

export const DialogWorkspaceSendEmail: React.FC<IProps> = ({
  workspace,
  handleCloseModal,
  setSendExtraHoursApprovedEmail,
}) => {
  const [extraHours, setExtraHours] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [dialogStatus, setDialogStatus] = React.useState('form');

  const extraHoursInputErrorCheck = () =>
    extraHours !== '' && (Number(extraHours) < 8 || Number(extraHours) > 176);

  const handleSendEmail = async () => {
    setLoading(true);

    await setSendExtraHoursApprovedEmail(workspace, Number(extraHours))
      .then(() => setDialogStatus('success'))
      .catch(() => setDialogStatus('error'));

    setLoading(false);
  };

  return (
    <S.Root>
      <Dialog
        maxWidth="lg"
        open={Boolean(workspace)}
        onClose={handleCloseModal}
      >
        <S.CustomDialogTitle variant={dialogStatus}>
          {dialogStatus === 'form' && 'Aprovação de Horas Extras'}
          {dialogStatus === 'success' && 'E-mail enviado com sucesso!'}
          {dialogStatus === 'error' && 'Falha no envio'}
        </S.CustomDialogTitle>
        <S.DialogContentCustom>
          {dialogStatus === 'form' && (
            <S.FormContent>
              <S.FormTitle>Horas extras aprovadas:</S.FormTitle>
              <S.FormInputs>
                <Input
                  required={true}
                  type="text"
                  text="Digite um valor ente 8h e 176h"
                  fullWidth={true}
                  mr={20}
                  value={extraHours}
                  onChange={e =>
                    setExtraHours(e.target.value.replace(/[^0-9]/g, ''))
                  }
                  error={
                    extraHoursInputErrorCheck()
                      ? 'Digite um valor ente 8h e 176h'
                      : ''
                  }
                />
              </S.FormInputs>
            </S.FormContent>
          )}
          {dialogStatus === 'success' && (
            <Message message="Um e-mail com a indicação de horas extras aprovadas foi enviado para o CD selecionado." />
          )}
          {dialogStatus === 'error' && (
            <Message message="A mensagem não pode ser enviada pois não há um endereço de e-mail cadastrado." />
          )}
        </S.DialogContentCustom>
        {dialogStatus === 'form' ? (
          <S.GroupButton>
            <Button
              color="primary"
              size={'medium'}
              onClick={handleCloseModal}
              style={{ marginRight: 20 }}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={
                extraHours === '' || extraHoursInputErrorCheck() || loading
              }
              onClick={handleSendEmail}
            >
              Enviar Agora
            </Button>
          </S.GroupButton>
        ) : (
          <S.GroupButton>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={handleCloseModal}
            >
              Fechar
            </Button>
          </S.GroupButton>
        )}
      </Dialog>
    </S.Root>
  );
};
