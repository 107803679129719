import * as React from 'react';
import { ReactComponent as IconError } from 'modules/common/icons/icon-error.svg';
import { EmptyState } from '@cmp/ui';
import Typography from '@material-ui/core/Typography';
import * as S from './styled';

const Error404 = () => {
  return (
    <S.Root>
      <EmptyState>
        <IconError />
        <Typography component="h1">
          Ops! Acho que essa página não existe
        </Typography>
        <Typography>
          A página que você tentou acessar não existe. Se acredita que isso foi
          um erro, tente <span>recarregar a página</span>.
        </Typography>
        <S.ButtonHome color="primary" variant="contained" href="/reports">
          <span>Voltar à tela inicial</span>
        </S.ButtonHome>
      </EmptyState>
    </S.Root>
  );
};

export default Error404;
