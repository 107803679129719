import React, { useEffect } from 'react';
import * as S from './Styled';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';
import Tooltip from 'modules/common/components/Tooltip';
import { CognitoApi } from './CognitoApi';

const Permissions = (props) => {
  const [cogPermissions, setCogPermissions] = React.useState<String[]>([]);
  const [grantedList, setGrantedList] = React.useState([]);
  const [reload, setReload] = React.useState<boolean>(true);
  const [loadError, setLoadError] = React.useState(false);

  const getAllPermissions = React.useCallback(() => {
    if(localStorage.getItem('Permissões') === null) {
      CognitoApi.getPermissions()
      .then(response => {
        const arrayPermissions: String[] = [];
        response.map(permissions => arrayPermissions.push(permissions.GroupName));
        setCogPermissions(arrayPermissions);
        setReload(false);
      })
    }else {
      const dataPermissions = window.localStorage.getItem('Permissões');
      setCogPermissions(JSON.parse(dataPermissions));
      setReload(false);
    }
  }, []);

  useEffect(() => {
    if(reload) {
      getAllPermissions();
    }
  }, [reload, getAllPermissions]);

  useEffect(() => {
    const data = window.localStorage.getItem('Permissões concedidas');

    if(data) {
      setGrantedList(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('Permissões concedidas', JSON.stringify(grantedList));
    window.localStorage.setItem('Permissões', JSON.stringify(cogPermissions));
    props.func(grantedList);
  }, [props, grantedList, cogPermissions]);

  const addPermissions = (groups) => {
    if(grantedList.includes(groups) === false) {
      setGrantedList((prevWorksSpace) => [...prevWorksSpace, groups]);
    }else {
      setLoadError(true);
      setTimeout(() => {
        setLoadError(false);
      }, 3000);
    }
  }

  const removePermission = (permission: String) => {
    setGrantedList(grantedList.filter(element => !element.includes(permission)));
  }


  return(
    <S.FlexContainer>
      {reload === true &&
        <S.LoadingCircleContainer>
          <CircularProgress
          size={50}
          style={{ marginRight: 8 }}
          />
        </S.LoadingCircleContainer>
      }
      {reload === false &&
        <S.ComponentsContainer>
          {cogPermissions.map((group, index) => {
            return (
            <div key={index}>
              <S.ButtonPermissions onClick={() => addPermissions(group)}>{group}</S.ButtonPermissions>
              <S.DividerPermissions/>
            </div>
            )}
          )}
        </S.ComponentsContainer>
      }
      <S.ComponentsContainer>
      <S.TitleAccessList>
         <p className='Titulo'>Acesso concedido</p>
        <Tooltip
            description="Aqui constarão os clientes e permissões que este(s) usuário(s) têm permissão de acesso"
            icon="info"
          />
        </S.TitleAccessList>
        <S.GrantedAccess>
          {grantedList.map((access, index) => {
            return(
              <div key={index}>
                <S.ButtonPermissions onClick={() => removePermission(access)}>{access}</S.ButtonPermissions>
              </div>
            )
          })}
        </S.GrantedAccess>
      </S.ComponentsContainer>
      <S.ErrorContainer>
        {loadError &&
          <Alert variant="filled" severity="error">
            Esta permissão já foi selecionada.
          </Alert>
        }
      </S.ErrorContainer>
    </S.FlexContainer>
  );
};

export default Permissions;
