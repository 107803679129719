import { colors, Container } from '@cmp/ui';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { paletteColor } from 'modules/common/styles/colors';

export const activeClassName = 'nav-item-active';

export const NavItem = styled(NavLink).attrs({
  activeClassName: 'nav-item-active',
})`
  text-decoration: none;
  display: block;
  color: ${paletteColor.dark.icon};

  &.nav-item-active {
    & > div {
      color: ${paletteColor.primary.main};
      position: relative;
      font-weight: bold;
      letter-spacing: 0.01em;
      &:after {
        content: '';
        position: absolute;
        left: -14px;
        top: 4px;
        height: 18px;
        width: 3px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background: ${paletteColor.primary.main};
        display: block;
      }
    }
  }
`;

export const RootContainer = styled(Container)`
  min-height: calc(100% - 50px);
`;

export const MainContent = styled.div`
  @media only screen and (min-width: 992px) {
    ${({ isMenuOpened }) =>
      isMenuOpened
        ? 'margin-left: 204px; width: calc(100% - 204px);'
        : 'margin-left: 0'}
  }
`;

export const TitleMenu = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  color: ${paletteColor.info.soft};
  font-size: 14px;
  padding: 8px 0 4px;
  font-weight: 500;
  letter-spacing: 0.05em;
`;

export const ListMenu = styled.div`
  && {
    background-color: ${colors.white};
    padding: 15px;
  }
`;

export const ListItemMenu = styled.div`
  && {
    padding: 3px 5px;
    color: ${paletteColor.dark.icon};
    letter-spacing: 0.01em;

    &:hover {
      background-color: ${colors.white};
      color: ${paletteColor.primary.main};
      font-weight: bold;
      letter-spacing: 0.01em;
    }
  }
`;

export const NavigationRoot = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 50px;
  background-color: ${colors.white};
`;

export const WorkspaceMenuContainer = styled.div`
  position: relative;
  top: 0;
  width: 280px;
  height: 50px;
  background-color: red;
`;

export const LogoutButton = styled(Button)`
  && {
    width: 120px;
    height: 50px;
    border-radius: 0;
  }
  & .icon {
    pointer-events: none;
    margin-left: 6px;
    height: 16;
    width: 16;
  }
  &:before {
    content: ' ';
    position: absolute;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border-left: 1px solid #cccccc;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: auto;

  & .logo {
    width: 22px;
  }

  & .itemList {
    color: ${paletteColor.primary.main};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.05em;
    margin-left: 8px;
  }
`;

export const Item = styled.div`
  width: 100%;
`;

export const NavigationPullRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(223, 225, 235, 0.25);
`;

export const AppBarCustom = styled(AppBar)`
  && {
    right: 0px;
    width: 100%;
    margin-left: 0px;
    box-shadow: 0px 4px 4px rgba(223, 225, 235, 0.25);
    transition: margin 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      width 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  @media only screen and (min-width: 992px) {
    && .appBarShift {
      position: absolute;
      right: 0px;
      margin-left: 204px;
      width: calc(100% - 204px);
      transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        width 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`;

export const ToolbarCustom = styled(Toolbar)`
  && {
    background-color: ${paletteColor.primary.white};
    color: rgba(0, 0, 0, 0.87);
    min-height: 48px;
    box-shadow: 0px 4px 4px rgba(223, 225, 235, 0.25);
    padding-left: 0;
    padding-right: 0;

    & .MuiAppBar-root {
      width: 6%;
      display: block;
      z-index: auto;
      flex-shrink: 1;
      flex-direction: row;
      box-shadow: none;
    }

    & .MuiPaper-elevation4 {
      box-shadow: none !important;
    }

    & .MuiIconButton-root {
      padding: 15px;
      color: transparent;
      padding-left: 12px;
      padding-right: 12px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
        border-radius: 0;
        padding-left: 12px;
      }
    }

    & .custom-divider {
      border-right: 0.6px solid ${paletteColor.info.border};
      height: 50px;
      margin: 0px;
      border-radius: 0;
    }
  }
`;

export const UsernameContent = styled.div`
  justify-self: flex-end;
  margin-right: 15px;
  color: ${paletteColor.info.soft};
  font-size: 12px;
  letter-spacing: 0.02em;
`;

export const DrawerCustom = styled(Drawer)`
  && {
    width: 200px;

    & .MuiDrawer-paper {
      width: 204px;
      ::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
  & .drawHeader {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-top: 7px;
    font-size: 12px;
    color: ${paletteColor.info.soft};
    letter-spacing: 0.03em;
    border: 0;
  }

  & .MuiDrawer-paper {
    top: 51px;
  }

  @media only screen and (min-width: 992px) {
    & .MuiDrawer-paper {
      top: 0px;
    }
  }
`;

export const ListMenuItem = styled.div`
  && {
    background-color: ${colors.white};
    padding-top: 4px;
  }
`;

export const ListSubMenuItem = styled.div`
  && {
    color: #e00000;
    margin-left: 20px;
  }
`;
