import * as React from 'react';

type Props = {
  content: React.ReactNode;
};

class Layout extends React.Component<Props, {}> {
  public render() {
    const { content } = this.props;

    return (
      <main
        style={{
          maxWidth: '600px',
          margin: '40px auto 120px',
        }}
      >
        {content}
      </main>
    );
  }
}

export default Layout;
