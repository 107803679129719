import * as React from 'react';
import { ReportsContainer } from 'modules/reports/containers';
import { Route, Switch } from 'react-router';
import { StatusContainer } from 'modules/status/containers';
import { ProtectedRoute } from 'modules/auth/hoc';

const routes = () => (
  <Switch>
    <ProtectedRoute path="/reports" exact={true} component={ReportsContainer} />
    <Route path="*" component={StatusContainer} />
  </Switch>
);

export default routes;
