import logo from 'modules/common/brand/logo-brand.png';
import { ReactComponent as IconLogout } from 'modules/common/icons/logout.svg';
import { ReactComponent as IconMenu } from 'modules/common/icons/icon-menu.svg';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react';
import * as S from './styled';
import { logout } from 'modules/auth';
import { withRouter, RouteComponentProps } from 'react-router';
import { IUser } from 'modules/auth/types';
import { modules } from './Menu';
// import Menu from './Menu';
import Divider from '@material-ui/core/Divider';
import { Input } from '@cmp/ui';
import { ReactComponent as IconSearch } from 'modules/common/icons/icon-search.svg';
import { IMenu, IMenuList } from '../types';
import Typography from '@material-ui/core/Typography';
import { getItem, setItem } from 'utils/storage';

interface IProps extends RouteComponentProps {
  getUser: () => IUser;
  children: React.ReactNode;
}

const Navigation = ({ getUser, history, children, location }: IProps) => {
  const menuOpenSettingName = 'keepMenuOpen';

  const setMenuOpenSetting = (openSetting: boolean) => {
    setItem(menuOpenSettingName, String(openSetting), 'cookies', {
      domain: '.claranet.com.br',
    });
  };

  const getMenuOpenSetting = () => {
    const setting = getItem(menuOpenSettingName, 'cookies');
    return setting === 'true' || setting === null;
  };

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(
    getMenuOpenSetting()
  );

  useEffect(() => {
    setMenuOpenSetting(isMenuOpened);
  }, [isMenuOpened]);

  const [search, setSearch] = useState('');

  const handleLogout = () => {
    logout();
    history.push('/login');
  };

  const { email } = getUser();

  const handleDrawerToggle = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const hideNavigationUrls = [/^\/login(\/)?$/];

  const hideNavigation = hideNavigationUrls.some(item =>
    item.test(location.pathname)
  );

  const flatMap = (listProduct: IMenu[]) => {
    return listProduct
      .map((item: IMenu) => item.list)
      .reduce(
        (destinationArray: IMenuList[], array: IMenuList[]) =>
          destinationArray.concat(array),
        []
      );
  };

  const buildNavItem = (item: IMenuList, itemIndex: number) => (
    <>
      {
        <S.NavItem
          key={`item_${itemIndex}`}
          exact={true}
          to={item.modules[0]}
          activeClassName="nav-item-active"
          isActive={() =>
            item.modules.some(m => new RegExp(m).test(location.pathname))
          }
          onClick={() => setSearch('')}
        >
          <S.ListItemMenu>{item.name}</S.ListItemMenu>
        </S.NavItem>
      }
    </>
  );

  if (hideNavigation) {
    return <>{children}</>;
  }

  return (
    <>
      <S.NavigationRoot>
        <S.AppBarCustom>
          <S.ToolbarCustom className={!isMenuOpened ? '' : 'appBarShift'}>
            <IconButton className="custom-divider" onClick={handleDrawerToggle}>
              <IconMenu />
            </IconButton>
            <S.NavigationPullRight>
              <S.LogoContainer>
                <img className="logo" src={logo} alt="CMP - Backoffice" />
                {modules.map((item, itemIndex) =>
                  item.list.map(itemList => {
                    const isActive = itemList.modules.some(item =>
                      new RegExp(item).test(location.pathname)
                    );
                    if (isActive) {
                      return (
                        <S.Item
                          key={`item_${itemIndex}`}
                          exact={true}
                          to={itemList.modules[0]}
                        >
                          <Typography className="itemList">
                            {itemList.name}
                          </Typography>
                        </S.Item>
                      );
                    }
                    return null;
                  })
                )}
              </S.LogoContainer>
              <S.UsernameContent>{email}</S.UsernameContent>
              <S.LogoutButton onClick={handleLogout}>
                Logout
                <IconLogout className="icon" />
              </S.LogoutButton>
            </S.NavigationPullRight>
          </S.ToolbarCustom>
        </S.AppBarCustom>

        <S.DrawerCustom
          id="drawer-left"
          className="drawer"
          variant="persistent"
          anchor="left"
          open={isMenuOpened}
        >
          <div className="drawHeader">
            <Input
              type="text"
              text="Localizar serviços"
              fullWidth={true}
              Icon={IconSearch}
              rootProps={{ style: { border: 0 } }}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
          <Divider />
          <div style={{ backgroundColor: 'white', padding: '15px' }}>
            {search
              ? flatMap(modules).map((item, itemIndex) => (
                  <div key={`item_${itemIndex}`}>
                    {new RegExp(search, 'i').test(item.name) && (
                      <>{buildNavItem(item, itemIndex)}</>
                    )}
                  </div>
                ))
              : modules.map((category, categoryIndex) => (
                  <div key={`category_${categoryIndex}`}>
                    <S.TitleMenu>{category.name}</S.TitleMenu>
                    <div
                      style={{ backgroundColor: 'white', paddingTop: '4px' }}
                    >
                      {category.list.map((item, itemIndex) => (
                        <div key={`item_${itemIndex}`}>
                          {buildNavItem(item, itemIndex)}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              {location.pathname === '/admin' && <S.ListSubMenuItem className="itemList">Permissões</S.ListSubMenuItem>}
          </div>
        </S.DrawerCustom>
      </S.NavigationRoot>
      <S.MainContent isMenuOpened={isMenuOpened}>{children}</S.MainContent>
    </>
  );
};

export default withRouter(Navigation);
