const createUser = `
  mutation createUser(
    $workspaces: [String!]!,
    $username: String!,
    $email: String!,
    $password: String!,
    $resetPassword: Boolean!
  ) {
    createUser(user: {
      workspaces: $workspaces,
      username: $username,
      email: $email,
      password: $password,
      resetPassword: $resetPassword
    }) {
      errors
      message
    }
  }
`;

const editUser = `
  mutation updateUser(
    $workspaces: [String!],
    $newEmail: String,
    $email: String!,
    $password: String,
    $resetPassword: Boolean
  ) {
    updateUser(values: {
      workspaces: $workspaces,
      email: $newEmail,
      password: $password,
      resetPassword: $resetPassword
    }, email: $email) {
      workspaces{
        code
        name
      },
      username,
      email
    }
  }
`;

const deleteUser = `
  mutation deleteUser(
    $email: String!
  ) {
    deleteUser(email: $email)
  }
`;

export default {
  createUser, editUser, deleteUser
}
