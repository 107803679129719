import * as React from 'react';
import { EmptyState } from '@cmp/ui';
import { ReactComponent as IconUser } from '../../common/icons/icon-crud.svg';
import * as S from './Styled';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { IWorkspace } from 'modules/types';
import DialogUserCreate from './DialogUserCreate';

interface IProps {
  onSaveUser: () => void;
  getWorkspaces: () => Promise<IWorkspace[]>;
  createUser: (
    workspaces: string[],
    username: string,
    email: string,
    password: string,
    resetPassword: boolean
  ) => Promise<any>;
}

const UserEmpty = ({ getWorkspaces, createUser, onSaveUser }: IProps) => {
  const [openDialogUser, setOpenDialogUser] = React.useState(false);

  const handleOpenDialogUser = () => {
    setOpenDialogUser(true);
  };

  return (
    <S.Root>
      <DialogUserCreate
        formState={openDialogUser}
        handleCloseModal={() => setOpenDialogUser(false)}
        getWorkspaces={getWorkspaces}
        createUser={createUser}
        onSaveUser={onSaveUser}
      />
      <EmptyState>
        <IconUser />
        <Typography component="h1">
          Você ainda não criou nenhum usuário!
        </Typography>
        <Typography component="p">
          Poxa, mas que pena hein. Mas não tem problema não, basta clicar no
          botão abaixo e vamos te ajudar a fazer isso.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          style={{ padding: '10px 30px', marginTop: '15px' }}
          onClick={handleOpenDialogUser}
        >
          Criar meu Primeiro Usuário
        </Button>
      </EmptyState>
    </S.Root>
  );
};

export default UserEmpty;
