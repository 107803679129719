import * as React from 'react';
import * as S from './Styled';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect } from 'react';
import { CognitoApi } from './CognitoApi';
import { makeStyles } from '@material-ui/core';

const ITEM_HEIGHT = 98;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 490,
      position: 'absolute',
      marginTop: '12%',
      marginLeft: -4,
    },
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    position: 'relative'
  }
}))

export default function SelectUsers(props) {
  const classes = useStyles();
  const [personName, setPersonName] = React.useState<String[]>([]);
  const [cogUsers, setCogUsers] = React.useState([]);
  const [usersInGroup, setUsersInGroup] = React.useState<String[]>([]);
  const [reload, setReload] = React.useState<boolean>(true);
  const [personKey, setPersonKey] = React.useState<String[]>([])

  const getUsersInGroup = React.useCallback(() => {
    CognitoApi.getUsersInGroup("admin:read_workspace")
    .then(response => {
      const cogUsersListInGroup: String [] = [];
      response.map(users => cogUsersListInGroup.push(users.UserEmail));
      setUsersInGroup(cogUsersListInGroup);
    })
  }, [])

  const getAllUsers = React.useCallback(() => {
    CognitoApi.getUsers()
    .then(response => {
      const cogUsersList = [];
      response.map(users => cogUsersList.push({userId: users.Username, email: users.UserEmail}))
      setCogUsers(cogUsersList);
      setReload(false);
    })
  }, [])

  useEffect(() => {
    if (reload) {
      localStorage.removeItem('UsersList');
      localStorage.removeItem('Workspaces');
      localStorage.removeItem('Acesso-concedido-Workspaces');
      localStorage.removeItem('Permissões');
      localStorage.removeItem('Permissões concedidas');
      getAllUsers();
      getUsersInGroup();
    }
  }, [reload, getAllUsers, getUsersInGroup]);

  const handleChange = (event, props) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
    const key = props.key.replace(/^\.[0-9]:\$/ig, "");
    let keys = personKey;
    if(keys.includes(key)) {
      keys = keys.filter(gn => gn !== key);
    }else {
      keys.push(key);
    }
    setPersonKey(keys);
  };

  function separatorNames(selected) {
      return selected.join(', ');
  }

  useEffect(() => {
    const data = window.localStorage.getItem('UsersList');

    if(data) {
      setPersonName(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('UsersList', JSON.stringify(personName));
    props.func(personName, personKey);
  }, [props, personName, personKey]);

  return (
    <>
      <S.CustomTypography>Usuários</S.CustomTypography>
      <FormControl className={classes.formControl}>
        <S.CustomSelect
          multiple
          value={personName}
          onChange={handleChange}
          renderValue=
            {
                selected => separatorNames(selected)
            }
            MenuProps={MenuProps}
        >
          <S.CustomMenuItem value="option" disabled>
            Selecionar Usuário
          </S.CustomMenuItem>
          {cogUsers.filter(x => !usersInGroup.includes(x.email)).map((name) => (
            <MenuItem key={`${name.userId}`} value={`${name.email}`}>
              <Checkbox color="default" checked={personName.indexOf(name.email) > -1} />
              <ListItemText secondary={`${name.email}`}/>
            </MenuItem>
          ))}
        </S.CustomSelect>
      </FormControl>
    </>
  );
}
