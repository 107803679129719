import * as React from 'react';
import * as S from './Styled';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Workspaces from './Workspaces';
import Permissions from './Permissions';
import ModalConfirm from './ModalConfirm';
import SelectUsers from './SelectUsers';
import Tooltip from 'modules/common/components/Tooltip';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [confirmWorkspaces, setConfirmWorkspaces] = React.useState([]);
  const [confirmPermissions, setConfirmPermissions] = React.useState([]);
  const [confirmUsers, setConfirmUsers] = React.useState([]);
  const [personKey, setPersonKey] = React.useState<String[]>([]);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const pull_dataWorkspaces = (dataWorkspaces) => {
    setConfirmWorkspaces(dataWorkspaces);
  }

  const pull_dataPermissions = (dataPermissions) => {
    setConfirmPermissions(dataPermissions);
  }

  const pull_dataUsers = (dataUsers, usersId) => {
    setConfirmUsers(dataUsers);
    setPersonKey(usersId);
  }

  return (
    <div style={{position: 'relative'}}>
    <SelectUsers func={pull_dataUsers}/>
    <S.FlexContent>
      <Box sx={{ width: '100%'}}>
        <Box sx={{ width: '20%'}}>
          <S.colorBackground value={value} onChange={handleChange} textColor="primary" indicatorColor="primary">
            <Tab label="Clientes" {...a11yProps(0)} style={{minWidth:"40%", marginRight: '25px'}}/>
            <Tab label="Permissões" {...a11yProps(1)} style={{minWidth:"40%"}}/>
          <S.AlignToolTipContainer>
            <Tooltip
            description="Escolha e arraste para a caixa ao lado quais clientes e permissões o(s) usuário(s) acima poderá(ão) acessar"
            icon="info"
          />
          </S.AlignToolTipContainer>
          </S.colorBackground>
        </Box>
        <TabPanel value={value} index={0}>
          <Workspaces func={pull_dataWorkspaces}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Permissions func={pull_dataPermissions}/>
        </TabPanel>
      </Box>
      <ModalConfirm
        confirmWorkspaces={confirmWorkspaces}
        confirmPermissions={confirmPermissions} 
        confirmUsers={confirmUsers}
        personKey = {personKey}
        />
    </S.FlexContent>
    </div>
  );
}
