import DateFnsUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import * as React from 'react';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { IAccounts, IWorkspace } from '../types';
import * as S from './Styled';

let widthScreen = window.screen.width;
let responsiveMargin;
responsiveMargin = widthScreen < 1400 ? '35%' : '20%';

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: responsiveMargin,
    },
  },
};

interface IProps {
  handleCreatePDF: () => void;
  loading: boolean;
  setEditorLoading: (value: boolean) => void;
  setSelectorLoading: (value: boolean) => void;
  workspace?: IWorkspace;
  selectedDate: any;
  setSelectedDate: (value: any) => void;
  workspaces: IWorkspace[];
  setWorkspace: (value?: IWorkspace) => void;
  costRecommendation: string;
  setCostRecommendation: (value: string) => void;
  costEditor: string;
  setCostEditor: (value: string) => void;
  performanceRecommendation: string;
  setPerformanceRecommendation: (value: string) => void;
  perfEditor: string;
  setPerfEditor: (value: string) => void;
  additionalInformation: string;
  setAdditionalInformation: (value: string) => void;
  infoEditor: string;
  setInfoEditor: (value: string) => void;
  awsRegion: string;
  setAwsRegion: (value: string) => void;
  awsAccounts: IAccounts[];
  setAwsAccounts: (value: IAccounts[]) => void;
  selectedAccounts: string[];
  setSelectedAccounts: (value: string[]) => void;
}

const ReportForm = ({
  handleCreatePDF,
  loading,
  setEditorLoading,
  setSelectorLoading,
  workspace,
  workspaces,
  setWorkspace,
  setCostRecommendation,
  costEditor,
  setCostEditor,
  setPerformanceRecommendation,
  perfEditor,
  setPerfEditor,
  additionalInformation,
  setAdditionalInformation,
  infoEditor,
  setInfoEditor,
  setSelectedDate,
  awsRegion,
  setAwsRegion,
  awsAccounts,
  setAwsAccounts,
  setSelectedAccounts,
}: IProps) => {
  const RECOMMENDATION_MAX_LENGTH = 6000;
  const [editorTimer, setEditorTimer] = React.useState<NodeJS.Timeout>(null);
  const [selectTimer, setSelectTimer] = React.useState<NodeJS.Timeout>(null);
  const [accountsSelect, setAccountsSelect] = React.useState<string[]>([]);
  const [dateSelect, setDateSelect] = React.useState<Moment>(moment());

  const cleanEditors = () => {
    const defaultInfo =
      additionalInformation ||
      '# Pesquisa de Satisfação\n[Ir para pesquisa de satisfação](https://docs.google.com/forms/d/1kenFpgdhJHU6ZO8KJUlMWUt0_ni1D_FtECCKPf2Nz5k)';
    clearTimeout(editorTimer);
    clearTimeout(selectTimer);
    setEditorTimer(null);
    setCostEditor('');
    setCostRecommendation('');
    setPerfEditor('');
    setPerformanceRecommendation('');
    setInfoEditor(defaultInfo);
    setAdditionalInformation(defaultInfo);
  };

  const cleanAccounts = () => {
    setSelectTimer(null);
    setAccountsSelect([]);
    setSelectedAccounts([]);
    setAwsAccounts([]);
  };

  const handleChangeWorkspace = (workspaceCode: string) => {
    const workspaceSelected = workspaces.find(
      wks => wks.code === workspaceCode
    );
    cleanEditors();
    cleanAccounts();
    setWorkspace(workspaceSelected);
  };

  const selectDebounceTimer = () => {
    if (selectTimer) clearTimeout(selectTimer);

    setSelectorLoading(true);
    setSelectTimer(
      setTimeout(() => {
        setSelectedDate(dateSelect);
        setSelectedAccounts(accountsSelect);
        setSelectTimer(null);
        setSelectorLoading(false);
      }, 5000)
    );
  };

  const editorDebounceTimer = () => {
    if (editorTimer) clearTimeout(editorTimer);

    if (perfEditor || costEditor || infoEditor) {
      setEditorLoading(true);
      setEditorTimer(
        setTimeout(() => {
          setAdditionalInformation(infoEditor);
          setPerformanceRecommendation(perfEditor);
          setCostRecommendation(costEditor);
          setEditorTimer(null);
          setEditorLoading(false);
        }, 10000)
      );
    }
  };

  React.useEffect(() => {
    editorDebounceTimer();
    // eslint-disable-next-line
  }, [costEditor, perfEditor, infoEditor]);

  React.useEffect(() => {
    selectDebounceTimer();
    // eslint-disable-next-line
  }, [accountsSelect, dateSelect]);

  const formatAccount = (accountId: string, alias: string) => {
    if (accountId && alias) {
      return `${alias} (${accountId}) `;
    }
    if (!alias && accountId) {
      return `(${accountId})`;
    }
  };

  const awsDisclaimerService = (
    sectionName: string,
    awsServiceName: string,
    awsServiceUrl: string
  ) => (
    <>
      - {sectionName} -{' '}
      <a href={awsServiceUrl} target="blank">
        {awsServiceName}
      </a>
    </>
  );
  
  const awsDisclaimer = (
    <>
      <S.TypographyInfo>
        Certifique-se de que a{' '}
        <a
          href="https://cmp-cf-template.s3.amazonaws.com/customer-role-latest"
          target="blank"
        >
          role do MCP
        </a>{' '}
        esteja presente nas contas.
      </S.TypographyInfo>
      <S.TypographyInfo>
        Além disso, algumas seções do relatório precisam dos seguintes serviços
        habilitados para serem preenchidas corretamente: <br />
        {awsDisclaimerService(
          'Análise de Custos',
          'AWS Cost Explorer',
          'https://aws.amazon.com/aws-cost-management/aws-cost-explorer/'
        )}
        <br />
        {awsDisclaimerService(
          'Recomendações de otimização de custos',
          'AWS Cost Explorer - Right Sizing',
          'https://aws.amazon.com/aws-cost-management/aws-cost-optimization/right-sizing/'
        )}
        <br />
        {awsDisclaimerService(
          'Relatório do AWS Config',
          'AWS Config',
          'https://aws.amazon.com/config/'
        )}
      </S.TypographyInfo>
    </>
  );

  return (
    <>
      <Box display="flex" flexWrap="wrap" padding="24px 0">
        <S.FormContentSelect>
          <Typography>Escolha um Workspace:</Typography>
          <S.CustomSelect
            value={workspace ? workspace.code : 'option'}
            onChange={event => handleChangeWorkspace(event.target.value)}
          >
            <S.CustomMenuItem value="option" disabled>
              Selecionar workspace
            </S.CustomMenuItem>
            {workspaces.map(wks => (
              <MenuItem key={`workspace_${wks.code}`} value={wks.code}>
                {wks.name}
              </MenuItem>
            ))}
          </S.CustomSelect>
        </S.FormContentSelect>
        <S.FormContentSelect>
          <Typography>Escolha a data:</Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <S.DatePicker
              autoOk={true}
              openTo="year"
              variant="inline"
              margin="normal"
              format="MM/YY"
              views={['year', 'month']}
              maxDate={moment()}
              value={dateSelect}
              onChange={setDateSelect}
              style={{
                width: 100,
              }}
            />
          </MuiPickersUtilsProvider>
        </S.FormContentSelect>
        <S.FormContentSelect>
          <Typography>Escolha a região principal da AWS:</Typography>
          <S.CustomSelect
            className="selector"
            value={awsRegion}
            onChange={event => setAwsRegion(event.target.value as string)}
          >
            <S.CustomMenuItem key="placeholder" value="" disabled>
              Selecione a Região da Cloud
            </S.CustomMenuItem>

            {awsRegionsList.map(([regionCode, regionName]) => (
              <MenuItem
                key={regionCode}
                value={regionCode}
                style={{ padding: 5 }}
              >
                {regionName}
              </MenuItem>
            ))}
          </S.CustomSelect>
        </S.FormContentSelect>
      </Box>
      <Box>
        <S.FormContentMulti>
          <Typography id="multiple-multi-account">
            Selecione as contas da AWS:
          </Typography>
          {awsDisclaimer}
          <S.CustomSelect
            MenuProps={MenuProps}
            className="custom-select"
            labelId="multiple-multi-account"
            value={(() => {
              if (workspace) {
                if (accountsSelect.length > 0) {
                  return accountsSelect;
                } else {
                  return ['Selecione uma conta AWS'];
                }
              } else {
                return ['Selecione um workspace'];
              }
            })()}
            onChange={e =>
              setAccountsSelect(
                e.target.value.filter(acc => acc !== 'Selecione uma conta AWS')
              )
            }
            multiple
            disabled={awsAccounts.length === 0}
            renderValue={value => (
              <div className="chip-container">
                {accountsSelect.length ? (
                  awsAccounts
                    .filter(account =>
                      accountsSelect.includes(account.accountId)
                    )
                    .map(account => (
                      <>
                        <Chip
                          key={account.accountId}
                          label={formatAccount(
                            account.accountId,
                            account.alias
                          )}
                        />
                      </>
                    ))
                ) : (
                  <Chip key={value} label={value} />
                )}
              </div>
            )}
          >
            {awsAccounts.map(account => (
              <MenuItem key={account.accountId} value={account.accountId}>
                <S.textCapitalize>
                  {formatAccount(account.accountId, account.alias)}
                </S.textCapitalize>
              </MenuItem>
            ))}
          </S.CustomSelect>
        </S.FormContentMulti>
      </Box>
      <Box borderTop="1px solid #DFE1EB">
        <S.FormContent>
          <Typography>
            Recomendações de performance do ambiente e aplicações:
          </Typography>
          <S.MarkdownEditor
            value={perfEditor}
            onChange={setPerfEditor}
            minEditorHeight={80}
            disablePreview={true}
            textAreaProps={{
              maxLength: RECOMMENDATION_MAX_LENGTH,
              placeholder: 'Escreva suas recomendações aqui',
              disabled: !workspace || loading,
            }}
          />
          <Box textAlign="right">
            <Typography variant="caption">
              {perfEditor.length} / {RECOMMENDATION_MAX_LENGTH}
            </Typography>
          </Box>
        </S.FormContent>
      </Box>
      <Box borderTop="1px solid #DFE1EB">
        <S.FormContent>
          <Typography>Recomendações de otimização de custos:</Typography>
          <S.MarkdownEditor
            value={costEditor}
            onChange={setCostEditor}
            minEditorHeight={80}
            disablePreview={true}
            textAreaProps={{
              maxLength: RECOMMENDATION_MAX_LENGTH,
              placeholder: 'Escreva suas recomendações aqui',
              disabled: !workspace || loading,
            }}
          />
          <Box textAlign="right">
            <Typography variant="caption">
              {costEditor.length} / {RECOMMENDATION_MAX_LENGTH}
            </Typography>
          </Box>
        </S.FormContent>
      </Box>
      <Box borderTop="1px solid #DFE1EB">
        <S.FormContent>
          <Typography>Informações adicionais:</Typography>
          <S.MarkdownEditor
            value={infoEditor}
            onChange={setInfoEditor}
            minEditorHeight={80}
            disablePreview={true}
            textAreaProps={{
              maxLength: RECOMMENDATION_MAX_LENGTH,
              placeholder: 'Escreva informações adicionais aqui',
              disabled: !workspace || loading,
            }}
          />
          <Box textAlign="right">
            <Typography variant="caption">
              {infoEditor.length} / {RECOMMENDATION_MAX_LENGTH}
            </Typography>
          </Box>
        </S.FormContent>
      </Box>
      <Box
        borderTop="1px solid #DFE1EB"
        display="flex"
        justifyContent="flex-end"
      >
        <S.FormContent>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            onClick={handleCreatePDF}
            disabled={Boolean(
              !workspace || loading || editorTimer || selectTimer
            )}
          >
            {editorTimer || selectTimer ? (
              <S.ButtonProgress size={20} />
            ) : (
              'Exportar'
            )}
          </Button>
        </S.FormContent>
      </Box>
    </>
  );
};

export default ReportForm;

const awsRegionsList = [
  ['us-east-1', 'US East (N. Virginia)'],
  ['us-east-2', 'US East (Ohio)'],
  ['us-west-1', 'US West (N. California)'],
  ['us-west-2', 'US West (Oregon)'],
  ['ca-central-1', 'Canada (Central)'],
  ['eu-central-1', 'EU (Frankfurt)'],
  ['eu-west-1', 'EU (Ireland)'],
  ['eu-west-2', 'EU (London)'],
  ['eu-west-3', 'EU (Paris)'],
  ['eu-north-1', 'EU (Stockholm)'],
  ['ap-east-1', 'Asia Pacific (Hong Kong)'],
  ['ap-northeast-1', 'Asia Pacific (Tokyo)'],
  ['ap-northeast-2', 'Asia Pacific (Seoul)'],
  ['ap-northeast-3', 'Asia Pacific (Osaka-Local)'],
  ['ap-southeast-1', 'Asia Pacific (Singapore)'],
  ['ap-southeast-2', 'Asia Pacific (Sydney)'],
  ['ap-south-1', 'Asia Pacific (Mumbai)'],
  ['me-south-1', 'Middle East (Bahrain)'],
  ['sa-east-1', 'South America (São Paulo)'],
];
