import styled from 'styled-components';
import {
  DialogContent,
  DialogTitle,
  Typography,
  Link,
  IconButton,
  Button,
  CircularProgress,
  Tabs
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { paletteColor } from 'modules/common/styles/colors';

export const Container = styled.div`
  width: 91.5%;
  margin: 0 auto;
`;

export const Root = styled.div`
  padding: 30px;

  & .whiteCard {
    background: ${paletteColor.primary.white};
    box-shadow: 0px 2px 2px rgba(223, 225, 235, 0.5);
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 65px;
  }
`;

export const DialogContentCustom = styled(DialogContent)`
  && {
    overflow-y: auto;
    padding: 0;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  && {
    & .avatar.count {
      margin-right: -12px;
      background: ${paletteColor.info.border};
      color: ${paletteColor.info.soft};
    }
    & .avatar {
      border: 2px solid ${paletteColor.primary.white};
      text-transform: uppercase;
    }
    & .avatar:not(.count) {
      margin-right: -12px;
      background: #f2994a;
      transition: transform 100ms ease;
      cursor: pointer;
    }
    & .avatar:not(.count):hover {
      transform: scale(1.2);
      z-index: 10;
    }
  }
`;

export const FormInputs = styled.div`
  display: flex;

  & .align-tooltip-jira {
    margin: 14px 0;
  }
`;

export const FormInputsColumn = styled.div`
  display: flex;
  flex-direction: column;

  & .align-items {
    display: flex;
    min-width: 229px;
  }
`;

export const IconButtonCustom = styled(IconButton)`
  && {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const ButtonUpdate = styled(Button)`
  && {
    font-weight: 500;
    color: ${paletteColor.primary.main};
    padding: 12px;
    min-width: 154px;
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid ${paletteColor.primary.main};
    border-radius: 3px;
    margin: 8px;

    & .circular-button {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    &.MuiButton-root.Mui-disabled {
      color: #ffcba5;
      border: 1px solid #ffcba5;
    }

    & .MuiCircularProgress-indeterminate {
      color: #ffcba5;
    }

    & img {
      width: 20px;
    }

    &:hover {
      transition: 0.5s;
      text-decoration: none;
      background-color: ${paletteColor.primary.main};
      color: ${paletteColor.primary.white};
      img {
        filter: brightness(0) invert(1);
      }
    }

    @media only screen and (max-width: 425px) {
      padding: 18px;
      margin: 0 9px;
    }
  }
`;

export const ButtonProgress = styled(CircularProgress)`
  && {
    margin-right: 8px;
  }
`;

export const FormTitle = styled(Typography)`
  && {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: ${paletteColor.info.text};
    padding-bottom: 8px;
    padding-right: 8px;
  }
`;

export const ButtonAddAccount = styled(Link)`
  && {
    text-align: right;
    font-weight: 500;
    color: ${paletteColor.primary.main};
    padding-top: 8px;
    font-size: 12px;
    cursor: pointer;
    padding-right: 32px;

    @media only screen and (max-width: 768px) {
      padding-right: 24px;
    }

    &:hover {
      text-decoration: none;
    }
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${paletteColor.info.border};

  & .align-tooltip {
    display: flex;
  }

  &:last-child {
    border-bottom: none;
  }

  & .formControl {
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;

    &:focus-within {
      border: 1px solid #f26522;
    }
  }

  & .input-label {
    font-size: 12px;
    height: 14px;
    color: ${paletteColor.info.soft};
    line-height: 0;
  }

  && .Mui-focused {
    color: ${paletteColor.info.soft};
  }

  & .custom-select {
    width: 300px;
    background: ${paletteColor.primary.white};
    margin-right: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 3px;

    &:before {
      content: '';
      position: absolute;
      left: 265px;
      top: 14px;
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: ${paletteColor.info.soft};
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
    }

    & .MuiSelect-icon {
      display: none;
    }

    &:hover,
    :focus {
      background: ${paletteColor.primary.white};
    }
  }

  & .MuiFilledInput-input {
    padding: 18px 12px 10px;
  }

  && .MuiSelect-select:focus {
    background: ${paletteColor.primary.white};
  }

  && .MuiFilledInput-underline:after {
    border-bottom: none;
  }

  & .MuiFilledInput-underline:hover:before {
    border-bottom: 2px solid ${paletteColor.info.soft};
  }
`;

export const GroupButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 24px;
`;

export const ListContent = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;

  & .text {
    color: ${paletteColor.info.text};
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
  }

  & .avatar {
    background-color: blueviolet;
    color: white;
  }

  &:hover {
    & .tools {
      width: 100px;
      min-width: 65px;
    }
  }
`;

export const ResourceTools = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 0;
  min-width: 0;
  overflow: hidden;
  transition: all 400ms;
  margin-left: 25px;

  &::before {
    content: '';
    border-left: 1px solid ${paletteColor.info.border};
    border-radius: 0;
    padding-left: 9px;
  }
`;

export const CustomTypography = styled(Typography)`
  && {
    color: ${paletteColor.info.soft};
    font-weight: 500;
    letter-spacing: 0.05em;
    margin-bottom: 8px;
    margin-top: 30px;
    padding: 0 0 0 16px;
  }
`;

export const SelectContent = styled.div`
  position: relative;
  margin-right: 20px;

  & .error-content {
    position: absolute;
    background: ${paletteColor.error.main};
    border-radius: 0 0 4px 4px;
    text-align: center;
    top: 95%;
    width: 95%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  & .error-text {
    font-size: 12px;
    color: ${paletteColor.primary.white};
    margin: 0;
    padding: 0;
  }
`;

export const AutocompleteCustom = styled(Autocomplete)`
  width: 100%;
  min-width: 200px;

  && .MuiFilledInput-root {
    background: ${paletteColor.primary.white};
    color: ${paletteColor.info.text};
    border: 1px solid ${paletteColor.info.border};
    border-radius: 3px;
    font-size: 12px;
    height: 40px;
    padding: 9px 7px;

    &:before {
      content: '';
      position: absolute;
      border-left: 1px solid ${paletteColor.info.border};
      left: 162px;
      top: 5px;
      height: 24px;
      border-bottom: none;
    }
  }

  && .MuiFilledInput-root:hover {
    text-decoration: none;
  }

  & input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus-within {
    border: 1px solid #f26522;
    border-radius: 3px;
  }

  & label {
    font-size: 12px;
    color: ${paletteColor.info.soft};
    position: absolute;
    top: calc(50% - 27px);
  }
  & .MuiFilledInput-underline:before {
    border-bottom: none;
    content: ' ';
    position: initial;
  }

  & .MuiAutocomplete-popupIndicator:before {
    content: ' ';
    position: absolute;
    box-sizing: border-box;
    height: 8px;
    width: 8px;
    border-style: solid;
    border-color: ${paletteColor.info.soft};
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
  }

  & .MuiIconButton-root:hover {
    background: none;
  }

  & .MuiAutocomplete-popupIndicator {
    padding: 12px;
    margin: 0;
    color: transparent;
  }

  & .MuiIconButton-label {
    display: none;
  }

  & .MuiInputLabel-asterisk {
    color: red;
  }
`;

export const TextClientCode = styled.div`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.03em;
  color: ${paletteColor.info.soft};
  padding: 9px 4px 0 0px;
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  text-align: center;
  padding: 24px;
`;

interface ICustomDialogTitle {
  variant: 'form' | 'success' | 'error';
}

export const CustomDialogTitle = styled(DialogTitle)<ICustomDialogTitle>`
  color: ${(props: ICustomDialogTitle) =>
    props.variant === 'form'
      ? '#000'
      : props.variant === 'success'
      ? '#72CB46'
      : '#E92641'};
`;

export const CustomTabs = styled(Tabs)`
    & .MuiPaper-root {
      background-color: rgb(255,0,0) !important;
    }

    & .MuiTabs-flexContainer {
      background-color: rgb(255,255,255) !important;
    }

    & .MuiTab-wrapper {
      color: rgb(80,80,80);
    }

    & .MuiTabs-indicator {
      background-color: #E00000 !important;
    }

    & .MuiPaper-elevation4 {
      box-shadow: none !important;
    }
`

export const CustomDiaglogInner = styled.div`
    margin-right: -24px;
    margin-left: -24px;
    width: 500px;

    & p {
      padding-left: 24px;
    }
`;

export const CustomContainerField = styled.div`
    border-bottom: 1px solid rgb(220,220,200);
    padding: 15px 24px;
`;
export const CustomLabelField = styled.div`
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
    font-weight: bold;
    display: flex;

    & span {
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      display: flex;
    }
`;

export const CustomBoxField = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;

  & .BoxInner {
    width: 80%;
  }

  & span {
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
  }

  & .BoxButtonCreate {
    color: #E00000;
  }
`

export const CustomBoxFieldCreate = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  width: 80%;

  & span {
    font-weight: bold;
    font-size: 11px;
    color: #E00000;
    cursor: pointer;
  }
`

