import { queries } from '../graphql';
import client from 'apolloClient';
import gql from 'graphql-tag';
import { mutations } from '../graphql';
import { IAccount, MutationNotificationsInput } from 'modules/types';

// getWorkspaces -> Listagem de Workspaces
const getWorkspaces = () => {
  return client
    .query({ query: gql(queries.workspaces), fetchPolicy: 'network-only' })
    .then(response => response.data.workspaces.workspaces);
};

// getWorkspace -> Listagem de Workspaces
const getWorkspace = (code: string) => {
  return client
    .query({
      query: gql(queries.workspace),
      variables: { code },
      fetchPolicy: 'network-only',
    })
    .then(res => res.data.workspaces.workspaces[0]);
};

// getPools -> Listagem de Pools de Horas
const getPools = (orderBy: string = 'pool_size') => {
  return client
    .query({ query: gql(queries.pools), variables: { orderBy } })
    .then(response => response.data.pools);
};

// getProjects -> Listagem de Projetos
const getProjects = (cd: string) => {
  return client
    .query({
      query: gql(queries.projects),
      variables: { cd },
      fetchPolicy: 'network-only',
    })
    .then(response => response.data.projects);
};

// getAccounts -> Listagem de Pools de Horas
const getAccounts = (cd: string) => {
  return client
    .query({
      query: gql(queries.accounts),
      variables: { cd },
      fetchPolicy: 'network-only',
    })
    .then(response => response.data.accounts);
};

// getNotifications -> Atualizar informações de Notifications
const getNotifications = (workspace: string) => {
  return client
    .query({
      query: gql(queries.notifications),
      variables: { workspace },
      fetchPolicy: 'network-only',
    })
    .then(response => response.data.notifications);
};

// setUupdateNotifications -> Atualizar informações de Notifications
const setUupdateNotifications = (
  workspace: string,
  notificationsEmails: MutationNotificationsInput[]
) => {
  return client.mutate({
    mutation: gql(mutations.updateNotifications),
    variables: {
      workspace,
      notificationsEmails,
    },
  });
};

// setUpdateWorklogs -> Gravar Projects
const setUpdateWorklogs = (projects: string[]) => {
  return client
    .mutate({
      mutation: gql(mutations.updateWorklogs),
      variables: {
        projects,
      },
    })
    .then(response => {
      return response;
    });
};

// setUpdateWorkspace -> Gravar Projects
const setUpdateWorkspace = (
  workspaceCode: string,
  values: {
    name?: string;
    poolId?: string;
    clientCode?: string;
    jira?: string;
    pagerduty?: string;
    startedAt?: string;
    planType?: string;
  }
) => {
  return client.mutate({
    mutation: gql(mutations.updateWorkspace),
    variables: {
      workspaceCode,
      ...values,
      planType: values.planType || '',
    },
  });
};

// setCreateWorkspace -> Gravar Projects
const setCreateWorkspace = (
  name: String,
  poolId: String,
  clientCode: String,
  jira: String,
  pagerduty: String,
  startedAt: String,
  planType: String
) => {
  return client.mutate({
    mutation: gql(mutations.createWorkspace),
    variables: {
      name: name,
      poolId: poolId,
      clientCode: clientCode,
      jira: jira,
      pagerduty: pagerduty,
      startedAt: startedAt,
      planType: planType,
    },
  });
};

// setUpdateAccounts -> Gravar Projects
const setUpdateAccounts = (cd: string, accounts: IAccount[]) => {
  return client.mutate({
    mutation: gql(mutations.updateAccounts),
    variables: {
      cd,
      accounts,
    },
  });
};

// setUpdateProjects -> Gravar Projects
const setUpdateProjects = (cd: string, projectsKeys: string[]) => {
  return client.mutate({
    mutation: gql(mutations.updateProjects),
    variables: {
      cd,
      projectsKeys,
    },
  });
};

// setSendExtraHoursApprovedEmail -> Enviar Email para o Cliente
const setSendExtraHoursApprovedEmail = async (
  workspace: string,
  extraHours: number
) => {
  client.mutate({
    mutation: gql(mutations.sendExtraHoursApprovedEmail),
    variables: { workspace, extraHours },
  });
};

// setDeleteWorkspace -> Deletar uma Workspace
const setDeleteWorkspace = (workspaceCode: string) => {
  return client.mutate({
    mutation: gql(mutations.deleteWorkspace),
    variables: {
      workspaceCode,
    },
  });
};

/*
 * @Export Connect Workspace
 **/
export {
  getWorkspaces,
  getWorkspace,
  getPools,
  getProjects,
  getAccounts,
  setUpdateWorklogs,
  setUpdateWorkspace,
  setCreateWorkspace,
  setUpdateAccounts,
  setUpdateProjects,
  getNotifications,
  setUupdateNotifications,
  setDeleteWorkspace,
  setSendExtraHoursApprovedEmail,
};
